import {

} from "./actionType";

import {
  Get_Brand_Packages,
  Get_Brand_Services
} from './actionType'


export const getBrandPackages = (payload) => ({
  type: Get_Brand_Packages,
  payload
});

export const getBrandServices = (payload) => ({
  type: Get_Brand_Services,
  payload
});
