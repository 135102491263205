export const Set_Event_Type_Input_Data = "Set_Event_Type_Input_Data"
export const Set_Event_Religion_Input_Data = "Set_Event_Religion_Input_Data"
export const Set_Event_Community_Input_Data = "Set_Event_Community_Input_Data"
export const Set_Event_Guests_Input_Data = "Set_Event_Guests_Input_Data"
export const Set_Event_Budget_Input_Data = "Set_Event_Budget_Input_Data"
export const Set_Event_Location_Input_Data = "Set_Event_Location_Input_Data"

export const Set_Community_Types_Loading = "Set_Community_Types_Loading"
export const Un_Set_Community_Types_Loading = "Un_Set_Community_Types_Loading"
export const Set_Community_Types = "Set_Community_Types"
export const Get_Community_Type = "Get_Community_Type"

export const Set_Religion_Types_Loading = "Set_Religion_Types_Loading"
export const Un_Set_Religion_Types_Loading = "Un_Set_Religion_Types_Loading"
export const Set_Religion_Types = "Set_Religion_Types"
export const Get_Religion_Type = "Get_Religion_Type"
export const Set_Event_Packages_Brand_Tabs = "Set_Event_Packages_Brand_Tabs"


export const Set_Event_Types_Loading = "Set_Event_Types_Loading"
export const Un_Set_Event_Types_Loading = "Un_Set_Event_Types_Loading"
export const Set_Event_Types = "Set_Event_Types"
export const Get_Event_Types = "Get_Event_Types"

export const Get_Event_Settings = "Get_Event_Settings"
export const Set_Event_Settings = "Set_Event_Settings"

export const Get_Event_Packages_Search = "Get_Event_Packages_Search"
// new
export const Set_Event_Packages_Is_Loading = "Set_Event_Packages_Is_Loading"
export const Get_Event_Packages_Details = "Get_Event_Packages_Details"
export const Set_Event_Packages_Details = "Set_Event_Packages_Details"
// new 
export const Set_Event_Packages_Search = "Set_Event_Packages_Search"
export const Set_Event_Packages_Search_Is_Loading = "Set_Event_Packages_Search_Is_Loading"

export const Get_Event_Packages_Services = "Get_Event_Packages_Services"
export const Set_Event_Packages_Services = "Set_Event_Packages_Services"
export const Set_Event_Input_Packages_Search = "Set_Event_Input_Packages_Search"
export const Set_Event_Services_Includes = 'Set_Event_Services_Includes'
export const Set_Event_Services = 'Set_Event_Services'
export const Get_Event_Services = 'Get_Event_Services'
export const Set_Event_Services_Is_Loading = 'Set_Event_Services_Is_Loading'
export const Set_Event_Is_Filter = 'Set_Event_Is_Filter'

export const Reset_Event_Packages_Services_Filter = "Reset_Event_Packages_Services_Filter"
export const Set_Event_Packages_Services_Filter = "Set_Event_Packages_Services_Filter"
export const Reset_Event_Packages_Services_Payload = "Reset_Event_Packages_Services_Payload"
export const Set_Event_Packages_Services_Payload = "Set_Event_Packages_Services_Payload"
export const Set_Event_Packages_Services_IsDelete = "Set_Event_Packages_Services_IsDelete"
export const Set_Event_Packages_Services_Apply_Filter_Data = "Set_Event_Packages_Services_Apply_Filter_Data"
export const Set_Event_Packages_Service_Edit_Start = "Set_Event_Packages_Service_Edit_Start"

export const Get_Packages_Search_Keywords = "Get_Packages_Search_Keywords"
export const Set_Packages_Search_Keywords = 'Set_Packages_Search_Keywords'