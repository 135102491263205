import { call, put, takeLatest } from "redux-saga/effects";
import {
  Get_Brand_Packages,
  Set_Brand_Packages_Is_Loading,
  Set_Brand_Packages,
  Get_Brand_Services,
  Set_Brand_Services_Is_Loading,
  Set_Brand_Services
} from "./actionType";
import service from "../../api/services/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function* getBrandPackagesSaga({ payload }) {

  try {
    yield put({ type: Set_Brand_Packages_Is_Loading, data: true })
    const response = yield service.brandPackagesServices.getBrandPackages(payload);
    const data = yield response.data;
    yield put({ type: Set_Brand_Packages, data })
    yield put({ type: Set_Brand_Packages_Is_Loading, data: false })
  } catch (error) {
    yield put({ type: Set_Brand_Packages_Is_Loading, data: false })
    console.error('error', error.message)
    toast.error(error.message, { autoClose: 2000 });
  }
}

function* getBrandServicesSaga({ payload }) {

  try {
    yield put({ type: Set_Brand_Services_Is_Loading, data: true })
    const response = yield service.brandPackagesServices.getBrandServices(payload);
    const data = yield response.data;
    yield put({ type: Set_Brand_Services, data })
    yield put({ type: Set_Brand_Services_Is_Loading, data: false })
  } catch (error) {
    yield put({ type: Set_Brand_Services_Is_Loading, data: false })
    console.error('error', error.message)
    toast.error(error.message, { autoClose: 2000 });
  }
}

function* productSaga() {
  yield takeLatest(Get_Brand_Packages, getBrandPackagesSaga);
  yield takeLatest(Get_Brand_Services, getBrandServicesSaga);
}

export default productSaga;
