export const MAIN_MATCH_CONSTANTS = {
    PACKAGES_CARD: {
        DETAILS: "details",
        PACKAGES: "packages",
        INCLUDES: "includes",
        DYNAMIC_EVENT_SERVICE_SCREEN: 'dynamicEventServiceScreen',
        SERVICE_CARD: 'eventServicesCard',
        MEDIA_TYPE:{
            VIDEO:"VIDEO",
            IMAGE:"IMAGE",
        }
    },
    FILTER_LABELS: {
        LOCATIONS: "Locations",
        BRANDS: "Brands",
        NO_OF_GUEST: "No Of Guests",
        BUDGET: "Budget",
        EVENT_TYPE: "Event Type",
        COMMUNITY_AND_CULTURE: "Community & Culture",
        RELIGION: "Religion",
        SERVICE_EVENT_TYPE: 'Event Service Type',
        SERVICE_EVENT_BUDGET: 'Budget'
    },
    LABEL_KEY_CHECK: {
        'Locations': 'location',
        'Radius': 'radius',
        'MinPrice': 'minPrice',
        'Budget': 'maxPrice',
        'MinGuests': 'minGuests',
        'No Of Guests': 'maxGuests',
        'Community & Culture': 'communityId',
        'Religion': 'religionId',
        'Event Type': 'eventTypeId',
        'Event Service Type': 'serviceTypeId',
        'Brands': 'keyword'
    },
    PRICE_MATCH: ['Other', 'Not Sure'],
    MATCH_EVENT_TYPE_KEY: ['communityId', 'eventTypeId', 'religionId'],
    MATCH_SERVICE_EVENT_TYPE_KEY: ["serviceTypeId"],
    MATCH_LOCATION: 'location',
    MAX_PRICE: 'maxPrice',
    MAX_GUESTS: 'maxGuests',
    SEARCH_KEYWORD: 'keyword',
    TYPES: {
        RELIGION: 'religion',
        COMMUNITY: 'community',
        EVENT_TYPE: 'eventType',
        BRANDS: 'brands',
        PACKAGES: 'packages'
    },
    LIST_MATCH: {
        INCLUDES: 'includes',
        EXCLUDES: 'excludes',
        ADD_ONS: 'add-ons'
    },
    DYNAMIC_SCREEN_TYPES: {
        SELECT_SERVICE: 'selectServices',
        EVENT_SERVICES_BUDGET: 'eventBudgetServices',
    },
    NOT_SURE: "Not Sure",
    OTHER: 'Other',
    ONE:'one',
    TWO:'two'
}