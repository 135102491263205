export const keywordStyle = {
    inputsContainer: { width: '100%', marginTop: '1rem', display: 'flex', justifyContent: 'center', minHeight: '47px' },
    labels: {
        // color: '#949494',
        minWidth: '67px',
        height: '11px',
        textAlign: 'left',
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '15px',
        letterSpacing: '0px',
        textTransform: 'uppercase',
        opacity: 1,
        paddingBottom: '22px'
    },
    innerBox: {
        container: {
            // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            padding: "5px 14px",
            backgroundColor: "#F2F2F2",
            fontSize: "1rem",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
            color: "rgba(0, 0, 0, 0.87)",
            boxSizing: "border-box",
            position: "relative",
            cursor: "pointer",
            display: "inline-flex",
            alignItems: "center",
            width: "100%",
            borderRadius: "25px",
            paddingLeft: "23px",
            paddingRight: "14px",
            // height: '3.4375em',
            justifyContent: "center",
            "&:hover": {
                backgroundColor: "#EFEFEF",
            },
            height: '49px'
        },
        text: {
            width: '400px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            height: 'auto',
            textAlign: 'center',
            fontSize: '19px',
            lineHeight: '22px',
            letterSpacing: '0px',
            color: '#0F0F0F',
            opacity: 1,
            fontFamily: 'Open Sans, sans-serif',
            fontWeight: 300,
        }
    },
    brands: {
        innerBox: {
            backgroundColor: '#fff',
            color: 'rgba(0, 0, 0, 0.87)',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderRadius: '4px',
            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
            overflow: 'hidden',
            position: 'absolute',
            top: '3rem',
            zIndex: '5',
            width: '95%',
            padding: '0',
        }
    }
}