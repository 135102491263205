export const SetEventServicesType = "SetEventServicesType"
export const SetEventServicesTypeIsLoading = "SetEventServicesTypeIsLoading"
export const GetEventServiceTypes = "GetEventServiceTypes"
export const setInputEventServiceTypes = "setInputEventServiceTypes"
export const Get_Event_Services_Dynamic = "Get_Event_Services_Dynamic"
export const Set_Event_Services_Dynamic_Is_Loading = "Set_Event_Services_Dynamic_Is_Loading"
export const Set_Event_Services_Search_Dynamic = "Set_Event_Services_Search_Dynamic"
export const Set_Event_Service_Budget_Input_Data = "Set_Event_Service_Budget_Input_Data"
export const Set_Dynamic_Event_Services = "Set_Dynamic_Event_Services"
export const Get_Dynamic_Event_Services = "Get_Dynamic_Event_Services"
export const Set_Dynamic_Event_Services_Is_Loading = "Set_Dynamic_Event_Services_Is_Loading"
// new
export const Set_Event_Packages_Is_Loading = "Set_Event_Packages_Is_Loading"
export const Get_Event_Packages_Details = "Get_Event_Packages_Details"
export const Set_Event_Packages_Details = "Set_Event_Packages_Details"
// new 
export const Reset_Event_Services_Filter = "Reset_Event_Services_Filter"
export const Set_Event_Services_Filter = "Set_Event_Services_Filter"
export const Reset_Event_Services_Payload = "Reset_Event_Services_Payload"
export const Set_Event_Services_Payload = "Reset_Event_Services_Payload"
export const Set_Event_Services_IsDelete = "Set_Event_Services_IsDelete"
export const Toggle_Event_Services_Popup = "Toggle_Event_Services_Popup"
export const Set_Event_Services_Apply_Filter_Data = "Set_Event_Services_Apply_Filter_Data"
export const Set_Hold_Event_Service_PayLoad = "Set_Hold_Event_Service_PayLoad"
export const Set_Hold_Event_Service_FilterData = "Set_Hold_Event_Service_FilterData"
export const Set_Event_Service_Edit_Start = "Set_Event_Service_Edit_Start"
export const Set_Event_Service_Brand_Tabs = "Set_Event_Service_Brand_Tabs"
