export const staticConstants = {
    eventTypeConstants: {
        eventSearchStatic: { "keyword": "", "communityId": 1602, "religionId": 8 },
        religionCommunityStatic: { religiontext: "Any Religion", communityText: "Any Community" },
        modalStatic: {
            communityHeading: "COMMUNITY & CULTURE",
            religionHeading: "SELECT RELIGION"
        },
        defaultEventType: {
            "eventTypeId": 74,
            "eventTypeName": "Any Event",
            "sortOrder": 0,
            "tags": null,
            "commonTags": null,
            "isActive": true,
            "relatedReligions": "#8#",
            "relatedCommunities": "#1602#",
            "imageUrl": "https://res.cloudinary.com/events6/image/upload/v1692536315/appv2/uzvsaih8pteau73qokkn.jpeg",
            "oldId": "SyCBWh16G"
        },
        defaultReligion: {
            "religionId": 8,
            "religionName": "Any Religion",
            "sortOrder": 1,
            "tags": "Any Religion",
            "isActive": true
        },
        defaultCommunity: {
            "communityId": 1602,
            "communityName": "Any Community",
            "country": "Any",
            "nationality": "Any",
            "sortOrder": 1,
            "tags": null,
            "isActive": true,
            "parentId": 0
        }
    },
    placeholderImageUrl: "https://liftlearning.com/wp-content/uploads/2020/09/default-image.png",
    packageScreen: {
        eventDefault: {
            "eventTypeId": 74,
            "eventTypeName": "Any Event",
            "sortOrder": 0,
            "tags": null,
            "commonTags": null,
            "isActive": true,
            "relatedReligions": "#8#",
            "relatedCommunities": "#1602#",
            "imageUrl": "https://res.cloudinary.com/events6/image/upload/v1692536315/appv2/uzvsaih8pteau73qokkn.jpeg",
            "oldId": "SyCBWh16G",
            "delete": false,
        },

    },
    defaultSettings: {
        guestsStatic: {
            "min": 1,
            "Max": 1000,
            "defaultMin": 1,
            "defaultMax": 1000
        },
        budgetStatic: {
            "min": 0,
            "Max": 100000,
            "defaultMin": 0,
            "defaultMax": 100000
        },
        radiusStatic: {
            "max": 500,
            "default": 50
        }
    },
    defaultEventType: { "communityId": 1602, "religionId": 8, "eventTypeId": 74, "maxPrice": 100000, "maxGuests": 1000 },
    eventServiceStatic: {
        inputApiBody: {
            "keyword": ""
        },
        eventSearchStatic: { "keyword": "", "communityId": 1602, "religionId": 8, "eventTypeId": 74 },
        eventServiceSelectTabDefault: {
            "serviceTypeId": 438,
            "serviceTypeName": "Any",
            "sortOrder": 1,
            "parentId": 0,
            "tags": "ALL",
            "isActive": true,
            "oldId": null
        },
        serviceIdAny: {
            "serviceTypeId": 438,
            "serviceTypeName": "Any",
            "sortOrder": 1,
            "parentId": 0,
            "tags": "ALL",
            "isActive": true,
            "oldId": null
        }
    }
}