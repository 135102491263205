export const ListServiceListStyles = {
    listContainer: {
        topHeading: {
            height: '2.5rem',
            borderRadius: '21px',
            display: 'flex',
            justifyContent: 'flex-start',
            margin: '0rem 1rem',
            color: '#FFFFFF'
        },
        headingText: {
            fontFamily: 'Open Sans, sans-serif',
            paddingLeft: '1.5rem',
            paddingTop: '0.5rem',
            textTransform: 'uppercase',
        },
    },
    addOnsImageSize: { height: '17px', width: '17px' }
}