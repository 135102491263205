import React, { useState } from "react";
import { RWebShare } from "react-web-share";
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
const Index = ({ shareData }) => {
    return (
        <div>
            <RWebShare
                data={shareData}
                onClick={() => console.log("shared successfully!")}
            >
                <IosShareOutlinedIcon />
            </RWebShare>
        </div>

    )
}

export default Index
