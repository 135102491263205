import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Avatar,
  Typography,
  Grid,
  Link
} from "@mui/material";
import { modalStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import KeywordSearch from '../../components/KeywordSearch'
import { useSelector } from 'react-redux'
import { MAIN_MATCH_CONSTANTS } from '../../constants/MainMatchConstants'

const Index = ({
  type, openDialog, onClickModal,
  topHeading, filterFields, handleClickOnFilter = () => { },
  handleApplyFilter = () => { }, handleOnclickKeywordList = () => { },
  keywordInputValue
}) => {
  const keywordsData = useSelector((state) => state.PackagesSearchKeywords.keywords)
  const { FILTER_LABELS } = MAIN_MATCH_CONSTANTS



  return (
    <>
      <Dialog open={openDialog} onClose={onClickModal} fullWidth>
        <DialogTitle>
          <Box sx={modalStyles.modalContainer.container}>
            <Typography variant="h5" sx={modalStyles.modalContainer.labelText}>
              {topHeading}
            </Typography>
            <Avatar sx={modalStyles.modalContainer.avatar}>
              <CloseIcon onClick={onClickModal} sx={{ color: "black" }} />
            </Avatar>
          </Box>
        </DialogTitle>

        <DialogContent >

          {
            filterFields instanceof Array && filterFields.length > 0 &&
            filterFields.map((filterItems) => {
              return <>
                {
                  filterItems.label === FILTER_LABELS.BRANDS ?
                    <KeywordSearch
                      data={keywordsData}
                      handleOnclickKeywordList={handleOnclickKeywordList}
                      openType="brands"
                      keywordInputValue={keywordInputValue}
                    />
                    :
                    <Grid key={filterFields.id} sx={modalStyles.inputsContainer}>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={4}
                        style={{ borderRadius: '25px', width: '100%' }}
                      >
                        <Typography variant="body1" sx={modalStyles.labels}>{filterItems.label}</Typography>
                        <Box
                          component="div"
                          sx={modalStyles.innerBox.container}
                          onClick={(e) => { handleClickOnFilter(filterItems) }}
                        >
                          <Box
                            component="span"
                            sx={modalStyles.innerBox.text}
                          >
                            {(filterItems.delete || filterItems.isDelete) ? filterItems.defaultDeleteName : filterItems.value}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                }
              </>
            })
          }
          <Grid item xs={12} sx={modalStyles.buttonContent.gridContainer}>
            <Box sx={modalStyles.buttonContent.container} >
              <Button variant="contained"
                onClick={() => { handleApplyFilter('apply') }}
                sx={{ ...modalStyles.buttonContent.button }}
              >
                APPLY
              </Button>
            </Box>
            <Box sx={modalStyles.buttonContent.container} >
              <Link onClick={() => { handleApplyFilter('reset') }} sx={modalStyles.buttonContent.linkButton} href="#" underline="none">
                Reset
              </Link>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Index;
