import {
  SetEventServicesType,
  GetEventServiceTypes,
  setInputEventServiceTypes,
  Set_Event_Service_Budget_Input_Data,
  Get_Event_Services_Dynamic,
  Set_Dynamic_Event_Services,
  Get_Dynamic_Event_Services,
  Reset_Event_Services_Filter,
  Set_Event_Services_Filter,
    // 
    Get_Event_Packages_Details,
    Set_Event_Packages_Details,
    // 
  Reset_Event_Services_Payload,
  Set_Event_Services_Payload,
  Set_Event_Services_IsDelete,
  Toggle_Event_Services_Popup,
  Set_Event_Services_Apply_Filter_Data,
  Set_Hold_Event_Service_PayLoad,
  Set_Hold_Event_Service_FilterData,
  Set_Event_Service_Edit_Start,
  Set_Event_Service_Brand_Tabs
} from "./actionType";


export const getEventServiceTypeAction = (payload, maxData) => {
  return {
    type: GetEventServiceTypes,
    payload,
    maxData
  }
}

export const setEventServiceTypeAction = (payload) => {
  return {
    type: SetEventServicesType,
    payload
  }
}

export const setEventServicesTypeInputData = (data) => ({
  type: setInputEventServiceTypes,
  data,
});

export const setEventServiceBudgetInputData = (data) => ({
  type: Set_Event_Service_Budget_Input_Data,
  data,
});

export const getEventServiceDynamic = (payload, serviceCardCount) => ({
  type: Get_Event_Services_Dynamic,
  payload,
  serviceCardCount
});
// 
export const setEventPackagesDetails = (payload) => ({
  type: Set_Event_Packages_Details,
  payload,
});

export const getEventPackagesDetails = (payload) => ({
  type: Get_Event_Packages_Details,
  payload
});

// 
export const setDynamicEventServices = (payload) => ({
  type: Set_Dynamic_Event_Services,
  payload
});

export const getDynamicEventServices = (payload) => ({
  type: Get_Dynamic_Event_Services,
  payload,
});

export const resetEventServicesFilter = (data) => ({
  type: Reset_Event_Services_Filter,
  data,
});

export const setEventServicesFilter = (data) => ({
  type: Set_Event_Services_Filter,
  data,
});

export const resetEventServicesPayload = (data) => ({
  type: Reset_Event_Services_Payload,
  data,
});

export const setEventServicesPayload = (data) => ({
  type: Set_Event_Services_Payload,
  data,
});

export const setEventServicesIsDelete = (data) => {
  return {
    type: Set_Event_Services_IsDelete,
    data,
  }
};

export const toggleEventServicesPopup = (data) => {
  return {
    type: Toggle_Event_Services_Popup,
    data,
  }
};

export const setEventServicesApplyFilterData = (data) => {
  return {
    type: Set_Event_Services_Apply_Filter_Data,
    data,
  }
};

export const setHoldEventServicePayload = (data) => {
  return {
    type: Set_Hold_Event_Service_PayLoad,
    data,
  }
};

export const setHoldEventServiceFilter = (data) => {
  return {
    type: Set_Hold_Event_Service_FilterData,
    data,
  }
};

export const setEventServiceEditStart = (data) => {
  return {
    type: Set_Event_Service_Edit_Start,
    data,
  }
};

export const setEventServiceBrandTabs = (data) => {
  return {
    type: Set_Event_Service_Brand_Tabs,
    data,
  }
};
