import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
} from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import { useLocation, useNavigate } from "react-router";
import component from '../../../../components'
import { useDispatch, useSelector } from "react-redux";
import SearchField from "../../../../components/SearchField";
import { routePaths } from '../../../../routes/routePaths'
import useDebounce from "../../../../hooks/useDebounce";
import { staticConstants } from "../../../../constants/StaticConstants"
import { eventServicesStyles } from "./style"
import { MAIN_MATCH_CONSTANTS } from '../../../../constants/MainMatchConstants'
import {
  getEventServiceTypeAction,
  setEventServicesTypeInputData,
  setEventServicesIsDelete,
  setEventServicesApplyFilterData,
  setEventServicesFilter,
  setEventServicesPayload
} from "../../../../redux/EventServices/action";
import InfiniteScroll from 'react-infinite-scroll-component';

import './style.scss'
const { TopBar } = component
const { inputApiBody, serviceIdAny } = staticConstants.eventServiceStatic
const Index = () => {
  const navigate = useNavigate();
  const eventServiceType = useSelector((state) => state.EventServiceTypesData);
  const dispatch = useDispatch();
  const [eventServicesTypeSearch, setEventServicesTypeSearch] = useState(inputApiBody)
  const AllEventServiceInputStore = useSelector(state => state.AllEventServiceInputStore)
  const eventServicesTypeData = eventServiceType?.data?.body?.data || []
  const totalServiceData = eventServiceType?.data?.body?.count || 0
  const debouncedEventTypeSearch = useDebounce(eventServicesTypeSearch.keyword, 500);
  const { FILTER_LABELS } = MAIN_MATCH_CONSTANTS
  const maxData = 100
  const { state } = useLocation()
  const allFilterEventServicesStore = useSelector((state) => state.FilterEventServicesStore)
  const [selectServiceCardCount, setSelectServiceCardCount] = useState(30)


  useEffect(() => {
    if (debouncedEventTypeSearch) {
      dispatch(getEventServiceTypeAction(eventServicesTypeSearch, selectServiceCardCount))
    } else {
      dispatch(getEventServiceTypeAction({ keyword: "" }, selectServiceCardCount))
    }
  }, [debouncedEventTypeSearch, selectServiceCardCount]);

  const handleOnchangeSearch = (value) => {
    setEventServicesTypeSearch({ ...eventServicesTypeSearch, keyword: value })
  }

  const handleOnclickType = (serviceType) => {
    const serviceSelectData = serviceType?.serviceTypeId === 0 ? serviceIdAny : serviceType
    dispatch(setEventServicesTypeInputData(serviceSelectData))
    if (allFilterEventServicesStore.isFilter || routePaths.dynamicEventServiceRoute === state?.dynamicServicePath) {
      //matching routes
      navigate(routePaths.dynamicEventServiceRoute)
      dispatch(setEventServicesIsDelete(false))
      editFilterData(
        allFilterEventServicesStore.isFilter ? allFilterEventServicesStore.applyFilterData : allFilterEventServicesStore.filterData,
        FILTER_LABELS.SERVICE_EVENT_TYPE, serviceSelectData
      )
    } else {
      navigate(routePaths.eventLocation, { state: { isServicesScreens: true } })
    }
    dispatch(setEventServicesPayload(
      {
        ...allFilterEventServicesStore.apiPayload,
        serviceTypeId: serviceSelectData?.serviceTypeId
      }
    ))
  }

  const editFilterData = (data, type, updateData) => {
    const editedFilterData = data.map((items) => {
      if (items.label === type) {
        return {
          ...items, id: updateData.serviceTypeId,
          value: updateData.serviceTypeName, isDelete: false,
          serviceTypeId: updateData.serviceTypeId
        }
      }
      else {
        return items
      }
    })
    if (allFilterEventServicesStore.isFilter) {
      dispatch(setEventServicesApplyFilterData(editedFilterData))
    } else {
      dispatch(setEventServicesFilter(editedFilterData))
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  const fetchMoreData = () => {
    setSelectServiceCardCount(prevPage => prevPage + 30);
  };


  return (
    <>
      <Container maxWidth="md" sx={{ paddingBottom: '4rem', minHeight: '35rem', maxWidth: "720px", border: '1px solid #F2F2F2', paddingTop: '36px' }}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <TopBar
            heading={"SELECT A SERVICE"}
          />
          {/* Progress Bar */}
          {/* Search map input box */}
          <Grid item xs={12}>
            <SearchField
              placeholder={"Search Event Service"}
              handleOnchangeSearch={handleOnchangeSearch}
            />
          </Grid>

          {/* section 1  */}
          <Grid container className="list-container" xs={12} >
            {
              eventServicesTypeData instanceof Array && eventServicesTypeData.length > 0
                ?
                <>
                  <InfiniteScroll
                    dataLength={eventServicesTypeData?.length || 0}
                    next={fetchMoreData}
                    hasMore={totalServiceData !== eventServicesTypeData?.length}
                    loader={<Skeleton variant="text" sx={{ fontSize: '2.5rem', borderRadius: '10px' }} />}
                    scrollableTarget="scrollableDiv"
                  >
                    {
                      eventServicesTypeData.map((items) => {
                        return (
                          <Grid
                            loading
                            item
                            sx={eventServicesStyles.eventServicesBox.container}
                            onClick={(e) => handleOnclickType(items)}
                          >
                            <Box
                              component="span"
                              sx={eventServicesStyles.eventServicesBox.text}
                            >
                              {items.serviceTypeName}
                            </Box>
                          </Grid>

                        )
                      })
                    }
                  </InfiniteScroll>
                </>
                :
                Array.from(new Array(8)).map((item, index) => (
                  <Skeleton variant="text" sx={{ fontSize: '2.5rem' }} />
                ))
            }
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Index;
