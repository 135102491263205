export const budgetPrice = [
    [
        { id: 101, value: 500 },
        { id: 102, value: 1000 },
        { id: 103, value: 2500 },
        { id: 104, value: 5000 },
    ],
    [
        { id: 105, value: 7500 },
        { id: 106, value: 10000 },
        { id: 107, value: 15000 },
        { id: 108, value: 20000 },
    ],
    [
        { id: 109, value: 30000 },
        { id: 110, value: 50000 },
        { id: 111, value: 30000 },
        { id: 112, value: 50000 },
    ],
    [
        { id: 113, value: 75000 },
        { id: 114, value: 100000 },
        { id: 115, value: 'Other' },
        { id: 116, value: 'Not Sure' },
    ],
];

export const numberOfGuests = [
    [
        { id: 201, value: 50 },
        { id: 202, value: 100 },
        { id: 203, value: 150 },
        { id: 204, value: 200 },
        { id: 205, value: 250 },
    ],
    [
        { id: 206, value: 300 },
        { id: 207, value: 350 },
        { id: 208, value: 400 },
        { id: 209, value: 450 },
        { id: 210, value: 500 },
    ],
    [
        { id: 211, value: 600 },
        { id: 212, value: 700 },
        { id: 213, value: 750 },
        { id: 214, value: 1250 },
        { id: 215, value: 1500 },
    ],
    [
        { id: 216, value: 2000 },
        { id: 217, value: 2500 },
        { id: 218, value: 'Other' },
        { id: 219, value: 'Not Sure' },
    ],
];

export const numberOfGuestsMobile = [
    [
        { id: 201, value: 50 },
        { id: 202, value: 100 },
        { id: 203, value: 150 },
        { id: 204, value: 200 },
    ],
    [
        { id: 206, value: 300 },
        { id: 207, value: 350 },
        { id: 208, value: 400 },
        { id: 209, value: 450 },
    ],
    [
        { id: 211, value: 600 },
        { id: 212, value: 700 },
        { id: 213, value: 750 },
        { id: 214, value: 1250 },
    ],
    [
        { id: 216, value: 2000 },
        { id: 217, value: 2500 },
        { id: 218, value: 'Other' },
        { id: 219, value: 'Not Sure' },
    ],
];
