import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Box,
  Button
} from "@mui/material";
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useNavigate, useLocation } from "react-router";
import Card from "@mui/material/Card";
import { numberOfGuests, numberOfGuestsMobile } from '../../../../constants/DataRowConstants'
import { EventLocationStyles } from '../EventLocation/style'
import { routePaths } from '../../../../routes/routePaths'
import {
  setEventGuestsInputData,
  setEventPackagesIsDelete,
  setEventPackagesFilter,
  setEventPackagesFilterApplyFilterData
} from "../../../../redux/PackagesServices/action"
import { useDispatch, useSelector } from "react-redux";
import { staticConstants } from '../../../../constants/StaticConstants'
import { MAIN_MATCH_CONSTANTS } from '../../../../constants/MainMatchConstants'
import component from '../../../../components'
const { TopBar, ProgressBar } = component

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showButton, setShowButton] = useState(false)
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [error, setError] = useState(false);
  const [guestValue, setGuestValue] = useState("")
  const allEventData = useSelector((state) => state.AllPackagesServiceStore);
  const location = useLocation()
  const allPackagesFilterData = useSelector((state) => state.FilterEventPackagesStore)
  const { FILTER_LABELS, NOT_SURE, OTHER } = MAIN_MATCH_CONSTANTS
  const { defaultSettings } = staticConstants
  const [guestData, setGuestData] = useState(numberOfGuests)

  const handleToggleRedirect = (item) => {
    if (item.value === 'Other') {
      setShowButton(true)
      dispatch(setEventGuestsInputData({ type: item.value, ...allEventData?.guests, id: item.id }))
    } else {
      if (allEventData.isFilter || routePaths.packages === location?.state?.packagesPath) {
        navigate(routePaths.packages)
        dispatch(setEventPackagesIsDelete(false))
        editFilterData(
          allEventData.isFilter ? allPackagesFilterData.applyFilterData : allPackagesFilterData.filterData,
          FILTER_LABELS.NO_OF_GUEST, item
        )
      } else {
        navigate(routePaths.eventBudget)
      }
      setShowButton(false)
      dispatch(setEventGuestsInputData(item))
    }
  }

  const handeleOnChange = (e) => {
    dispatch(setEventGuestsInputData({ ...allEventData?.guests, value: parseInt(e.target.value) }))
    setGuestValue(e.target.value)
    if (!e.target.value) {
      setError(true)
      setShowButton(true)
    } else {
      setError(false)
    }
  }

  const handleRedirect = () => {
    if (guestValue.length === 0 && !allEventData.guests) {
      setError(true)
    } else {
      setShowButton(false)
      editFilterData(
        allEventData.isFilter ? allPackagesFilterData.applyFilterData : allPackagesFilterData.filterData,
        FILTER_LABELS.NO_OF_GUEST, allEventData?.guests
      )
      if (allEventData.isFilter || routePaths.packages === location?.state?.packagesPath) {
        //matching routes
        navigate(routePaths.packages)
        dispatch(setEventPackagesIsDelete(false))
      } else {
        navigate(routePaths.eventBudget)
      }
    }
  }

  const editFilterData = (data, type, updateData) => {
    const editedFilterData = data.map((items) => {
      if (items.label === type) {
        return {
          ...items,
          id: (NOT_SURE !== updateData.value && OTHER !== updateData.value) ? updateData.id : 219,
          value: (NOT_SURE !== updateData.value && OTHER !== updateData.value) ? updateData.value : NOT_SURE,
          maxGuests: (NOT_SURE !== updateData.value && OTHER !== updateData.value) ? updateData.value : defaultSettings.guestsStatic.defaultMax,
          delete: false,
        }
      }
      else {
        return items
      }
    })
    if (allEventData.isFilter) {
      dispatch(setEventPackagesFilterApplyFilterData(editedFilterData))
    } else {
      dispatch(setEventPackagesFilter(editedFilterData))
    }
  }

  useEffect(() => {
    if (isSmallScreen) setGuestData(numberOfGuestsMobile)
    else setGuestData(numberOfGuests)
  }, [isSmallScreen])

  return (
    <Container maxWidth="md" sx={{ paddingBottom: '4rem', maxWidth: "720px", border: '1px solid #F2F2F2', paddingTop: '36px', marginBottom: '50px' }}>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        {/* Event location box */}
        <TopBar
          heading={"NO OF GUESTS"}
        />
        {/* Progress Bar */}
        {/* <Box sx={{ marginBottom: '1rem' }}> */}
        {
          !(routePaths.packages === location?.state?.packagesPath) &&
          <ProgressBar style={{ marginBottom: !isSmallScreen ? '1rem' : '1.8rem' }} value={75} />
        }
        {/* </Box> */}
        {guestData.map((row, rowIndex) => (
          <Grid sx={{ marginLeft: '1rem', minHeight: '87px' }} key={rowIndex} container spacing={2}>
            {row.map((item, colIndex, array) => {
              const isLastCol = colIndex === array.length - 1;
              return <React.Fragment key={item.id}>
                <Grid
                  item
                  xs={
                    isSmallScreen ?
                      (
                        6
                      ) :
                      (
                        item.value === NOT_SURE ? 4.4 :
                          2
                      )
                  }
                  sx={
                    {
                      margin: !isSmallScreen && 'auto',
                      mt: !isSmallScreen && 2
                    }
                  }
                >
                  <Card
                    onClick={(e) => {
                      handleToggleRedirect(item);
                    }}
                    sx={{
                      // backgroundColor: '#363636',
                      borderRadius: '10px',
                      height: 90,
                      textAlign: 'center',
                      marginBottom: (isLastCol && isSmallScreen) && '1rem',
                      width: `${item.value === 'Not Sure' ? '100%' : '109'}`,
                      display: 'flex',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: allEventData?.guests?.id === item.id ? '#009485' : '#5c5c5c',
                      },
                      backgroundColor: allEventData?.guests?.id === item.id ? '#009485' : '#363636',
                    }}
                  >
                    <Typography variant={'h4'} sx={{ fontFamily: 'Open Sans, sans-serif', color: 'white', p: 2, paddingTop: '27px' }}>
                      {item.value}
                    </Typography>
                  </Card>
                </Grid>
              </React.Fragment>
            })}
          </Grid>
        ))}

        {
          (showButton || allEventData?.guests?.type) &&
          <><Grid item xs={12}>
            <Box sx={{
              display: 'flex', justifyContent: 'center', marginTop: '4px', borderRadius: '20px', textDecoration: 'none',
              minWidth: '337px',
              height: '47px',
            }}>
              <TextField
                error={error}
                helperText={error ? 'Please enter no of guest value' : ''}
                type="number"
                className="form-control-search"
                placeholder="Enter number of Guests"
                aria-label="Search"
                variant="outlined"
                value={allEventData.guests?.otherValue}
                onChange={(e) => { handeleOnChange(e) }}
                sx={{
                  minWidth: '337px',
                  height: '47px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none', // Remove the default border
                    },
                    '&:hover fieldset': {
                      border: 'none', // Remove the border on hover
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none', // Remove the border on focus
                    },
                  },
                  '& input': {
                    background: '#f2f2f2',
                    padding: '12px',
                    borderRadius: '21px',
                    paddingLeft: '45px',
                    // height: '47px',
                    border: '1px solid #f4f4f4', // Border for unfocused state
                    transition: 'border-color 0.2s ease-in-out', // Transition for border change
                    '&:focus': {
                      borderColor: 'transparent', // Remove border on focus
                    },
                    '&:hover': {
                      borderColor: 'transparent', // Remove border on hover
                    },
                  },
                }}
              />
            </Box>
          </Grid>
            <Grid item xs={12}>
              <Box sx={EventLocationStyles.buttonContent.container}>
                <Button variant="contained" onClick={() => { handleRedirect() }} sx={EventLocationStyles.buttonContent.button}>
                  CONTINUE
                </Button>
              </Box>
            </Grid>
          </>
        }
      </Grid>
    </Container>
  );
};

export default Index;
