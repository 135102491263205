import {
  SetEventServicesType,
  SetEventServicesTypeIsLoading,
  setInputEventServiceTypes,
  Set_Event_Service_Budget_Input_Data,
  Set_Event_Services_Search_Dynamic,
  Set_Event_Services_Dynamic_Is_Loading,
  Set_Dynamic_Event_Services,
  Set_Dynamic_Event_Services_Is_Loading,
  Reset_Event_Services_Filter,
  Set_Event_Services_Filter,
  Reset_Event_Services_Payload,
  Set_Event_Services_Payload,
  Set_Event_Services_IsDelete,
  Toggle_Event_Services_Popup,
  Set_Event_Services_Apply_Filter_Data,
  Set_Hold_Event_Service_PayLoad,
  Set_Hold_Event_Service_FilterData,
  Set_Event_Service_Edit_Start,
    // new
    Set_Event_Packages_Details,
    // new 
  Set_Event_Service_Brand_Tabs
} from "./actionType";
import { MAIN_MATCH_CONSTANTS } from '../../constants/MainMatchConstants'
import { staticConstants } from '../../constants/StaticConstants'
import { routePaths } from '../../routes/routePaths'

const { budgetStatic, radiusStatic, guestsStatic } = staticConstants.defaultSettings

const { FILTER_LABELS, DYNAMIC_SCREEN_TYPES, TYPES } = MAIN_MATCH_CONSTANTS


const allEventServicesInitialState = {
  eventServicesType: {},
  serviceBudget: {}
}

export const AllEventServiceInputStore = (state = allEventServicesInitialState, action) => {
  switch (action.type) {
    case setInputEventServiceTypes:
      return {
        ...state,
        eventServicesType: action.data,
      };
    case Set_Event_Service_Budget_Input_Data:
      return {
        ...state,
        serviceBudget: action.data,
      };
    default:
      return state;
  }
};

export const EventServiceTypesData = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case SetEventServicesType:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case SetEventServicesTypeIsLoading:
      return {
        ...state,
        loading: action.data,
      };
    case Set_Event_Services_Dynamic_Is_Loading:
      return {
        ...state,
        loading: action.data,
      };
    default:
      return state;
  }
};

export const EventServiceDynamicSearch = (state = { dynamicEventServiceScreen: {}, loading: false }, action) => {
  switch (action.type) {
    case Set_Event_Services_Search_Dynamic:
      return {
        ...state,
        dynamicEventServiceScreen: action.data,
      };
    case Set_Event_Services_Dynamic_Is_Loading:
      return {
        ...state,
        loading: action.data,
      };
    default:
      return state;
  }
};

export const DynamicEventServices = (state = { services: {}, loading: false }, action) => {
  switch (action.type) {
    case Set_Dynamic_Event_Services:
      return {
        ...state,
        services: action.data,
      };
    case Set_Dynamic_Event_Services_Is_Loading:
      return {
        ...state,
        loading: action.data,
      };
    default:
      return state;
  }
};

const isLocationExist = JSON.parse(localStorage.getItem('location'))
const initialFilterConstants = [
  {
    label: FILTER_LABELS.LOCATIONS,
    value: isLocationExist?.formatted_address,
    id: isLocationExist?.place_id ? isLocationExist?.place_id : 14548,
    path: routePaths.eventLocation,
    isDelete: true,
    pathState: { packagesPath: routePaths.dynamicEventServiceRoute },
    defaultDeleteName: "Select Location",
  }, {
    label: FILTER_LABELS.BUDGET,
    value: 100000,
    id: 114,
    path: routePaths.servicesBudget,
    maxPrice: 100000,
    isDelete: false,
    pathState: { type: DYNAMIC_SCREEN_TYPES.SELECT_SERVICE, dynamicServicePath: routePaths.dynamicEventServiceRoute }
  },
  {
    label: FILTER_LABELS.SERVICE_EVENT_TYPE,
    value: "Any Service Type",
    id: 438,
    path: routePaths.servicesSelect,
    isDelete: false,
    serviceTypeId: 438,
    pathState: { type: DYNAMIC_SCREEN_TYPES.EVENT_SERVICES_BUDGET, dynamicServicePath: routePaths.dynamicEventServiceRoute }
  },
  {
    label: FILTER_LABELS.EVENT_TYPE,
    path: routePaths.eventType,
    pathState: { type: TYPES.EVENT_TYPE, reBackPagePath: routePaths.dynamicEventServiceRoute },
    value: "Any Event",
    defaultDeleteName: "Select Event Type",
    id: 74,
    eventTypeId: 74,
    isDelete: true
  },
  {
    label: FILTER_LABELS.COMMUNITY_AND_CULTURE,
    value: "Any Community",
    defaultDeleteName: "Select Community",
    id: 1602,
    path: routePaths.eventType,
    pathState: { type: TYPES.COMMUNITY, reBackPagePath: routePaths.dynamicEventServiceRoute },
    communityId: 1602,
    isDelete: true
  },
  {
    label: FILTER_LABELS.RELIGION,
    value: "Any Religion",
    defaultDeleteName: "Select Religion",
    id: 8,
    path: routePaths.eventType,
    pathState: { type: TYPES.RELIGION, reBackPagePath: routePaths.dynamicEventServiceRoute },
    religionId: 8,
    isDelete: true
  },
]

const initialEventServicePayload = {
  communityId: staticConstants.defaultEventType.communityId,
  religionId: staticConstants.defaultEventType.religionId,
  serviceTypeId: 438,
  keyword: "",
  radius: radiusStatic.default,
  minPrice: budgetStatic.defaultMin,
  maxPrice: budgetStatic.defaultMax,
  minGuests: guestsStatic.defaultMin,
  maxGuests: guestsStatic.defaultMax,
}
export const FilterEventServicesStore = (state = {
  filterData: [],
  apiPayload: initialEventServicePayload,
  applyFilterData: initialFilterConstants,
  holdApiPayload: {},
  holdFilterData: [],
  isDelete: false,
  isFilter: false,
  isEditStart: false,
  activeBrandTab: MAIN_MATCH_CONSTANTS.ONE

}, action) => {
  switch (action.type) {
    case Reset_Event_Services_Filter:
      return {
        ...state,
        filterData: initialFilterConstants,
      };
    case Set_Event_Services_Filter:
      return {
        ...state,
        filterData: action.data,
      };
    case Reset_Event_Services_Payload:
      return {
        ...state,
        apiPayload: action.data,
      };
    case Set_Event_Services_Payload:
      return {
        ...state,
        apiPayload: action.data,
      };
    case Set_Event_Services_IsDelete:
      return {
        ...state,
        isDelete: action.data,
      };
    case Toggle_Event_Services_Popup:
      return {
        ...state,
        isFilter: !state.isFilter,
      };
    case Set_Event_Services_Apply_Filter_Data:
      return {
        ...state,
        applyFilterData: action.data,
      };
    case Set_Hold_Event_Service_PayLoad:
      return {
        ...state,
        holdApiPayload: action.data,
      };
    case Set_Hold_Event_Service_FilterData:
      return {
        ...state,
        holdFilterData: action.data,
      };
    case Set_Event_Service_Edit_Start:
      return {
        ...state,
        isEditStart: action.data,
      };
    case Set_Event_Service_Brand_Tabs:
      return {
        ...state,
        activeBrandTab: action.data,
      };
    default:
      return state;
  }
};

// new
export const EventPackagesDetails = (state = { inputData: {} }, action) => {
  switch (action.type) {
    case Set_Event_Packages_Details:
      return {
        ...state,
        inputData: action.data,
      };
    default:
      return state;
  }
};
// new