const LoginAuth = {
    login: () => 'app/users/login/23484f3d-c696-4164-8abd-fd78a2159bc6i',
    register: () => 'app/users/register/23484f3d-c696-4164-8abd-fd78a2159bc6i',
    verifyOtp: () => 'app/users/verify-otp/23484f3d-c696-4164-8abd-fd78a2159bc6i',
    reSendOtp: () => 'app/users/resend-otp/23484f3d-c696-4164-8abd-fd78a2159bc6i',
    logout: () => 'logout',
    changePassword: () => 'change-password',
    getProfile: () => 'profile',
    resetPassword: () => 'reset-password',
}
export default LoginAuth