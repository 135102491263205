import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { Box, CircularProgress } from "@mui/material";
// Add a request interceptor
const Interceptor = () => {
    const [isLoading, setIsLoading] = useState(false)
    const pathname = window.location.pathname;
    const navigate = useNavigate();
    axios.interceptors.request.use((config) => {
        const AUTH_TOKEN = localStorage.getItem("access_token")
        let isLogin = (config.url?.split("/")[config.url?.split("/").length - 1] == 'login')
        if (config)
            if (config.headers)
                if (!isLogin)
                    config.headers.Authorization = `Bearer ${AUTH_TOKEN}`;

        if (!AUTH_TOKEN) {
            // navigate("/login");
        }
        setIsLoading(true)
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    axios.interceptors.response.use(
        response => {
            // setTimeout(() => {
            setIsLoading(false)
            // }, 100);
            return response
        }, (error) => {
            // setTimeout(() => {
            setIsLoading(false)
            // }, 100);
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    if (error.response.status == 401) {

                        navigate("/login");
                        // window.location.reload()
                    } else if (error.response.status == 503) {

                        navigate("/login");
                        window.location.reload()
                    }
                }
            }
            return Promise.reject(error);
        }
    )

    return (
        <div>
            {isLoading && 
            <Box sx={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                alignItems: 'center',
                zIndex: 50
            }}>
                <CircularProgress />
            </Box>
            }
        </div >
    )
}
export default Interceptor;