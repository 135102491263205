
export const ProgressBarStyle = {
    stepper: {
        height: '10px',
        borderRadius: '27px',
        backgroundColor: '#DCDCDC',
        '& .MuiLinearProgress-bar': {
            backgroundColor: '#EEB000',
        },
    }
}