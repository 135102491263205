import { combineReducers } from "redux";

import { ProfileData, AllLoginDetails } from "./auth/reducers";
import { IsAppLoadingReducer } from './AppLoading/reducer'
import {
  EventServicesIncludes, EventServices,
  ReligionData, EventTypesData,
  CommunityData, AllPackagesServiceStore,
  EventSettings, EventPackagesSearch,
  EventPackagesServices,
  FilterEventPackagesStore,
  PackagesSearchKeywords
} from "./PackagesServices/reducers";
import {
  EventServiceTypesData,
  AllEventServiceInputStore,
  EventServiceDynamicSearch,
  DynamicEventServices,
  FilterEventServicesStore,
   // new
   EventPackagesDetails,
   // new
} from './EventServices/reducers'
import {
  BrandPackages,
  BrandService
} from './CommonBrandPackages/reducers'
const rootReducer = combineReducers({
  IsAppLoadingReducer,
  AllPackagesServiceStore,
  ProfileData,
  ReligionData,
  EventTypesData,
  CommunityData,
  AllLoginDetails,
  EventSettings,
  EventPackagesSearch,
  EventPackagesServices,
  EventPackagesDetails,
  EventServicesIncludes,
  EventServices,
  EventServiceTypesData,
  AllEventServiceInputStore,
  EventServiceDynamicSearch,
  DynamicEventServices,
  FilterEventServicesStore,
  FilterEventPackagesStore,
  PackagesSearchKeywords,
  BrandPackages,
  BrandService
});

export default rootReducer;
