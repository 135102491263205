import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  IconButton,
  Box,
  Tooltip,
  Skeleton
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModalPopUp from "../../../../components/ModalPopUp";
import { useNavigate, useLocation } from "react-router";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import component from '../../../../components'
import { useDispatch, useSelector } from "react-redux";
import SearchField from "../../../../components/SearchField";
import { routePaths } from '../../../../routes/routePaths'
import useDebounce from "../../../../hooks/useDebounce";
import { staticConstants } from "../../../../constants/StaticConstants"
import { eventTypeStyles } from "./style"
import { MAIN_MATCH_CONSTANTS } from '../../../../constants/MainMatchConstants'
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  getReligionTypeAction, getEventTypeAction, getCommunityTypeAction, setEventTypeInputData,
  setEventCommunityInputData, setEventReligionInputData,
  setEventPackagesIsDelete, setEventPackagesFilterApplyFilterData,
  setEventPackagesFilter
} from "../../../../redux/PackagesServices/action";
import {
  setEventServicesIsDelete,
  setEventServicesApplyFilterData,
  setEventServicesFilter,
  setEventServicesPayload
} from '../../../../redux/EventServices/action'
import { isEmptyObject } from '../../../../utils/emptyHelpers'
const { TopBar, ProgressBar } = component
const { modalStatic, eventSearchStatic, religionCommunityStatic } = staticConstants.eventTypeConstants
const Index = () => {
  const navigate = useNavigate();
  const [openPopUp, setOpenPopUp] = useState(false);
  const eventReligion = useSelector((state) => state.ReligionData);
  const eventType = useSelector((state) => state.EventTypesData);
  const eventCommunity = useSelector((state) => state.CommunityData);
  const allEventData = useSelector((state) => state.AllPackagesServiceStore);
  const dispatch = useDispatch();
  const eventCardData = eventType?.data?.body?.data || []
  const totalEventCount = eventType?.data?.body?.count || 0
  const [eventStates, setEventStates] = useState({ modalHeading: "", listDataKey: "" })
  const { modalHeading } = eventStates;
  const [searchTerm, setSearchTerm] = useState()
  const [eventTypeSearch, setEventTypeSearch] = useState(eventSearchStatic)
  const [religionCommunityText, setReligionCommunityText] = useState(religionCommunityStatic)
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const debouncedEventTypeSearch = useDebounce(eventTypeSearch.keyword, 500);
  const { state: useLocationState } = useLocation();
  const { TYPES, FILTER_LABELS } = MAIN_MATCH_CONSTANTS
  const allPackagesFilterData = useSelector((state) => state.FilterEventPackagesStore)
  const allFilterEventServicesStore = useSelector((state) => state.FilterEventServicesStore)
  const [eventTypeCount, setEventTypeCount] = useState(100)
  const [isReligionLoading, setIsReligionLoading] = useState(false)
  const [isCommunityLoading, setIsCommunityLoading] = useState(false)

  useEffect(() => {
    dispatch(getReligionTypeAction({ "keyword": "" }, setIsReligionLoading));
    dispatch(getCommunityTypeAction({ "keyword": "" }, setIsCommunityLoading));
  }, [eventTypeSearch.religionId, eventTypeSearch.communityId]);


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    if (debouncedSearchTerm) {
      if (modalHeading === modalStatic.communityHeading) {
        dispatch(getCommunityTypeAction({ "keyword": searchTerm }, setIsCommunityLoading));
      }
      else {
        dispatch(getReligionTypeAction({ "keyword": searchTerm }, setIsReligionLoading));
      }
    } else {
      if (modalHeading === modalStatic.communityHeading) {
        dispatch(getCommunityTypeAction({ "keyword": "" }, setIsCommunityLoading));
      }
      if (modalHeading === modalStatic.religionHeading) {
        dispatch(getReligionTypeAction({ "keyword": "" }, setIsReligionLoading));
      }
    }
  }, [debouncedSearchTerm, modalHeading]);

  useEffect(() => {
    const { community, religion } = allEventData
    if (debouncedEventTypeSearch) {
      dispatch(getEventTypeAction(eventTypeSearch, eventTypeCount))
    } else {
      dispatch(getEventTypeAction(
        {
          ...eventTypeSearch,
          communityId: community?.communityId ? community?.communityId : eventSearchStatic.communityId,
          religionId: religion?.religionId ? religion?.religionId : eventSearchStatic.religionId,
          keyword: ""
        },
        eventTypeCount
      ))
    }
  }, [debouncedEventTypeSearch, eventTypeCount]);


  const handleCloseOpenModal = (type) => {
    // re back to screen when user do not select value while coming from filter modal
    if (openPopUp && useLocationState?.reBackPagePath) {
      navigate(useLocationState?.reBackPagePath)
    }
    //end
    setOpenPopUp(!openPopUp);
    if (type === TYPES.COMMUNITY) {
      setEventStates({
        modalHeading: modalStatic.communityHeading,
        listDataKey: "communityName"
      })
    } else if (type === TYPES.RELIGION) {
      setEventStates({
        modalHeading: modalStatic.religionHeading,
        listDataKey: "religionName"
      })
    } else {
      setSearchTerm("")
    }
  };


  useEffect(() => {
    if (!isEmptyObject(useLocationState) && useLocationState?.type !== TYPES.EVENT_TYPE && !useLocationState?.isServicesScreens) {
      handleCloseOpenModal(useLocationState.type)
    }
  }, [useLocationState])

  const handleOnEventType = (card) => {
    if (allEventData.isFilter) {
      navigate(routePaths.packages)
      isRedirectPackages(card)
    } else {
      if (isEmptyObject(allEventData.religion)) {
        dispatch(setEventReligionInputData(staticConstants.eventTypeConstants.defaultReligion))
      }
      if (isEmptyObject(allEventData.community)) {
        dispatch(setEventCommunityInputData(staticConstants.eventTypeConstants.defaultCommunity))
      }
      isRedirectPackages(card)
    }
    dispatch(setEventTypeInputData(card));
  }
  const handleOnchangeSearch = (value) => {
    setEventTypeSearch({ ...eventTypeSearch, keyword: value })
  }
  const handleModalSearch = (value) => {
    setSearchTerm(value)
  }

  const isRedirectPackages = (items) => {
    if (useLocationState?.type === TYPES.EVENT_TYPE || allEventData.isFilter) {
      if (useLocationState?.dynamicServicePath) {
        dispatch(setEventServicesIsDelete(false))
        editFilterData(
          allFilterEventServicesStore.isFilter ? allFilterEventServicesStore.applyFilterData : allFilterEventServicesStore.filterData,
          FILTER_LABELS.EVENT_TYPE, items
        )
        dispatch(setEventServicesPayload(
          {
            ...allFilterEventServicesStore.apiPayload,
            eventTypeId: items.eventTypeId,
          }
        ))
        navigate(routePaths.dynamicEventServiceRoute)
      }
      else {
        navigate(routePaths.packages)
        dispatch(setEventPackagesIsDelete(false))
        editFilterData(
          allEventData.isFilter ? allPackagesFilterData.applyFilterData : allPackagesFilterData.filterData,
          FILTER_LABELS.EVENT_TYPE, items
        )
      }
    }
    else {
      if (useLocationState?.isServicesScreens) { // redirecting to service page 
        navigate(routePaths.servicesBudget)
      }
      else {
        navigate(routePaths.eventGuests)
      }

    }
  }

  const onClickOnlist = (listItem) => {
    const { community, religion } = allEventData
    if (modalHeading === modalStatic.communityHeading) {
      setReligionCommunityText({ ...religionCommunityText, communityText: listItem.communityName })
      dispatch(getEventTypeAction(
        {
          ...eventTypeSearch, communityId: listItem.communityId,
          religionId: religion?.religionId ? religion?.religionId : eventSearchStatic.religionId
        },
        eventTypeCount
      ))
      dispatch(setEventCommunityInputData(listItem))
      if (useLocationState?.dynamicServicePath) {
        dispatch(setEventServicesIsDelete(false))
        editFilterData(
          allFilterEventServicesStore.isFilter ? allFilterEventServicesStore.applyFilterData : allFilterEventServicesStore.filterData,
          FILTER_LABELS.COMMUNITY_AND_CULTURE, listItem
        )
        dispatch(setEventServicesPayload(
          {
            ...allFilterEventServicesStore.apiPayload,
            communityId: listItem.communityId,
          }
        ))
      }
      else {
        editFilterData(
          allEventData.isFilter ? allPackagesFilterData.applyFilterData : allPackagesFilterData.filterData,
          FILTER_LABELS.COMMUNITY_AND_CULTURE, listItem
        )
      }
    }
    else {
      setReligionCommunityText({ ...religionCommunityText, religiontext: listItem.religionName })
      dispatch(getEventTypeAction(
        {
          ...eventTypeSearch, religionId: listItem.religionId,
          communityId: community?.communityId ? community?.communityId : eventSearchStatic.communityId
        },
        eventTypeCount
      ))
      dispatch(setEventReligionInputData(listItem))
      if (useLocationState?.dynamicServicePath) {
        dispatch(setEventServicesIsDelete(false))
        editFilterData(
          allFilterEventServicesStore.isFilter ? allFilterEventServicesStore.applyFilterData : allFilterEventServicesStore.filterData,
          FILTER_LABELS.RELIGION, listItem
        )
        dispatch(setEventServicesPayload(
          {
            ...allFilterEventServicesStore.apiPayload,
            religionId: listItem.religionId,
          }
        ))
      }
      else {
        editFilterData(
          allEventData.isFilter ? allPackagesFilterData.applyFilterData : allPackagesFilterData.filterData,
          FILTER_LABELS.RELIGION, listItem
        )
      }
    }
    if (!isEmptyObject(useLocationState) && !useLocationState?.isServicesScreens) {
      if (useLocationState?.dynamicServicePath) {
        navigate(routePaths.dynamicEventServiceRoute)
      } else {
        navigate(routePaths.packages);
        dispatch(setEventPackagesIsDelete(false))
      }
    }
    setOpenPopUp(!openPopUp);
  }

  const editFilterData = (data, type, updateData) => {
    const editedFilterData = data.map((items) => {
      switch (type) {
        case FILTER_LABELS.EVENT_TYPE:
          if (items.label === type) {
            return {
              ...items, id: updateData.eventTypeId,
              value: updateData.eventTypeName, delete: false,
              isDelete: false,
              eventTypeId: updateData.eventTypeId
            }
          }
          else {
            return items
          }
        case FILTER_LABELS.COMMUNITY_AND_CULTURE:
          if (items.label === type) {
            return {
              ...items,
              id: updateData.communityId,
              value: updateData.communityName,
              delete: false,
              isDelete: false,
              communityId: updateData.communityId
            }
          }
          else {
            return items
          }
        case FILTER_LABELS.RELIGION:
          if (items.label === type) {
            return {
              ...items,
              id: updateData.religionId,
              value: updateData.religionName,
              delete: false,
              isDelete: false,
              religionId: updateData.religionId
            }
          }
          else {
            return items
          }
        default:
          return items
      }
    })
    if (useLocationState?.dynamicServicePath) {
      //event service filter data
      if (allFilterEventServicesStore.isFilter) {
        dispatch(setEventServicesApplyFilterData(editedFilterData))
      } else {
        dispatch(setEventServicesFilter(editedFilterData))
      }
    } else {

      if (allEventData.isFilter) {
        dispatch(setEventPackagesFilterApplyFilterData(editedFilterData))
      } else {
        dispatch(setEventPackagesFilter(editedFilterData))
      }
    }
  }

  const fetchMoreData = () => {
    setEventTypeCount(prevPage => prevPage + 10);
  };
  return (
    <>
      <Container maxWidth="md" sx={{ paddingBottom: '4rem', maxWidth: "720px", border: '1px solid #F2F2F2', paddingTop: '36px' }}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <TopBar
            heading={"EVENT TYPE"}
          />
          {/* Progress Bar */}
          {
            !(useLocationState?.type === TYPES.EVENT_TYPE || routePaths.dynamicEventServiceRoute === useLocationState?.dynamicEventServiceRoute) &&
            <ProgressBar value={50} />
          }
          {/* Search map input box */}
          <Grid item xs={12}>
            <SearchField
              placeholder={"Search Event Type"}
              handleOnchangeSearch={handleOnchangeSearch}
            />
          </Grid>
          {/* section 1  */}
          <Grid item xs={12}>
            <Box
              component="div"
              sx={eventTypeStyles.communityReligion.container}
              onClick={(e) => handleCloseOpenModal("community")}
            >
              <Box
                component="span"
                sx={eventTypeStyles.communityReligion.text}
              >
                {allEventData.community?.communityName ? allEventData.community?.communityName : religionCommunityText.communityText}
              </Box>
              <IconButton edge="end">
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Grid>
          {/* section 2  */}
          <Grid item xs={12}>
            <Box
              component="div"
              onClick={(e) => handleCloseOpenModal("religion")}
              sx={eventTypeStyles.communityReligion.container}
            >
              <Box
                component="span"
                sx={eventTypeStyles.communityReligion.text}
              >
                {allEventData.religion?.religionName ? allEventData?.religion?.religionName : religionCommunityText.religiontext}
              </Box>
              <IconButton edge="end">
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Grid>
          {/* This is my card content */}
          {/* <InfiniteScroll
            dataLength={eventCardData?.length || 0}
            next={fetchMoreData}
            hasMore={totalEventCount !== eventCardData?.length}
            loader={Array.from(new Array(8)).map((item, index) => (
              <Grid item key={index} xs={6} sm={6} md={4} lg={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Box
                  sx={{
                    ...eventTypeStyles.cards.container,
                  }}
                >
                  <Skeleton
                    sx={{
                      ...eventTypeStyles.cards.cardMedia,
                    }}
                    variant="rectangular" />
                  <Box sx={
                    { display: 'flex', justifyContent: 'center' }
                  }>
                    <Skeleton
                      sx={
                        {
                          marginTop: '0.6rem'
                        }
                      }
                      width="60%" />
                  </Box>
                </Box>
              </Grid>
            ))}
            scrollableTarget="scrollableDiv"
          >

          </InfiniteScroll> */}
          <Grid container sx={{ paddingLeft: '20px', paddingTop: '33px', margin: 0, gridGap: 'inherit', justifyContent: 'left' }}>
            {
              eventCardData instanceof Array && eventCardData.length > 0 ?
                eventCardData.map((card, index) => (
                  <Grid item xs={6} sm={6} md={4} lg={3} key={index} sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                    <Card
                      sx={{
                        ...eventTypeStyles.cards.container,
                        // ...(allEventData?.eventType?.eventTypeId === card.eventTypeId && eventTypeStyles.cards.selectedCard),
                      }}
                      onClick={() => { handleOnEventType(card) }}
                    >
                      <CardMedia
                        sx={{
                          ...eventTypeStyles.cards.cardMedia,
                          ...(allEventData?.eventType?.eventTypeId === card.eventTypeId && eventTypeStyles.cards.selectedCard),
                        }}
                        image={card.imageUrl}
                        title="green iguana"
                      />
                      <CardContent >
                        <Tooltip title={card.eventTypeName} placement="top-start">
                          <Typography
                            sx={eventTypeStyles.cards.text}
                          >
                            {card.eventTypeName}
                          </Typography>
                        </Tooltip>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
                :
                <Grid container spacing={2}>
                  {
                    eventType.loading ?
                      Array.from(new Array(8)).map((item, index) => (
                        <Grid item key={index} xs={6} sm={6} md={4} lg={3}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <Box
                            sx={{
                              ...eventTypeStyles.cards.container,
                            }}
                          >
                            <Skeleton
                              sx={{
                                ...eventTypeStyles.cards.cardMedia,
                              }}
                              variant="rectangular" />
                            <Box sx={
                              { display: 'flex', justifyContent: 'center' }
                            }>
                              <Skeleton
                                sx={
                                  {
                                    marginTop: '0.6rem'
                                  }
                                }
                                width="60%" />
                            </Box>
                          </Box>
                        </Grid>
                      ))
                      :
                      <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Typography sx={{ paddingLeft: '405px' }}>
                          No Data Found
                        </Typography>
                      </Box>
                  }
                </Grid>
            }
          </Grid>
        </Grid>
      </Container>
      {openPopUp && <ModalPopUp
        onClickModal={handleCloseOpenModal}
        onClickOnlist={onClickOnlist}
        openDialog={openPopUp}
        topHeading={eventStates.modalHeading}
        listDataType={eventStates.listDataKey}
        handleOnchangeSearch={handleModalSearch}
        searchPlaceHolder={eventStates.modalHeading === modalStatic.communityHeading ? "Search Community" : "Search Religion"}
        isReligionLoading={isReligionLoading}
        isCommunityLoading={isCommunityLoading}
        listingDetails={eventStates.modalHeading === modalStatic.communityHeading ? eventCommunity?.data?.body?.data : eventReligion?.data?.body?.data}
      />}
    </>
  );
};

export default Index;
