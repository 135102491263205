import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  Avatar,
  Divider,
  Tab,
  Button,
  Tabs,
  IconButton,
} from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SearchIcon from '@mui/icons-material/Search';
import { EventPackagesStyles } from './style';
import TuneIcon from '@mui/icons-material/Tune';
import GridViewIcon from '@mui/icons-material/GridView';
import BrandCards from '../../../../components/BrandCards'
import { routePaths } from '../../../../routes/routePaths'
import { useNavigate, useParams } from 'react-router';
import { budgetPrice, numberOfGuests } from '../../../../constants/DataRowConstants'
import { findPreviousObject } from '../../../../utils/findPreviousObject'
import { getUniqueEventCompanies } from '../../../../utils/filterDataHelpers'
import {
  getEventPackagesSearch,
  setEventSearchInputData,
  setToggleIsFilter,
  setEventLocationInputData,
  setEventGuestsInputData,
  setEventBudgetInputData,
  setEventReligionInputData,
  setEventCommunityInputData,
  setEventTypeInputData,
  resetEventPackagesFilter,
  setEventPackagesFilter,
  setEventPackagesPayload,
  setEventPackagesIsDelete,
  setEventPackagesFilterApplyFilterData,
  resetEventPackagesPayload,
  setEventPackagesServiceEdit_Start,
  getPackagesSearchKeywords,
  setEventPackagesBrandTabs,
  getEventPackagesDetails
} from '../../../../redux/PackagesServices/action'
import { useDispatch, useSelector } from 'react-redux';
import PackagesCards from '../../../../components/PackagesCards'
import FilterModal from '../../../../components/FilterModal'
import { MAIN_MATCH_CONSTANTS } from '../../../../constants/MainMatchConstants'
import { staticConstants } from '../../../../constants/StaticConstants'
import { formatCurrency } from '../../../../utils/formatCurrency'
import KeywordSearch from '../../../../components/KeywordSearch'
import ScrollButtonBack from '../../../../components/ScrollButtonBack'
import ScrollButtonNext from '../../../../components/ScrollButtonNext'
import LoadingCardSkeleton from '../../../../components/LoadingCardSkeleton'
import InfiniteScroll from 'react-infinite-scroll-component';


const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
const Index = () => {
  const { packageId } = useParams();
  const packagesLoading = useSelector(state => state.EventPackagesSearch.loading)
  const dispatch = useDispatch()
  const packagesData = useSelector(state => state.EventPackagesSearch.packages?.body?.data)
  const totalResult = useSelector(state => state.EventPackagesSearch.packages?.body?.count)
  const navigate = useNavigate()
  const allEventData = useSelector((state) => state.AllPackagesServiceStore);
  const { community, religion, eventLocation, eventType, guests, budget, isFilter } = allEventData
  const [toggleImageText, setToggleImageText] = useState(false)
  const [openPopUp, setOpenPopUp] = useState(isFilter);
  const { eventLocation: eventLocationRoute, eventGuests, eventBudget, eventType: eventTypeRoute, packages } = routePaths
  const { FILTER_LABELS, TYPES, NOT_SURE, OTHER } = MAIN_MATCH_CONSTANTS
  const { guestsStatic, budgetStatic, radiusStatic } = staticConstants.defaultSettings
  const EventSettings = useSelector((state) => state.EventSettings.settings);
  const defaultSetting = {
    defaultGuests: EventSettings?.settings ? EventSettings.settings.guests : guestsStatic,
    defaultBudget: EventSettings?.settings ? EventSettings.settings.budget : budgetStatic,
    radiusDefault: eventLocation?.sliderValue ? eventLocation?.sliderValue : radiusStatic.default
  }
  const brandsData = useMemo(() => getUniqueEventCompanies(packagesData), [packagesData])
  const payload = {
    "keyword": "",
    "location": {
      "lat": eventLocation?.latitude,
      "lng": eventLocation?.longitude
    },
    "radius": defaultSetting.radiusDefault,
    "minPrice": (budget?.value && budget?.value !== 100000 && budget?.value !== NOT_SURE && budget?.value !== OTHER) ? findPreviousObject(budgetPrice, budget?.value)?.value : defaultSetting.defaultBudget.defaultMin,
    "maxPrice": (budget?.value && budget?.value !== NOT_SURE && budget?.value !== OTHER) ? budget?.value : defaultSetting.defaultBudget.defaultMax,
    "communityId": community?.communityId ? community?.communityId : staticConstants.defaultEventType.communityId,
    "eventDate": "12/03/2021",
    "minGuests": (guests?.value && guests?.value !== NOT_SURE) ? findPreviousObject(numberOfGuests, guests?.value)?.value : defaultSetting.defaultGuests.defaultMin,
    "maxGuests": (guests?.value && guests?.value !== NOT_SURE) ? guests?.value : defaultSetting?.defaultGuests?.defaultMax,
    "religionId": religion?.religionId ? religion?.religionId : staticConstants.defaultEventType.religionId,
    "eventTypeId": eventType?.eventTypeId ? eventType?.eventTypeId : staticConstants.defaultEventType.eventTypeId
  }

  const allPackagesFilterData = useSelector((state) => state.FilterEventPackagesStore)
  const keywordsData = useSelector((state) => state.PackagesSearchKeywords.keywords)
  const isLocationExist = JSON.parse(localStorage.getItem('location'))
  const filterConstants = [{
    label: FILTER_LABELS.LOCATIONS,
    value: isLocationExist?.formatted_address,
    id: isLocationExist?.place_id,
    path: eventLocationRoute,
    pathState: { packagesPath: packages },
    defaultDeleteName: "",
    delete: false,
    location: {
      lat: isLocationExist?.latitude,
      lng: isLocationExist?.longitude
    }
  },
  {
    label: FILTER_LABELS.BRANDS,
    value: "",
    id: 201514,
    path: packages,
    defaultDeleteName: "",
    delete: false
  },
  {
    label: FILTER_LABELS.NO_OF_GUEST,
    value: guests.value,
    id: guests.id,
    path: eventGuests,
    maxGuests: allPackagesFilterData.apiPayload?.maxGuests,
    pathState: { type: TYPES.NO_OF_GUEST, packagesPath: packages },
    defaultDeleteName: "",
    delete: false
  },
  {
    label: FILTER_LABELS.BUDGET,
    value: budget.value,
    id: budget.id,
    path: eventBudget,
    maxPrice: allPackagesFilterData.apiPayload?.maxPrice,
    defaultDeleteName: "",
    delete: false
  },
  {
    label: FILTER_LABELS.EVENT_TYPE,
    value: eventType?.eventTypeName,
    id: eventType?.eventTypeId,
    path: eventTypeRoute,
    pathState: { type: TYPES.EVENT_TYPE, reBackPagePath: packages },
    eventTypeId: eventType?.eventTypeId,
    defaultDeleteName: "",
    delete: false
  },
  {
    label: FILTER_LABELS.COMMUNITY_AND_CULTURE,
    value: community?.communityName,
    id: community?.communityId,
    path: eventTypeRoute,
    pathState: { type: TYPES.COMMUNITY, reBackPagePath: packages },
    communityId: community?.communityId,
    defaultDeleteName: "",
    delete: false
  },
  {
    label: FILTER_LABELS.RELIGION,
    value: religion?.religionName,
    id: religion?.religionId,
    path: eventTypeRoute,
    pathState: { type: TYPES.RELIGION, reBackPagePath: packages },
    religionId: religion?.religionId,
    defaultDeleteName: "",
    delete: false
  },
  ]
  const { isDelete, isEditStart } = allPackagesFilterData
  const prevApiPayloadRef = useRef(allPackagesFilterData.apiPayload);
  const [packagesCardCount, setPackagesCardCount] = useState(10)


  useEffect(() => {
    if (eventType?.eventTypeId) {
      if (!isDelete && !isFilter) {
        if (!isEditStart) {
          dispatch(setEventPackagesFilter(filterConstants))
          dispatch(setEventPackagesPayload(payload))
        } else {
          dispatch(setEventPackagesFilter(allPackagesFilterData.filterData))
          dispatch(setEventPackagesPayload(designFilterPayload(allPackagesFilterData.filterData)))
        }
      }

    } else {
      //reset filter when user reload's the page
      dispatch(resetEventPackagesPayload({
        ...allPackagesFilterData.apiPayload, serviceTypeId: 438,
        minPrice: defaultSetting.defaultBudget.defaultMin,
        maxPrice: defaultSetting.defaultBudget.defaultMax,
        radius: defaultSetting.radiusDefault,
        communityId: staticConstants.defaultEventType.communityId,
        eventDate: "12/03/2021",
        minGuests: defaultSetting.defaultGuests.defaultMin,
        maxGuests: defaultSetting.defaultGuests.defaultMax,
        religionId: staticConstants.defaultEventType.religionId,
        eventTypeId: staticConstants.defaultEventType.eventTypeId
      }))

    }
    dispatch(getPackagesSearchKeywords({ "keyword": "" })) // getting keyword
    dispatch(resetEventPackagesFilter(allPackagesFilterData.filterData))
    dispatch(resetEventPackagesPayload(allPackagesFilterData.apiPayload))
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    !isFilter && dispatch(setEventPackagesFilterApplyFilterData(allPackagesFilterData.filterData))
  }, [isDelete, isFilter, openPopUp])


  useEffect(() => {
    const currentApiPayload = allPackagesFilterData.apiPayload;
    if (!isFilter && JSON.stringify(prevApiPayloadRef.current) !== JSON.stringify(currentApiPayload)) {
      dispatch(getEventPackagesSearch(currentApiPayload, packagesCardCount));
    }
    prevApiPayloadRef.current = currentApiPayload;
  }, [allPackagesFilterData.apiPayload, isDelete, packagesCardCount]);

  useEffect(() => {
    if (packagesCardCount !== 10) {
      dispatch(getEventPackagesSearch(allPackagesFilterData.apiPayload, packagesCardCount));
    }
  }, [packagesCardCount]);

  const handleDelete = (chipToDelete) => () => {
    const cloneFilterData = [...allPackagesFilterData.filterData]
    const modifiedFilterData = cloneFilterData.map((items) => {
      if (chipToDelete.id === items.id) {
        switch (chipToDelete.label) {
          case FILTER_LABELS.EVENT_TYPE:
            dispatch(setEventTypeInputData(staticConstants.packageScreen.eventDefault))
            return { ...items, value: "Any Event", id: 74, eventTypeId: 74, delete: true, defaultDeleteName: 'Select Event Type' }
          case FILTER_LABELS.COMMUNITY_AND_CULTURE:
            dispatch(setEventCommunityInputData({ communityName: "Any Community", communityId: 1602, id: 1602, delete: true }))
            return { ...items, value: "Any Community", id: 1602, communityId: 1602, delete: true, defaultDeleteName: 'Select Community' }
          case FILTER_LABELS.RELIGION:
            dispatch(setEventReligionInputData({ religionName: "Any Religion", religionId: 8, id: 8 }))
            return { ...items, value: "Any Religion", id: 8, religionId: 8, delete: true, defaultDeleteName: 'Select Religion' }
          case FILTER_LABELS.NO_OF_GUEST:
            dispatch(setEventGuestsInputData({ ...guests, value: 1000 }))
            return { ...items, value: 1000, delete: true, defaultDeleteName: 'Select Guest' }
          case FILTER_LABELS.BUDGET:
            dispatch(setEventBudgetInputData({ ...budget, value: 100000 }))
            return { ...items, value: 100000, delete: true, defaultDeleteName: 'Select Budget' }
          case FILTER_LABELS.BRANDS:
            return { ...items, id: 201514, value: "", delete: false, defaultDeleteName: '', keyword: '' }
          case FILTER_LABELS.LOCATIONS:
            delete items.location
            return { ...items, id: 14548, value: "By Location", delete: true, defaultDeleteName: 'Select Location' }
          default:
            return { ...items, value: "", delete: true, defaultDeleteName: '' }
        }
      } else {
        return items
      }
    })

    dispatch(setEventPackagesIsDelete(true))
    dispatch(setEventPackagesFilter(modifiedFilterData))
    dispatch(setEventPackagesPayload(designFilterPayload(modifiedFilterData)))
    dispatch(setEventPackagesServiceEdit_Start(true))
    dispatch(setEventPackagesFilterApplyFilterData(modifiedFilterData))
  };

  const handleChange = (event, newValue) => {
    dispatch(setEventPackagesBrandTabs(newValue))
  };
  const handleBack = (e) => {
    e.preventDefault();
    navigate((!isFilter && isDelete) ? eventBudget : -1)
  }

  const handleOnClickCard = (items) => {
    dispatch(setEventSearchInputData(items))
    dispatch(getEventPackagesDetails(packageId))
    navigate(`/event/packages/details/${items.packageId}`, { state: { packagesData: items } })
  }

  const handleCloseOpenModal = () => {
    setOpenPopUp(!openPopUp);
    dispatch(setToggleIsFilter())
  };

  const handleClickOnFilter = (filteredItem) => {
    navigate(filteredItem.path, filteredItem.pathState ? { state: { ...filteredItem.pathState, isFilter } } : { state: { type: packages } })
  }

  const handleApplyFilter = (type) => {
    if (type === 'apply') {
      payload.keyword = allPackagesFilterData.applyFilterData ? allPackagesFilterData.applyFilterData[1]?.keyword : ""
      // delete payload.location
      dispatch(setEventPackagesPayload(payload))
      dispatch(setEventPackagesFilter(allPackagesFilterData.applyFilterData))
      handleCloseOpenModal()
    } else {
      dispatch(setEventPackagesPayload(allPackagesFilterData.resetApiPayload))
      dispatch(setEventPackagesFilter(allPackagesFilterData.resetFilterData))
      dispatch(setToggleIsFilter()) // added for reset with open pop up 
    }
    dispatch(setEventPackagesServiceEdit_Start(true))
  }

  const designFilterPayload = (data) => {
    const updatedPackagesPayload = { ...payload };
    data.forEach(item => {
      const key = MAIN_MATCH_CONSTANTS.LABEL_KEY_CHECK[item.label];
      if (key !== undefined) {
        if (key === MAIN_MATCH_CONSTANTS.MATCH_LOCATION) {
          updatedPackagesPayload.location = item.location
        } else if (key === MAIN_MATCH_CONSTANTS.MAX_PRICE) {
          updatedPackagesPayload[key] = (item.value && item?.value !== NOT_SURE && item?.value !== OTHER) ? item.value : defaultSetting.defaultBudget.defaultMax
          if (defaultSetting.defaultBudget.defaultMax === item.value) {
            updatedPackagesPayload.minPrice = 0
          } else {
            updatedPackagesPayload.minPrice = (item.value && item?.value !== NOT_SURE && item?.value !== OTHER) ? findPreviousObject(budgetPrice, item.value)?.value : defaultSetting.defaultBudget.defaultMin
          }
        } else if (key === MAIN_MATCH_CONSTANTS.MAX_GUESTS) {
          updatedPackagesPayload[key] = (item.value && item?.value !== NOT_SURE && item?.value !== OTHER) ? item.value : defaultSetting.defaultGuests.defaultMax
          if (defaultSetting.defaultGuests.defaultMax === item.value) {
            updatedPackagesPayload.minGuests = 1
          } else {
            updatedPackagesPayload.minGuests = item.value ? findPreviousObject(numberOfGuests, item.value)?.value : defaultSetting.defaultGuests.defaultMin
          }
        } else if (MAIN_MATCH_CONSTANTS.MATCH_EVENT_TYPE_KEY.includes(key)) {
          updatedPackagesPayload[key] = item[key]
        } else if (key === MAIN_MATCH_CONSTANTS.SEARCH_KEYWORD) {
          updatedPackagesPayload[key] = item[key]
        }
        else {
          updatedPackagesPayload[key] = item.value;
        }
      }
    });
    // delete updatedPackagesPayload.location
    return updatedPackagesPayload
  }

  const handleRedirectBadge = (items) => {
    handleClickOnFilter(items)
  }

  const handleOnclickKeywordList = (keyword) => {
    dispatch(resetEventPackagesFilter(allPackagesFilterData.filterData))
    dispatch(resetEventPackagesPayload(allPackagesFilterData.apiPayload))
    editFilterData(
      allEventData.isFilter ? allPackagesFilterData.applyFilterData : allPackagesFilterData.filterData,
      FILTER_LABELS.BRANDS, keyword
    )

  }

  const editFilterData = (data, type, updateData) => {
    const editedFilterData = data.map((items) => {
      if (items.label === type) {
        return {
          ...items,
          value: updateData,
          keyword: updateData,
          delete: false
        }
      }
      else {
        return items
      }
    })
    if (allEventData.isFilter) {
      dispatch(setEventPackagesFilterApplyFilterData(editedFilterData))
    } else {
      dispatch(setEventPackagesFilter(editedFilterData))
      dispatch(setEventPackagesFilterApplyFilterData(editedFilterData))
      dispatch(setEventPackagesPayload(designFilterPayload(editedFilterData)))
    }
  }

  const scrollableRef = useRef(null);
  const [isScrollVisible, setIsScrollVisible] = useState(false);

  useEffect(() => {
    checkScroll();
  }, []);


  const checkScroll = () => {
    if (
      scrollableRef &&
      scrollableRef.current &&
      scrollableRef.current.scrollWidth > scrollableRef.current.clientWidth
    ) {
      setIsScrollVisible(true);
    } else {
      setIsScrollVisible(false);
    }
  };

  const onScroll = (offset) => {
    if (scrollableRef && scrollableRef.current) {
      const currentScrollLeft = scrollableRef.current.scrollLeft;
      const newScrollLeft = currentScrollLeft + offset;
      scrollableRef.current.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const fetchMoreData = () => {
    setPackagesCardCount(prevPage => prevPage + 10);
  };

  return (
    <>
      <Container maxWidth="md" sx={{ maxWidth: '720px', border: '1px solid #F2F2F2', paddingTop: '36px', marginBottom: '3rem' }}>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Box sx={EventPackagesStyles.eventPackagesContainer.container}>
              <Avatar sx={EventPackagesStyles.eventPackagesContainer.avatar} onClick={(e) => { handleBack(e) }} >
                <KeyboardArrowLeftIcon sx={{ color: 'black' }} />
              </Avatar>
              <Typography variant="h5" sx={EventPackagesStyles.eventPackagesContainer.labelText}>
                PACKAGES
              </Typography>
              <KeywordSearch
                data={keywordsData}
                handleOnclickKeywordList={handleOnclickKeywordList}
                openType="packages"
              />
            </Box>
          </Grid>
          <Grid sx={EventPackagesStyles.listItems.container} item xs={12}>
            <Grid sx={{
              justifyContent: 'space-between'
            }} container>
              <Grid
                sx={{
                  textAlign: 'start',
                }}
                item xs={1} lg={0.5} md={0.5}>
                <ScrollButtonBack onScroll={onScroll} />
              </Grid>
              <Grid item xs={10} lg={11} md={11}>
                <Paper
                  sx={EventPackagesStyles.listItems.paper}
                  component="ul"
                  ref={scrollableRef}
                >
                  <ListItem >
                    <Chip
                      onClick={handleCloseOpenModal}
                      deleteIcon={<TuneIcon sx={{ color: 'black' }} />}
                      label={"Filters"}
                      dele="black"
                      onDelete={() => { }}
                      sx={{
                        ...EventPackagesStyles.listItems.deletedBadges,
                      }}
                    />
                  </ListItem>
                  {allPackagesFilterData.filterData.filter(value => value.value).map((data) => {
                    return (<React.Fragment key={data.key}>
                      <ListItem key={data.key}>
                        <Chip
                          onClick={() => { handleRedirectBadge(data) }}
                          label={`${!data.delete ?
                            (
                              data.label === FILTER_LABELS.NO_OF_GUEST ?
                                (NOT_SURE !== data.value ? formatCurrency(data.value) + " Guests" : NOT_SURE)
                                : data.label === FILTER_LABELS.BUDGET ?
                                  (NOT_SURE !== data.value ? "Budget £" + formatCurrency(data.value) : NOT_SURE)
                                  : data.value
                            ) : data.defaultDeleteName
                            }`
                          }
                          onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                          deleteIcon={
                            <CloseIcon style={{
                              ...EventPackagesStyles.listItems.icon,
                              display: data.delete ? 'none' : 'block'
                            }} />
                          }
                          sx={data.delete ? EventPackagesStyles.listItems.deletedBadges : EventPackagesStyles.listItems.badges}
                        />
                      </ListItem>
                    </React.Fragment>
                    );
                  })}
                </Paper>
              </Grid>
              <Grid
                sx={{
                  textAlign: 'end'
                }}
                item xs={1} lg={0.5} md={0.5}>
                <ScrollButtonNext onScroll={onScroll} />
              </Grid>
            </Grid>
            <Divider variant="fullWidth" orientation="horizontal" />
            <Box sx={EventPackagesStyles.tabBox.container}>
              <Tabs
                value={allPackagesFilterData.activeBrandTab}
                onChange={handleChange}
                textColor="black"
                aria-label="secondary tabs example"
                indicatorColor="primary"
                sx={EventPackagesStyles.tabBox.tabs}
              >
                <Tab value="one" label="Packages" />
                <Tab value="two" label="Brands" />
              </Tabs>
            </Box>
          </Grid>
        </Grid>
        <Divider variant="fullWidth" orientation="horizontal" />
        {
          allPackagesFilterData.activeBrandTab === MAIN_MATCH_CONSTANTS.TWO ? <>
            <BrandCards
              brandsData={brandsData || []}
            />
          </> :
            <>
              <Grid sx={EventPackagesStyles.resultFound.container}>
                <Typography>{totalResult ? totalResult : 0} Results Found</Typography>
                {
                  toggleImageText ? <Box onClick={() => setToggleImageText(!toggleImageText)} component="span">
                    <DehazeIcon />
                  </Box> : <Box onClick={() => setToggleImageText(!toggleImageText)} component="span">
                    <GridViewIcon />
                  </Box>
                }
              </Grid>
              <Divider variant="fullWidth" orientation="horizontal" />
              {
                (packagesLoading && packagesCardCount === 10) ? <LoadingCardSkeleton /> :
                  <InfiniteScroll
                    dataLength={packagesData?.length || 0}
                    next={fetchMoreData}
                    hasMore={totalResult !== packagesData?.length}
                    loader={<LoadingCardSkeleton />}
                    scrollableTarget="scrollableDiv"
                  >
                    <PackagesCards
                      packagesData={packagesData}
                      toggleImageText={toggleImageText}
                      handleOnClickCard={handleOnClickCard}
                      type={'packages'}
                      galleryModalHeadingText={"PACKAGES"}
                    />
                  </InfiniteScroll>
              }
            </>
        }
      </Container>

      {openPopUp && <FilterModal
        onClickModal={handleCloseOpenModal}
        type={'country'}
        openDialog={openPopUp}
        topHeading={'FILTERS'}
        filterFields={allPackagesFilterData.applyFilterData}
        handleClickOnFilter={handleClickOnFilter}
        handleApplyFilter={handleApplyFilter}
        handleOnclickKeywordList={handleOnclickKeywordList}
        keywordInputValue={allPackagesFilterData?.apiPayload?.keyword}
      />}
    </>
  );
};

export default Index
