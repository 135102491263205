import { Set_App_Is_Loading } from './actionType'


export const IsAppLoadingReducer = (state = { isAppLoading: false }, action) => {
    switch (action.type) {
        case Set_App_Is_Loading:
            return {
                ...state,
                isAppLoading: action.data,
            };
        default:
            return state;
    }
};