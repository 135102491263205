import {
  Set_Brand_Packages_Is_Loading,
  Set_Brand_Packages,
  Set_Brand_Services_Is_Loading,
  Set_Brand_Services
} from "./actionType";


export const BrandPackages = (state = { brandPackages: {}, loading: false }, action) => {
  switch (action.type) {
    case Set_Brand_Packages:
      return {
        ...state,
        brandPackages: action.data,
      };
    case Set_Brand_Packages_Is_Loading:
      return {
        ...state,
        loading: action.data,
      };
    default:
      return state;
  }
};

export const BrandService = (state = { servicesPackages: {}, loading: false }, action) => {
  switch (action.type) {
    case Set_Brand_Services:
      return {
        ...state,
        servicesPackages: action.data,
      };
    case Set_Brand_Services_Is_Loading:
      return {
        ...state,
        loading: action.data,
      };
    default:
      return state;
  }
};
