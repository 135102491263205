import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
    IconButton,
} from '@mui/material';
const Index = ({ onScroll }) => {
    return (
        <div
        className="d-flex align-items-center mt-5"
        style={{ cursor: "pointer" }}
      >
        <IconButton disableRipple sx={{ paddingLeft: '5px', paddingTop: '12px' }}  fontSize={20} onClick={() => onScroll(50)}>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    );
};

export default Index
