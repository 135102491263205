import React, { useState } from "react";
import { Box , CircularProgress, Typography } from "@mui/material";
import noData from '../../assets/images/no-data.png'

const Index = () => {
    return (
        
        <div>
            <Typography sx={{
               
                
               justifyContent: 'center',
               
               alignItems: 'center',
               zIndex: 50
           }} textAlign='center' pt='1rem'>Loading...</Typography>
            <Box sx={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                alignItems: 'center',
                zIndex: 50
            }}>
                
            </Box>
          
        </div>

    )
}

export default Index