export const isEmptyObject = (obj) => {
  return obj === null || obj === undefined || Object.keys(obj).length === 0;
};

export const isValidPrice = (price)  =>{
  return price !== undefined && price !== null && price !== 0;
}

export const isArrayEmpty = (array) => {
  return Array.isArray(array) && array.length === 0;
}

