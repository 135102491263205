import React, { useEffect, useState } from 'react';
import {
  InputBase,
  Popover,
  ListItem,
  ListItemText,
  Paper,
  Avatar,
  Typography,
  Box,
  Grid
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';
import { debounce } from 'lodash';
import { VariableSizeList as VList } from 'react-window';
import { keywordStyle } from './style'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { MAIN_MATCH_CONSTANTS } from '../../constants/MainMatchConstants'
import { useSelector } from "react-redux"

const SearchContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Input = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 1,
}));


const StyledPaper = styled(Paper)({
  padding: '8px',
  overflow: 'hidden'
});

const Index = ({ data, handleOnclickKeywordList = () => { }, openType, keywordInputValue }) => {
  const [searchInput, setSearchInput] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredData, setFilteredData] = useState(data);
  const [packageFilterInput, setPackageFilterInput] = useState("")
  const { TYPES } = MAIN_MATCH_CONSTANTS
  const allFilterApplyData = useSelector((state) => state.FilterEventPackagesStore.applyFilterData)
  const handleSearchInputChange = debounce((value) => {
    const filtered = (data instanceof Array && data.length > 0) ?
      data.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      ) : []
    setFilteredData(filtered);
  }, 300);

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSearchInput("")
    setPackageFilterInput("")
  };

  const openPopover = Boolean(anchorEl);

  const handleOnClickSearch = (value) => {
    handleClosePopover()
    handleOnclickKeywordList(value)
    setPackageFilterInput(value)
  }

  const Row = ({ index, style }) => (
    <>
      <ListItem button style={style} key={filteredData[index]}
        onClick={() => handleOnClickSearch(filteredData[index])}
      >
        <ListItemText primary={highlightMatch(filteredData[index])} />
      </ListItem>
    </>
  );

  const renderKeywordData = () => {
    return <>
      {
        searchInput &&
        <ListItem button>
          <ListItemText
            primary={
              <>

                <Typography sx={{ display: 'flex', justifyContent: 'start' }} component="span" >
                  <Typography  >
                    Search :
                  </Typography>
                  <Typography pl='5px' fontWeight="bold" color="black" >
                    {`${searchInput}`}
                  </Typography>
                </Typography>
              </>
            }
          />
        </ListItem>
      }
      {
        searchInput && filteredData?.length > 0 &&
        <VList
          height={
            openType === TYPES.PACKAGES ? 350 : 200
          }
          itemCount={filteredData.length}
          itemSize={() => 56}
          width="100%"
        >
          {Row}
        </VList>
      }
      {
        searchInput && filteredData?.length === 0 &&
        <>
          <ListItem
            button
            onClick={() => handleOnClickSearch(searchInput)}
          >
            <ListItemText primary={searchInput} />
          </ListItem>
        </>
      }
    </>
  }

  useEffect(() => {
    setPackageFilterInput(keywordInputValue ? keywordInputValue : "")
  }, [])

  return (
    <SearchContainer>
      {
        openType === TYPES.PACKAGES &&
        <>
          <Avatar onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ marginLeft: 'auto', ...{ backgroundColor: '#F2F2F2', maxWidth: '100%', cursor: 'pointer' } }}>
            <SearchIcon sx={{ color: 'black' }} />
          </Avatar>
          <Popover
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}

          >
            <StyledPaper>
              <Input
                placeholder="Search"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                  handleSearchInputChange(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    {searchInput && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSearchInput('');
                          handleSearchInputChange('');
                        }}
                      >
                        <CancelOutlinedIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
              {renderKeywordData()}
            </StyledPaper>
          </Popover>
        </>
      }
      {
        openType === TYPES.BRANDS &&
        <>

          <Grid sx={keywordStyle.inputsContainer}>
            <Grid
              item
              xs={12}
              sm={8}
              md={4}
              style={{ borderRadius: '25px', width: '100%' }}
            >
              <Typography variant="body1" sx={keywordStyle.labels}>{"brands"}</Typography>

              <Box
                component="div"
                sx={keywordStyle.innerBox.container}
              >
                <Input
                  sx={{
                    position: 'relative',
                    '& input': {
                      textAlign: 'center'
                    }
                  }}
                  value={packageFilterInput}
                  placeholder='Search by keyword'
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                    setPackageFilterInput(e.target.value)
                    handleSearchInputChange(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {searchInput && (
                        <IconButton
                          size="small"
                          onClick={() => {
                            setSearchInput('');
                            handleSearchInputChange('');
                          }}
                        >
                          <CancelOutlinedIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                />
                <Box sx={keywordStyle.brands.innerBox}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {renderKeywordData()}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      }

    </SearchContainer>
  );

  function highlightMatch(item) {
    const index = item.toLowerCase().indexOf(searchInput.toLowerCase());
    if (index !== -1) {
      const before = item.substring(0, index);
      const match = item.substring(index, index + searchInput.length);
      const after = item.substring(index + searchInput.length);
      return (
        <>
          {before}
          <strong>{match}</strong>
          {after}
        </>
      );
    }
    return item;
  }
};

export default Index;















