import React, { useState } from "react";
import {
    Container,
    Grid,
    Typography,
    Card,
    TextField,
    Box,
    Button
} from "@mui/material";
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import component from '../../../../components'
import { budgetPrice } from '../../../../constants/DataRowConstants'
import { EventLocationStyles } from '../EventLocation/style'
import {
    setEventBudgetInputData, setEventPackagesIsDelete,
    setEventPackagesFilter, setEventPackagesFilterApplyFilterData
} from "../../../../redux/PackagesServices/action"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { routePaths } from '../../../../routes/routePaths'
import { formatCurrency } from '../../..//../utils/formatCurrency'
import { MAIN_MATCH_CONSTANTS } from '../../../../constants/MainMatchConstants'
import { staticConstants } from '../../../../constants/StaticConstants'
const { TopBar, ProgressBar } = component;

const Index = () => {
    const theme = useTheme();
    const location = useLocation()
    const allEventData = useSelector((state) => state.AllPackagesServiceStore);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [showButton, setShowButton] = useState(false)
    const dispatch = useDispatch();
    const [budgetValue, setBudgetValue] = useState("")
    const [error, setError] = useState(false);
    const navigate = useNavigate()
    const allPackagesFilterData = useSelector((state) => state.FilterEventPackagesStore)
    const { FILTER_LABELS, NOT_SURE, OTHER } = MAIN_MATCH_CONSTANTS
    const { defaultSettings } = staticConstants

    const handleToggleRedirect = (item) => {
        if (item.value === 'Other') {
            setShowButton(true)
            dispatch(setEventBudgetInputData({ type: item.value, ...allEventData.budget, id: item.id }))
        } else {
            setShowButton(false)
            navigate(routePaths.packages)
            dispatch(setEventPackagesIsDelete(false))
            dispatch(setEventBudgetInputData(item))
            editFilterData(
                allEventData.isFilter ? allPackagesFilterData.applyFilterData : allPackagesFilterData.filterData,
                FILTER_LABELS.BUDGET, item
            )

        }
    }

    const handleOnChange = (e) => {
        dispatch(setEventBudgetInputData({ ...allEventData.budget, value: parseInt(e.target.value) }))
        setBudgetValue(e.target.value)
        if (!e.target.value) {
            setError(true)
        } else {
            setError(false)
        }
    }

    const handleRedirect = () => {
        if (budgetValue.length === 0 && !allEventData?.budget?.otherValue) {
            setError(true)
        } else {
            dispatch(setEventPackagesIsDelete(false))
            navigate(routePaths.packages)
            setError(false)
            setShowButton(true)
            editFilterData(
                allEventData.isFilter ? allPackagesFilterData.applyFilterData : allPackagesFilterData.filterData,
                FILTER_LABELS.SERVICE_EVENT_BUDGET, allEventData?.budget
            )
        }
    }

    const editFilterData = (data, type, updateData) => {
        const editedFilterData = data.map((items) => {
            if (items.label === type) {
                return {
                    ...items,
                    id: (NOT_SURE !== updateData.value && OTHER !== updateData.value) ? updateData.id : 106,
                    value: (NOT_SURE !== updateData.value && OTHER !== updateData.value) ? updateData.value : NOT_SURE,
                    maxPrice: (NOT_SURE !== updateData.value && OTHER !== updateData.value) ? updateData.value : defaultSettings.budgetStatic.defaultMax,
                    delete: false,
                }
            }
            else {
                return items
            }
        })
        if (allEventData.isFilter) {
            dispatch(setEventPackagesFilterApplyFilterData(editedFilterData))
        } else {
            dispatch(setEventPackagesFilter(editedFilterData))
        }
    }

  
    return (
        <Container maxWidth="md" sx={{ paddingBottom: '4rem', maxWidth: "720px", border: '1px solid #F2F2F2', paddingTop: '36px', marginBottom: '50px' }}>
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                {/* Event location box */}
                <TopBar
                    heading={"BUDGET"}
                />
                {/* Progress Bar */}
                {
                    !(routePaths.packages === location?.state?.type) &&
                    <ProgressBar style={{ marginBottom: !isSmallScreen ? '1rem' : '1rem' }} value={100} />
                }
                {/* Budget Number table */}
                {budgetPrice.map((row, rowIndex) => (
                    <Grid key={rowIndex}
                        sx={{ paddingTop: isSmallScreen ? '0.6rem !important' : '1rem !important' }}
                        xs={12} spacing={isSmallScreen ? 1.2 : 2.3}
                        container item
                    >
                        {row.map((item, colIndex) => (
                            <Grid
                                key={item.id} item
                                xs={isSmallScreen ? 6 : 3}
                                sx={{ margin: 'auto', paddingTop: '0px' }}
                            >
                                <Card
                                    onClick={() => handleToggleRedirect(item)}
                                    sx={{
                                        height: 90,
                                        textAlign: 'center',
                                        width: '100%',
                                        borderRadius: '10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: allEventData?.budget?.id === item.id ? '#009485' : '#5c5c5c',
                                        },
                                        backgroundColor: allEventData?.budget?.id === item.id ? '#009485' : '#363636'
                                    }}
                                >
                                    <Typography variant={'h4'} sx={{
                                        fontFamily: 'Open Sans, sans-serif',
                                        color: 'white',
                                        paddingTop: '2rem',
                                        fontSize: '2rem',
                                        lineHeight: '30px',
                                        fontWeight: 'normal',
                                        letterSpacing: '0px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                    }}>
                                        {!MAIN_MATCH_CONSTANTS.PRICE_MATCH.includes(item.value) ? `£${formatCurrency(item.value)}` : item.value}
                                    </Typography>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                ))}
                {
                    (showButton || allEventData?.budget?.type) &&
                    <><Grid item xs={12}>
                        <Box sx={{
                            display: 'flex', justifyContent: 'center', marginTop: '4px', borderRadius: '20px', textDecoration: 'none',
                            minWidth: '337px',
                            height: '47px',
                        }}>
                            <TextField
                                error={error}
                                helperText={error ? 'Please enter the budget value' : ''}
                                type="number"
                                className="form-control-search"
                                placeholder="Enter Budget"
                                aria-label="Search"
                                variant="outlined"
                                value={allEventData?.budget?.otherValue}
                                onChange={(e) => { handleOnChange(e) }}
                                sx={{
                                    minWidth: '337px',
                                    height: '47px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                        '&:hover fieldset': {
                                            border: 'none',
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: 'none',
                                        },
                                    },
                                    '& input': {
                                        background: '#f2f2f2',
                                        padding: '12px',
                                        borderRadius: '21px',
                                        paddingLeft: '45px',
                                        border: '1px solid #f4f4f4',
                                        transition: 'border-color 0.2s ease-in-out',
                                        '&:focus': {
                                            borderColor: 'transparent',
                                        },
                                        '&:hover': {
                                            borderColor: 'transparent',
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                        <Grid item xs={12}>
                            <Box sx={EventLocationStyles.buttonContent.container}>
                                <Button variant="contained" onClick={() => { handleRedirect() }} sx={EventLocationStyles.buttonContent.button}>
                                    CONTINUE
                                </Button>
                            </Box>
                        </Grid>
                    </>
                }
            </Grid>
        </Container>
    );
};

export default Index;
