export const LoginStyles = {
  container: {
    background: '#FFFFFF',
    backgroundPosition: '0% 0%',
    backgroundRepeat: 'no-repeat',
    border: '1px solid #F5F5F5',
    opacity: 1,
    '@media (max-width: 400px)': {
      minWidth: 'auto',
    },
    '@media (min-width: 700px)': {
      width: '735px',
    },
  },
  labels: {
    color: '#949494',
    minWidth: '67px',
    height: '11px',
    textAlign: 'left',
    fontFamily: 'Open Sans,sans-serif',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '15px',
    letterSpacing: '0px',
    textTransform: 'uppercase',
    opacity: 1,
    paddingBottom: '22px'
  },
  inputsContainer: { marginTop: '2rem', display: 'flex', justifyContent: 'center', minHeight: '47px' },
  bottomText: {
    minWidth: '203px',
    minHeight: '18px',
    textAlign: 'left',
    fontFamily: 'Open Sans , sans-serif',
    fontWeight: 'normal',
    fontSize: '21px',
    lineHeight: '23px',
    letterSpacing: '0px',
    color: '#0F0F0F',
    textTransform: 'uppercase',
    opacity: 1,
    textAlign: 'center'
  },
  loginContainer: {
    container: { display: 'flex', alignItems: 'center' },
    avatar: { backgroundColor: '#F2F2F2', maxWidth: '100%' },
    labelText: {
      minWidth: '99px',
      height: '27px',
      textAlign: 'left',
      fontFamily: 'Open Sans, sans-serif',
      fontWeight: 400,
      fontSize: '30px',
      lineHeight: '35px',
      letterSpacing: '0px',
      color: '#0F0F0F',
      opacity: 1
    }
  },
  country: {
    container: {
      fontFamily: 'Open Sans, sans-serif',
      fontWeight: 400,
      padding: "5px 14px",
      backgroundColor: "#F2F2F2",
      fontSize: "1rem",
      lineHeight: "1.4375em",
      letterSpacing: "0.00938em",
      color: "rgba(0, 0, 0, 0.87)",
      boxSizing: "border-box",
      position: "relative",
      cursor: "pointer",
      display: "inline-flex",
      alignItems: "center",
      width: "100%",
      borderRadius: "25px",
      paddingLeft: "23px",
      paddingRight: "14px",
      // height: '3.4375em',
      justifyContent: "space-between",
      "&:hover": {
        backgroundColor: "#EFEFEF",
      },
    },
    text: {
      width: '154px',
      height: '19px',
      textAlign: 'left',
      fontFamily: 'Open Sans, sans-serif',
      fontWeight: 'normal',
      fontSize: '19px',
      lineHeight: '22px',
      letterSpacing: '0px',
      color: '#0F0F0F',
      opacity: 1
    }
  },
  search: {
    '& .MuiOutlinedInput-root': {
      backgrouverificationndColor: '#F2F2F2',
      borderRadius: '10px',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
  },
  wembleyBox: {
    container: {
      height: '80px',
      background: '#F2F2F2',
      borderRadius: '11px',
      opacity: '1',
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap'
    },
    text: {
      paddingTop: '26px',
      fontFamily: 'Open Sans, sans-serif',
      lineHeight: '23px',
      letterSpacing: '0px',
      color: '#161616',
      opacity: '1',
    }
  },

  buttonContent: {
    container: { display: 'flex', justifyContent: 'center' },
    button: {
      // borderRadius: '20px',
      // display: 'inline-block',
      // textDecoration: 'none',
      // minWidth: '337px',
      // height: '47px',
      fontFamily: 'Open Sans, sans-serif',
      minWidth: '312px',
      minHeight: '49px',
      borderRadius: '25px',
      opacity: 1,
      backgroundColor: '#EEB000',
      '@media (max-width: 300px)': {
        minWidth: '80%',
      },
      '&:hover': {
        backgroundColor: '#efb719',
      },
    },
  },

}