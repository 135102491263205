export const EventServicesIncludesStyles = {
    listContainer: {
        topHeading: {
            backgroundColor: 'aqua',
            height: '2.5rem',
            borderRadius: '21px',
            display: 'flex',
            justifyContent: 'flex-start',
            margin: '0rem 1rem'
        },
        headingText: {
            fontFamily: 'Open Sans, sans-serif',
            paddingLeft: '1.5rem',
            paddingTop: '0.5rem',
            textTransform: 'uppercase',
        }
    },
    buttonContent: {
        container: { display: 'flex', justifyContent: 'center', },
        button: {
            borderRadius: '20px', display: 'inline-block', textDecoration: 'none',
            minWidth: '312px',
            height: '47px',
            backgroundColor: '#EEB000',
            fontFamily: 'Open Sans, sans-serif',
            color: '#FFFFFF',
            '&:hover': {
                backgroundColor: '#efb719',
            },
        }
    }
}