export const modalStyles = {
  modalContainer: {
    container: { display: 'flex', alignItems: 'center' },
    avatar: { backgroundColor: '#F2F2F2', maxWidth: '100%', cursor: 'pointer' },
    labelText: { fontSize: '20px', textAlign: 'center', width: '100%', fontFamily: 'Open Sans, sans-serif' }
  },
  listContainer: {
    container: { display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '7px' },
    listItems: {
      // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      padding: "6.5px 14px",
      fontSize: "1rem",
      lineHeight: "1.4375em",
      letterSpacing: "0.00938em",
      color: "rgba(0, 0, 0, 0.87)",
      boxSizing: "border-box",
      position: "relative",
      cursor: "pointer",
      alignItems: "center",
      width: "100%",
      borderRadius: "10px",
      paddingLeft: "43px",
      paddingRight: "14px",
      justifyContent: "space-between",
      backgroundColor: "#EFEFEF"
    }

  },
  inputsContainer: { marginTop: '1rem', display: 'flex', justifyContent: 'center', minHeight: '47px' },
  labels: {
    // color: '#949494',
    minWidth: '67px',
    height: '11px',
    textAlign: 'left',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '15px',
    letterSpacing: '0px',
    textTransform: 'uppercase',
    opacity: 1,
    paddingBottom: '22px'
  },
  inputsPropsStyles: {
    borderRadius: '25px',
    backgroundColor: '#f2f2f2',
    paddingLeft: '16px',
    paddingRight: '10px',
    minWidth: '154px',
    height: '49px',
    textAlign: 'left',
    fontWeight: 'normal',
    fontSize: '19px',
    // lineHeight: '22px',
    fontFamily: 'Open Sans, sans-serif',
    letterSpacing: '0px',
    color: '#0F0F0F',
    opacity: 1
  },
  innerBox: {
    container: {
      // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      padding: "5px 14px",
      backgroundColor: "#F2F2F2",
      fontSize: "1rem",
      lineHeight: "1.4375em",
      letterSpacing: "0.00938em",
      color: "rgba(0, 0, 0, 0.87)",
      boxSizing: "border-box",
      position: "relative",
      cursor: "pointer",
      display: "inline-flex",
      alignItems: "center",
      width: "100%",
      borderRadius: "25px",
      paddingLeft: "23px",
      paddingRight: "14px",
      // height: '3.4375em',
      justifyContent: "center",
      "&:hover": {
        backgroundColor: "#EFEFEF",
      },
      height: '49px'
    },
    text: {
      width: '400px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      height: 'auto',
      textAlign: 'center',
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '0px',
      color: '#0F0F0F',
      opacity: 1,
      fontFamily: 'Open Sans, sans-serif',
      fontWeight: 300,
    }
  },
  buttonContent: {
    gridContainer:{marginTop:'1.4rem' , width:'100%'},
    container: { display: 'flex', justifyContent: 'center' , marginTop:'0.5rem'},
    button: {
      borderRadius: '20px', display: 'inline-block', textDecoration: 'none',
      minWidth: '312px',
      height: '47px',
      backgroundColor: '#EEB000',
      fontFamily: 'Open Sans, sans-serif',
      width:'100%',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#efb719',
      },
      '@media (max-width: 400px)': {
        minWidth: 'auto',
      },
    },
    linkButton:{
      fontWeight:700,
      fontFamily: 'Open Sans, sans-serif',
      marginTop:'0.1rem'
    }
  }
}