import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Box,
  Card,
  Divider,
  IconButton,
  Video
} from '@mui/material';

import Rating from '@mui/material/Rating';
import SSimage from '../../assets/images/Image 37.png'
import SImage from '../../assets/images/Image-38.png'
import { useLocation, useNavigate } from 'react-router';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined';
import GalleryModal from '../../components/GalleryModal'
import { MAIN_MATCH_CONSTANTS } from '../../constants/MainMatchConstants'
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../utils/formatCurrency'
import { staticConstants } from '../../constants/StaticConstants'
import { isEmptyObject, isValidPrice, isArrayEmpty } from '../../utils/emptyHelpers'
import ShareButton from "../../components/ShareButton"
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import NoDataFound from '../../components/NoDataFound';
import LoadingData from '../../components/LoadingData';

const Index = ({ packagesData, isLoading, isNaved, toggleImageText, handleOnClickCard = () => { }, type = 'details', galleryModalHeadingText }) => {
  const [openGalleryModal, setOpenGalleryModal] = React.useState(false);
  const eventInputPackages = useSelector(state => state.EventPackagesSearch.eventInputPackages)
  const { PACKAGES_CARD } = MAIN_MATCH_CONSTANTS
  const navigate = useNavigate()
  
  const handleOpenClose = () => {
    if (!isEmptyObject(getGalleryData()) && !isArrayEmpty(getGalleryData()?.media || [])) {
      setOpenGalleryModal(!openGalleryModal);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1)
  }

  const handleClick = (e, items) => {
    handleOnClickCard(items)
  }

  function amendCloudinaryUrl(url, width) {
    const cloudinaryBase = "/upload/";
    const transformation = `w_${width}`; // Correct string interpolation for width
  
    // Check if the URL contains the "/upload/" section to insert the transformation
    if (url.includes(cloudinaryBase)) {
      // Insert the transformation right after "/upload/"
      return url.replace(cloudinaryBase, `${cloudinaryBase}${transformation}/`);
    } else {
      // Return original URL if the structure doesn't match Cloudinary format
      return url;
    }
  }

  const getGalleryData = () => {
    if (
      (type === PACKAGES_CARD.INCLUDES || PACKAGES_CARD.SERVICE_CARD) &&
      (!isEmptyObject(packagesData[0]) && packagesData.length > 0)
    ) {
      return packagesData[0]
    }
    else {
      return eventInputPackages
    }
  }



  return (
    <>
      {
        (packagesData instanceof Array && packagesData?.length > 0)
          && (Object.keys(packagesData[0])?.length !== 0) 
          ?
          packagesData.map((items, index, array) => {
            const mediaUrl = items.media && items.media.length > 0 ? items.media[0]?.mediaUrl : null;
            const mediaType = items.media ? items.media[0]?.mediaType : ""
            const shareData = { text: items?.description, url: window?.location?.href, title: items?.companyName }
            const dynamicWebTitle = type === PACKAGES_CARD.DETAILS ? `Events6 - Event Package - ${items?.companyName}` :
              type === PACKAGES_CARD.SERVICE_CARD ? `Events6 - Event Services - ${items?.companyName}` : false
            return <>
              <Box  >
                {
                  dynamicWebTitle &&
                  <Helmet>
                    <title>{dynamicWebTitle}</title>
                    <meta name="title" content={dynamicWebTitle} />
                    <meta
                      property="image"
                      content={mediaUrl}
                    />
                  </Helmet>
                }
                {!toggleImageText && (type === PACKAGES_CARD.PACKAGES || type === PACKAGES_CARD.DYNAMIC_EVENT_SERVICE_SCREEN) && <>
                  <Grid sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem', marginBottom: '0rem', justifyContent: 'space-between' }} >
                    <Box sx={{ display: 'flex' }} >
                      <Box component="img" alt="D" src={items.logoUrl}
                        sx={{
                          width: '47px',
                          height: '47px',
                          borderRadius: '40px', marginTop: '0rem', marginBottom: '0rem'
                        }} />
                      <Box>
                        <Typography variant="h5" style={{ marginLeft: '1rem', fontFamily: 'Open Sans, sans-serif', }}>
                          {items.companyName}
                        </Typography>
                        <Typography sx={{ marginLeft: '1rem', marginTop: '0rem', fontFamily: 'Open Sans, sans-serif', }}>
                          {items.locationName}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ cursor: 'pointer' }} >
                      <ShareButton
                        shareData={shareData}
                      />
                    </Box>
                  </Grid>
                </>}

                {
                  type === PACKAGES_CARD.INCLUDES && <>
                    <Grid >
                      <Typography variant='h4' textAlign='center' color='#EEB000' sx={{ fontFamily: 'Open Sans, sans-serif', }} >{items.serviceName}</Typography>
                      <Typography sx={{
                        color: '#949494',
                        minWidth: '67px',
                        height: '11px',
                        textAlign: 'left',
                        fontWeight: 500,
                        fontSize: '13px',
                        lineHeight: '15px',
                        letterSpacing: '0px',
                        textTransform: 'uppercase',
                        opacity: 1,
                        paddingBottom: '22px',
                        fontFamily: 'Open Sans, sans-serif',
                      }} >PACKAGE</Typography>
                      <Typography sx={{ fontFamily: 'Open Sans, sans-serif', }} >{items.companyName}</Typography>

                    </Grid>
                  </>
                }
                <Grid>
                  <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem', position: 'relative' }}>

                    {
                      mediaType === PACKAGES_CARD.MEDIA_TYPE.VIDEO ?
                        <video
                          src={mediaUrl}
                          onClick={(e) => { handleClick(e, items) }}
                          style={{
                            width: '100%',
                            height: '500px',
                            marginTop: '0rem',
                            marginBottom: '0rem',
                            cursor: 'pointer'
                          }}
                          autoPlay
                          controls
                        />
                        :
                        <Box 
                          onClick={(e) => handleClick(e, items)}
                          component="img" 
                          src={mediaUrl ? amendCloudinaryUrl(mediaUrl, 1000) : staticConstants.placeholderImageUrl} // Update URL with 1000px width
                          onError={(e) => { e.target.src = staticConstants.placeholderImageUrl }}
                          style={{
                            width: '100%', 
                            height: '100%',
                            marginTop: '0rem',
                            marginBottom: '0rem', 
                            cursor: 'pointer'
                          }} 
                        />

                    }
                    {!toggleImageText && (type === PACKAGES_CARD.PACKAGES || type === PACKAGES_CARD.DETAILS) && <>
                      <Box onClick={(e) => { handleClick(e, items) }} sx={{
                        position: 'absolute',
                        right: 0,
                        top: 'auto',
                        bottom: type === PACKAGES_CARD.DETAILS ? '50%' : '14%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        width: '112px',
                        height: '27px',
                        background: '#E80000',
                        borderRadius: '14px 0px 0px 14px',
                        opacity: 1
                      }}>
                        <Typography sx={{
                          textAlign: 'center',
                          fontFamily: 'Open Sans, sans-serif',
                          letterSpacing: '0px',
                          color: '#FFFFFF',
                          opacity: 1
                        }}>
                          Save &#163;{items.discount}
                        </Typography>
                      </Box>
                    </>}
                    {
                      (
                        type === PACKAGES_CARD.DETAILS ||
                        type === PACKAGES_CARD.INCLUDES ||
                        type === PACKAGES_CARD.SERVICE_CARD
                      ) &&
                      <>
                      {isNaved ?
                      <></>
                       : 
                        
                      (
                        <IconButton
                      onClick={(e) => { handleBack(e) }} 
                      sx={{
                        position: 'absolute',
                        top: '10px',
                        left: '10px',
                        borderRadius: '50%',
                        width: '40px',
                        height: '40px',
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                          backgroundColor: '#E5E5E5'
                        }
                      }}
                    >

                      <KeyboardArrowLeftIcon />
                    </IconButton>
                      )                  
                      }
                        
                        {
                          (
                            type === PACKAGES_CARD.DETAILS ||
                            type === PACKAGES_CARD.SERVICE_CARD
                          )
                          &&
                          <>

                            <IconButton
                              sx={{
                                position: 'absolute', top: '10px', right: '10px', borderRadius: '50%',
                                width: '40px',
                                height: '40px',
                                backgroundColor: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                '&:hover': {
                                  backgroundColor: '#E5E5E5'
                                }
                              }}
                            >
                              <ShareButton
                                shareData={shareData}
                              />
                            </IconButton>
                          </>
                        }
                        {/* Gallery box */}
                        <Box
                          sx={{
                            position: 'absolute',
                            bottom: '10px',
                            right: '22px',
                            width: '135px',
                            height: '36px',
                            background: 'grey',
                            borderRadius: '25px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#000000',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            cursor: 'pointer'
                          }}
                          onClick={handleOpenClose}
                        >
                          <Box sx={{ display: 'flex', justifyContent: 'space-around' }} >
                            <GridOnOutlinedIcon style={{ color: 'white' }} />
                            <Typography color='white' sx={{ paddingLeft: '6px', fontFamily: 'Open Sans, sans-serif', }} >Gallery</Typography>
                          </Box>
                        </Box>
                      </>
                    }
                  </Card>
                </Grid>
                {!toggleImageText && <React.Fragment >
                  <Box onClick={(e) => { handleClick(e, items) }} >
                    {
                      type !== PACKAGES_CARD.DYNAMIC_EVENT_SERVICE_SCREEN &&
                      <Grid sx={{ marginTop: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant='h5' sx={{
                          font: 'normal normal normal 22px/24px Open Sans',
                          color: '#0F0F0F',
                          fontFamily: 'Open Sans, sans-serif',
                        }}>
                          {
                            (
                              type === PACKAGES_CARD.INCLUDES
                              || type === PACKAGES_CARD.DYNAMIC_EVENT_SERVICE_SCREEN
                              || type === PACKAGES_CARD.SERVICE_CARD
                            ) ? items.serviceName : items.packageName
                          }
                        </Typography>
                      </Grid>
                    }
                    <Grid sx={{
                      display: 'flex', alignItems: 'center',
                      justifyContent: 'center', marginTop: type !== PACKAGES_CARD.DYNAMIC_EVENT_SERVICE_SCREEN ? '5px' : '1rem'
                    }}>
                      <Rating name="read-only" readOnly value={items.rating} />
                    </Grid>
                    {
                      (
                        type !== PACKAGES_CARD.INCLUDES &&
                        type !== PACKAGES_CARD.DYNAMIC_EVENT_SERVICE_SCREEN &&
                        type !== PACKAGES_CARD.SERVICE_CARD
                      ) && <>
                        <Grid item sx={{ display: 'flex', justifyContent: 'space-evenly' }} >
                          <Box sx={{ display: 'flex', width: '153px' }}>
                            <Box component="img" src={SSimage} style={{ color: '##EEB000', height: 'auto', marginTop: '0rem', marginBottom: '0rem' }} />
                            <Typography sx={{ fontFamily: 'Open Sans, sans-serif', paddingLeft: '9px', paddingTop: '8px', font: 'normal normal normal 15px/17px Open Sans', color: '#080808' }}>
                              {`${items.noOfGuests} Guests`}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', width: '153px' }}>
                            <Box component="img" src={SImage} style={{ color: '##EEB000', height: 'auto', marginTop: '0rem', marginBottom: '0rem' }} />
                            <Typography sx={{ fontFamily: 'Open Sans, sans-serif', paddingLeft: '9px', paddingTop: '8px', font: 'normal normal normal 15px/17px Open Sans', color: '#080808' }}>
                              {`${items.noOfServices} Services`}
                            </Typography>
                          </Box>
                        </Grid>
                      </>
                    }
                    <Grid sx={{
                      display: 'flex',
                      justifyContent: 'center', marginTop: '24px',
                      marginBottom: `${type === PACKAGES_CARD.INCLUDES ? '1rem' : '0'}  `
                    }}>
                      <Typography sx={{
                        font: 'normal normal normal 16px/18px Open Sans',
                        color: '#000000'
                      }}
                      >
                        REF #{(
                          type === PACKAGES_CARD.INCLUDES ||
                          type === PACKAGES_CARD.SERVICE_CARD ||
                          type === PACKAGES_CARD.DYNAMIC_EVENT_SERVICE_SCREEN
                        ) ? items.serviceId : items.packageId}
                      </Typography>
                    </Grid>
                    {
                      type !== PACKAGES_CARD.INCLUDES && <>
                        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem', gap: '1rem', marginBottom: '3rem' }}>
                          {
                            (
                              isValidPrice(items.discountValue) ||
                              isValidPrice(items.totalPrice) ||
                              isValidPrice(items.discount)
                            ) &&
                            <Typography sx={{
                              fontFamily: 'Open Sans, sans-serif',
                              color: '#A50707',
                              textDecoration: 'line-through',
                              fontWeight: 600,
                              letterSpacing: '0px',
                              height: '35px',
                              fontSize: '1.5rem',
                            }} variant="body1" >
                              {
                                (
                                  type === PACKAGES_CARD.SERVICE_CARD ||
                                  type === PACKAGES_CARD.DYNAMIC_EVENT_SERVICE_SCREEN
                                ) ?
                                  <> &#163;{items.discountValue && formatCurrency(items.discountValue)}
                                  </> : <> &#163;{formatCurrency(items.totalPrice)}</>
                              }
                            </Typography>
                          }
                          <Typography sx={{
                            width: '100px',
                            textAlign: 'center',
                            letterSpacing: '0px',
                            color: '#191919',
                            opacity: 1,
                            fontSize: '2rem',
                            fontFamily: 'Open Sans, sans-serif',
                          }} variant="h5">
                            {
                              items.finalPrice !== 0 ? <> &#163;{formatCurrency(items.finalPrice)}</>
                                :
                                <Typography sx={{
                                  fontFamily: 'Open Sans, sans-serif',
                                  color: '#A50707',
                                  fontWeight: 600,
                                  letterSpacing: '0px',
                                  fontSize: '1.5rem',
                                }} variant="body1" >
                                  <Link to={`tel:${packagesData && packagesData[0]?.bizContactNo}`} style={{ textDecoration: 'none', color: 'inherit' }}>Call</Link>
                                </Typography>
                            }
                          </Typography>
                        </Grid>
                      </>
                    }
                    {(
                      type === PACKAGES_CARD.INCLUDES
                    ) && <>
                        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '3rem' }}>
                          <Typography sx={{
                            fontFamily: 'Open Sans, sans-serif',
                            color: '#A50707',
                            fontWeight: 600,
                            letterSpacing: '0px',
                            fontSize: '1.5rem',
                          }} variant="body1" >
                            {/* &#163; */}
                            <Link to={`tel:${packagesData && packagesData[0]?.bizContactNo}`} style={{ textDecoration: 'none', color: 'inherit' }}>Call</Link>
                          </Typography>
                        </Grid>
                      </>
                    }
                  </Box>
                  {
                    type === PACKAGES_CARD.DETAILS && <>
                      <Helmet>
                        <meta name="description" content={items?.description} />
                      </Helmet>
                      <Divider />
                      <Box sx={{ padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography sx={{ fontFamily: 'Open Sans, sans-serif', color: '#707070', letterSpacing: '0px', textAlign: 'left', paddingBottom: '32px', paddingTop: '2rem' }} >
                          {items.description}
                        </Typography>
                        <Typography variant='h5' sx={{ fontFamily: 'Open Sans, sans-serif', textTransform: 'uppercase', textAlign: 'center', letterSpacing: '0px', fontWeight: 300, color: '#7B7B7B' }}>
                          Services Included
                        </Typography>
                      </Box>
                    </>
                  }
                  {
                    (type === PACKAGES_CARD.SERVICE_CARD) &&
                    <>
                      <Helmet>
                        <meta name="description" content={items?.description} />
                      </Helmet>
                      <Divider />
                      {
                        items.description &&
                        <Box sx={{ padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                          <Typography sx={{ fontFamily: 'Open Sans, sans-serif', color: '#707070', letterSpacing: '0px', textAlign: 'left', paddingBottom: '32px', paddingTop: '2rem' }} >
                            {items.description}
                          </Typography>
                        </Box>
                      }
                    </>
                  }
                </React.Fragment>}
              </Box >
              {(!toggleImageText && (type === PACKAGES_CARD.PACKAGES || type === PACKAGES_CARD.DYNAMIC_EVENT_SERVICE_SCREEN) && index !== array.length - 1) && (
                <Box sx={{ border: '4px solid #e2e2e2' }}>
                  {/* Divider div */}
                </Box>
              )}
            </>
          }
        ) 
          : 
          isLoading ? (
              <LoadingData />
          ):
          <NoDataFound />
      }
      {
        openGalleryModal && <GalleryModal
          handleOpenClose={handleOpenClose}
          openGalleryModal={openGalleryModal}
          galleryData={getGalleryData()}
          galleryModalHeadingText={galleryModalHeadingText}
        />
      }
    </>
  )
};

export default Index
