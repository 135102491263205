import React from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import HeaderLogo from '../../assets/images/eventHeaderLogo.png'
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

const Index = () => {
    return (
        <AppBar position="static" sx={{
            border: '1px solid #F2F2F2',
            boxShadow: 'none',
            backgroundColor: '#FFFFFF',
        }}>
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-around' }}>
                <Typography variant="h6"
                    component="div"
                >
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
                       <Box component='img' sx={{height:'49px' , width:'139px', marginTop:'7px'}} src={HeaderLogo}></Box>
                    </Link>
                </Typography>
            </Toolbar>
        </AppBar>
    )
}

export default Index