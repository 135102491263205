export const Login_Admin = "Login_Admin"
export const Set_Login_Value = "Set_Login_Value"
export const Get_Login_Response = "Get_Login_Response"
export const Logout_Admin = "Logout_Admin"
export const Get_Logout_Response = "Get_Logout_Response"
export const Change_Password = "Change_Password"
export const Get_Profile = "Get_Profile"
export const Get_Profile_Response = " Get_Profile_Response"
export const Get_logoType = " Get_logoType"
export const Reset_Password = "Reset_Password"
export const Login_User = "Login_User"
export const Set_Login_Input_Data = "Set_Login_Input_Data"
export const Set_Login_Response_Data = "Set_Login_Response_Data"
export const Send_Otp_Verification = "Send_Otp_Verification"
export const Re_Send_Otp_Verification = "Re_Send_Otp_Verification"
export const User_Registration = "User_Registration"
export const Set_Registration_RequestBody = "Set_Registration_RequestBody"



