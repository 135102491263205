import React from 'react'
import { TextField, InputAdornment } from '@mui/material';
import { searchStyle } from './style'
import SearchIcon from "@mui/icons-material/Search";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
const Index = ({ handleOnchangeSearch, placeholder }) => {

    const handleOnchange = (e) => {
        handleOnchangeSearch(e.target.value)
    }

    return (
        <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            onChange={(e) => { handleOnchange(e) }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                placeholder: placeholder,
                // endAdornment: (
                //     <InputAdornment position="end">
                //         <GpsFixedIcon />
                //     </InputAdornment>
                // ),
            }}
            sx={searchStyle.search}
        />
    )
}

export default Index