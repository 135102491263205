import React, { useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import { CardConstants } from '../../../constants/CardConstants'
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import components from '../../../components';
import {
    getEventSettings, getPackagesSearchKeywords,
    setEventTypeInputData, setEventCommunityInputData,
    setEventReligionInputData,
} from '../../../redux/PackagesServices/action'
import { setEventServiceEditStart } from '../../../redux/EventServices/action'
import { useDispatch } from 'react-redux';
import Header from '../../../components/Header'

const { EventHomeCard } = components

const Index = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch()
    const handleRedirect = (path) => {
        navigate(path)
    }

    useEffect(() => {
        dispatch(getEventSettings())
        dispatch(getPackagesSearchKeywords({ "keyword": "" }))
        dispatch(setEventServiceEditStart(false))

        //resetting event type screen data 
        dispatch(setEventCommunityInputData({}))
        dispatch(setEventTypeInputData({}));
        dispatch(setEventReligionInputData({}))
        //end
    }, [])

    return (
        <>
            {/* <Header /> */}
            <Container sx={{ fontFamily: 'Inter, sans-serif', marginTop: '5.5rem' }}>
                <Container mt={4}>
                    <Grid container sx={{ display: `${isSmallScreen ? 'block' : 'flex'}` }} spacing={4}>
                        {
                            CardConstants.map((card, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <EventHomeCard
                                            text={card.text}
                                            path={card.path}
                                            logo={card.logo}
                                            onClick={handleRedirect}
                                        />
                                    </React.Fragment>
                                )
                            })
                        }
                    </Grid>
                </Container>
            </Container>
        </>
    );
};

export default Index;












