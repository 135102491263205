export const eventServicesStyles = {
    eventServicesBox: {
        container: {
            // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            // margin:'0.6rem',
            fontWeight: 400,
            padding: "6.5px 14px",
            backgroundColor: "#F2F2F2",
            fontSize: "1rem",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
            color: "rgba(0, 0, 0, 0.87)",
            boxSizing: "border-box",
            position: "relative",
            cursor: "pointer",
            display: "inline-flex",
            alignItems: "center",
            width: "100%",
            borderRadius: "10px",
            paddingRight: "14px",
            height:'47px',
            justifyContent: "space-between",
            marginTop:'1rem',
            "&:hover": {
                backgroundColor: "#EFEFEF",
            },
        },
        text: {
            font: "inherit",
            letterSpacing: "inherit",
            color: "#4d4949",
            padding: "4px 0 5px",
            border: 0,
            boxSizing: "content-box",
            background: "none",
            height: "1.4375em",
            WebkitTapHighlightColor: "transparent",
            WebkitAnimationName: "mui-auto-fill-cancel",
            animationName: "mui-auto-fill-cancel",
            WebkitAnimationDuration: "10ms",
            animationDuration: "10ms",
            fontFamily: 'Open Sans, sans-serif'
        }
    },
    cards: {
        container: {
            maxWidth: 345,
            backgroundColor: "#fffff",
            boxShadow: "none",
            cursor: 'pointer',
            marginBottom:'1rem'
        },
        selectedCard: {
            border: '3px solid #EEB000',
            backgroundColor:'#F2F2F2',
            borderTopLeftRadius: '10px', // 0 border radius for top left corner
            borderTopRightRadius: '10px', // 0 border radius for top right corner
        },
        text: {
            width: "117px",
            height: "13px",
            textAlign: "center",
            fontFamily: "Open Sans, sans-serif",
            fontSize: "15px",
            lineHeight: "17px",
            letterSpacing: "0px",
            color: "#0F0F0F",
            opacity: 1,
            fontWeight: "600",
            paddingBottom: '20px',
            maxWidth: '400px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            cursor: "pointer"
        },
        cardMedia: {
            height: "114px",
            width: "149px",
            borderRadius: "10px",
            opacity: "1",
        }
    }
}