import {
    Login_Admin, Logout_Admin,
    Change_Password, Get_Profile, Get_logoType,
    Reset_Password, Login_User,
    Set_Login_Input_Data,
    Set_Login_Response_Data,
    Send_Otp_Verification,
    Re_Send_Otp_Verification,
    User_Registration,
    Set_Registration_RequestBody
} from './actionType'

export const loginUser = (data, navigate) => {
    return {
        type: Login_User,
        data,
        navigate
    }
}

export const userRegistration = (data, navigate) => {
    return {
        type: User_Registration,
        data,
        navigate
    }
}

export const sendOtpVerification = (data, navigate) => {
    return {
        type: Send_Otp_Verification,
        data,
        navigate
    }
}

export const reSendOtpVerification = (data) => {
    return {
        type: Re_Send_Otp_Verification,
        data,
    }
}

export const setLoginInputData = (data) => ({
    type: Set_Login_Input_Data,
    data,
})

export const setRegistrationRequestBody = (data) => ({
    type: Set_Registration_RequestBody,
    data,
})

export const setLoginResponseData = (data) => ({
    type: Set_Login_Response_Data,
    data,
})

export const DataDashboard = (data, navigate) => {

    return {
        type: Login_Admin,
        data,
        navigate
    }
}
export const LogoutAdmin = (token, navigate) => {

    return {
        type: Logout_Admin,
        token,
        navigate
    }
}
export const ChangePassword = (data, navigate) => {

    return {
        type: Change_Password,
        data,
        navigate
    }
}
export const ProfileDisplay = () => {
    return {
        type: Get_Profile,
    }
}
export const ResetPassword = (data, reset_token, navigate) => {
    return {
        type: Reset_Password,
        data,
        reset_token,
        navigate
    }
}
export const homeLogo = (logotype) => {

    return {
        type: Get_logoType,
        logotype
    }
}