export const searchStyle = {
  search: {
    fontFamily: 'Open Sans, sans-serif',
    '& .MuiOutlinedInput-root': {
      height: '47px ',
      backgroundColor: '#F2F2F2',
      borderRadius: '10px',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent', // Change to the color you want or 'transparent'
      },
    },
  },
}