import React, { useEffect, useState, useRef } from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { staticConstants } from '../../constants/StaticConstants'
import { MAIN_MATCH_CONSTANTS } from '../../constants/MainMatchConstants'
import { Box } from '@mui/material';
import 'photoswipe/dist/photoswipe.css'
import { isEmptyObject } from '../../utils/emptyHelpers'
const Index = ({ openGalleryModal, handleOpenClose = () => { }, galleryData, galleryModalHeadingText }) => {
  const [openCarousel, setOpenCarousel] = useState(false)
  const { PACKAGES_CARD } = MAIN_MATCH_CONSTANTS
  const buttonRef = useRef()
  const handleOpenCloseCarousel = () => {
    setOpenCarousel(!openCarousel)
  }


  useEffect(() => {
    setTimeout(() => {
      if (buttonRef) {
        buttonRef?.current?.click()
      }
    }, 200)
  }, [galleryData, openGalleryModal]);


  return (
    <>
      <Dialog
        open={openGalleryModal}
        onClose={handleOpenClose}
        aria-labelledby="image-list-modal"
        aria-describedby="image-list-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ paddingTop: '11px', paddingBottom: '3px' }} id="image-list-modal-title">
          <Typography
            sx={{
              minWidth: '67px',
              height: '11px',
              textAlign: 'center',
              fontWeight: 300,
              fontSize: '13px',
              lineHeight: '15px',
              letterSpacing: '0px',
              textTransform: 'uppercase',
              opacity: 1,
              paddingBottom: '22px'
            }}
          >
            {galleryModalHeadingText}
          </Typography>
          <Typography sx={{ textAlign: 'center' }}>{galleryData?.companyName}</Typography>
          <IconButton
            aria-label="close"
            onClick={handleOpenClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ overflow: 'hidden' }} dividers>
          <Gallery >
            <ImageList sx={{ width: '100%', height: '100%' }} cols={3} >
              {galleryData instanceof Object && galleryData?.media?.length > 0 && galleryData.media.map((item, index) => {
                const mediaType = item.mediaType && item.mediaType.length > 0 ? item.mediaType : null;
                const firstButtonRef = index === 0 ? buttonRef : null;
                return <Item
                  key={item.img}
                  original={item?.mediaUrl ? item?.mediaUrl : staticConstants.placeholderImageUrl}
                  thumbnail={item?.mediaUrl ? item?.mediaUrl : staticConstants.placeholderImageUrl}
                  width="1024"
                  height="768"
                >
                  {({ ref, open }) => (
                    <Box ref={ref}
                    >
                      <ImageListItem
                        sx={{
                          '& img': {
                            width: '100%',
                            cursor: 'pointer',
                            objectFit: 'fill',
                            maxHeight: '165px',
                            '@media (max-width:600px)': {
                              maxHeight: '57px',
                            }
                          },
                        }}
                        key={item.img}
                      >
                        {
                          mediaType === PACKAGES_CARD.MEDIA_TYPE.VIDEO ?
                            <video
                              src={item?.mediaUrl && item?.mediaUrl}
                              onClick={(e) => {
                                handleOpenCloseCarousel();
                                open();
                              }}
                              style={{
                                width: '100%',
                                height: '165px',
                                marginTop: '0rem',
                                marginBottom: '0rem',
                                cursor: 'pointer'
                              }}
                              autoPlay
                              controls
                            />
                            :
                            <Box
                              ref={firstButtonRef}
                              component='img'
                              onClick={(e) => {
                                handleOpenCloseCarousel();
                                open();
                              }}
                              src={item?.mediaUrl ? item?.mediaUrl : staticConstants.placeholderImageUrl}
                              onError={(e) => { e.target.src = staticConstants.placeholderImageUrl }}
                              loading="lazy"
                              type="image/webp"
                            />
                        }
                      </ImageListItem>
                    </Box>
                  )}
                </Item>
              })}
            </ImageList>
          </Gallery>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Index;
