export const formatCurrency = (amount) => {
    if (amount === null) {
        return 0;
    }
    let intAmount = parseInt(amount)
    const parts = intAmount.toFixed(2).toString().split('.');
    const wholePart = parts[0];
    const decimalPart = parts[1];
    const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${formattedWholePart}`;
}

