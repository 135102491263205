import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid,
    Box,
    Typography,
    Button
} from '@mui/material';


import PackagesCards from '../../../../components/PackagesCards'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { getDynamicEventServices, setDynamicEventServices } from './../../../../redux/EventServices/action'
import { EventServicesIncludesStyles } from './style'
import ServiceIncludesList from '../../../../components/ServiceIncludesList'
import { isEmptyObject } from '../../../../utils/emptyHelpers'
import { filterIsDescriptionExist } from '../../../../utils/filterDataHelpers'
import LoadingCardSkeleton from '../../../../components/LoadingCardSkeleton'
import { Link } from "react-router-dom";

const Index = () => {
    const packageServices = useSelector(state => state.DynamicEventServices.services?.body?.data || [])
    const isServiceCardLoading = useSelector(state => state.DynamicEventServices.loading)
    const dispatch = useDispatch()
    const includesListData = !isEmptyObject(packageServices) ? filterIsDescriptionExist(packageServices.includes) : []
    const excludesListData = !isEmptyObject(packageServices) ? filterIsDescriptionExist(packageServices.excludes) : []
    const addOnsListData = !isEmptyObject(packageServices) ? filterIsDescriptionExist(packageServices.addons) : []
    const { serviceId } = useParams()
    const [showBackButton, setShowBackButton] = useState(false);
    const location = useLocation()

    useEffect(() => {
        if (!location.state) {
            setShowBackButton(true);
          }
        dispatch(getDynamicEventServices(serviceId))
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return () => dispatch(setDynamicEventServices({}))
    }, [])

    return (
        <Container maxWidth="md" sx={{ marginBottom: '4rem', maxWidth: '720px', borderLeft: '1px solid #F2F2F2', borderRight: '1px solid #F2F2F2', paddingTop: '1rem' }}>
            <Grid
                spacing={4} justifyContent="center" alignItems="center"
            >
                <Grid sx={{ marginBottom: '1.2rem' }} >
                    {
                        isServiceCardLoading ? <LoadingCardSkeleton /> :
                            <>
                                {(Object.keys(packageServices)?.length !== 0) && <PackagesCards
                                    packagesData={[packageServices]}
                                    toggleImageText={false}
                                    type={'eventServicesCard'}
                                    isNaved={showBackButton}
                                    galleryModalHeadingText={'SERVICE'}
                                />}
                            </>
                    }
                </Grid>
                {
                    includesListData.length > 0 &&
                    <ServiceIncludesList
                        serviceListData={includesListData}
                        topHeadingText={'includes'}
                    />
                }
                {
                    excludesListData.length > 0 &&
                    <ServiceIncludesList
                        serviceListData={excludesListData}
                        topHeadingText={'excludes'}
                    />
                }
                {
                    addOnsListData.length > 0 &&
                    <ServiceIncludesList
                        serviceListData={addOnsListData}
                        topHeadingText={'add-ons'}
                    />
                }
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ ...EventServicesIncludesStyles.buttonContent.container, paddingBottom: '3rem' }}>
                    {
                        (Object.keys(packageServices)?.length !== 0) &&
                        <Link to={`tel:+${!isEmptyObject(packageServices) && packageServices?.bizContactNo}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Button variant="contained"
                                // onClick={() => navigate(routePaths.login)}
                                sx={{ ...EventServicesIncludesStyles.buttonContent.button, }}
                            >

                                Call

                                {/* CALL */}
                            </Button>
                        </Link>
                    }
                </Box>
            </Grid>
        </Container>
    )
}

export default Index

