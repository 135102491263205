import React from 'react';
import Interceptor from './api/interceptor'
import { Route, Routes } from 'react-router';
import './assets/scss/common.scss'
import { publicRoutes } from './routes';
// import { privateRoutes, publicRoutes } from './routes';
import AuthLayout from './layout/AuthLayout';
import NonAuthLayout from './layout/NonAuthLayout'
import { Helmet } from "react-helmet";

const App = () => {

  return (
    <React.Fragment>
      <Interceptor />
      <Helmet>
      <meta property="locale" content="en_GB" />
        <meta property="type" content="website" />
        <meta property="site_name" content="Events6" />
      </Helmet>
      <Routes >
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
          />
        ))}
        {/* {privateRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <AuthLayout>
                {route.component}
              </AuthLayout>}
            key={idx}
          />
        ))} */}
      </Routes>
    </React.Fragment>
  );
}

export default App;
