import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  Avatar,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SearchIcon from '@mui/icons-material/Search';
import { EventPackagesStyles } from './style';
import TuneIcon from '@mui/icons-material/Tune';
import GridViewIcon from '@mui/icons-material/GridView';
import BrandCards from '../../../../components/BrandCards'
import { routePaths } from '../../../../routes/routePaths'
import { useNavigate } from 'react-router';
import {
  getEventServiceDynamic,
  setEventServicesFilter,
  resetEventServicesFilter,
  resetEventServicesPayload,
  setEventServicesPayload,
  setEventServicesIsDelete,
  toggleEventServicesPopup,
  setEventServicesApplyFilterData,
  setHoldEventServicePayload,
  setHoldEventServiceFilter,
  setEventServiceEditStart,
  setEventServicesTypeInputData,
  setEventServiceBudgetInputData,
  setEventServiceBrandTabs
} from '../../../../redux/EventServices/action'
import { getPackagesSearchKeywords } from '../../../../redux/PackagesServices/action'
import { useDispatch, useSelector } from 'react-redux';
import PackagesCards from '../../../../components/PackagesCards'
import FilterModal from '../../../../components/FilterModal'
import { MAIN_MATCH_CONSTANTS } from '../../../../constants/MainMatchConstants'
import { staticConstants } from '../../../../constants/StaticConstants'
import { formatCurrency } from '../../../../utils/formatCurrency'
import { findPreviousObject } from '../../../../utils/findPreviousObject'
import { budgetPrice, numberOfGuests } from '../../../../constants/DataRowConstants'
import { getUniqueEventCompanies } from '../../../../utils/filterDataHelpers'
import ScrollButtonBack from '../../../../components/ScrollButtonBack'
import ScrollButtonNext from '../../../../components/ScrollButtonNext'
import KeywordSearch from '../../../../components/KeywordSearch'
import LoadingCardSkeleton from '../../../../components/LoadingCardSkeleton'
import InfiniteScroll from 'react-infinite-scroll-component';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
const Index = () => {
  const dispatch = useDispatch()
  const packagesData = useSelector(state => state.EventServiceDynamicSearch.dynamicEventServiceScreen?.body?.data)
  const packagesServiceLoading = useSelector(state => state.EventServiceDynamicSearch.loading)
  const totalResult = useSelector(state => state.EventServiceDynamicSearch.dynamicEventServiceScreen?.body?.count)
  const navigate = useNavigate()
  const allEventServicesData = useSelector((state) => state.AllEventServiceInputStore);
  const { eventServicesType, serviceBudget } = allEventServicesData
  const [toggleImageText, setToggleImageText] = useState(false)
  const { servicesBudget, servicesSelect, dynamicEventServiceRoute, eventType: eventTypeRoute, eventLocation: eventLocationRoute } = routePaths
  const { FILTER_LABELS, DYNAMIC_SCREEN_TYPES, NOT_SURE, TYPES, OTHER } = MAIN_MATCH_CONSTANTS
  const { budgetStatic, radiusStatic, guestsStatic } = staticConstants.defaultSettings
  const { eventServiceSelectTabDefault, eventSearchStatic } = staticConstants.eventServiceStatic
  const EventSettings = useSelector((state) => state.EventSettings.settings);
  const allFilterEventServicesStore = useSelector((state) => state.FilterEventServicesStore)
  const defaultSetting = {
    defaultGuests: EventSettings?.settings ? EventSettings.settings.guests : guestsStatic,
    defaultBudget: EventSettings?.settings ? EventSettings.settings.budget : budgetStatic,
    radiusDefault: radiusStatic.default
  }

  const { isFilter, isDelete, isEditStart } = allFilterEventServicesStore
  const brandsData = useMemo(() => getUniqueEventCompanies(packagesData), [packagesData])
  const allEventPackagesData = useSelector((state) => state.AllPackagesServiceStore);
  const { community, religion, eventLocation, eventType } = allEventPackagesData
  const keywordsData = useSelector((state) => state.PackagesSearchKeywords.keywords)

  const isLocationExist = JSON.parse(localStorage.getItem('location'))

  const filterConstants = [
    {
      label: FILTER_LABELS.LOCATIONS,
      value: isLocationExist?.formatted_address ? isLocationExist?.formatted_address : "Select Location",
      id: isLocationExist?.place_id,
      path: eventLocationRoute,
      pathState: { dynamicEventServiceRoute: dynamicEventServiceRoute },
      defaultDeleteName: "Select Location",
      isDelete: false,
    }, {
      label: FILTER_LABELS.BRANDS,
      value: "",
      id: 201514,
      path: dynamicEventServiceRoute,
      defaultDeleteName: "",
      isDelete: false
    },
    {
      label: FILTER_LABELS.SERVICE_EVENT_BUDGET,
      value: serviceBudget.value,
      id: serviceBudget.id,
      path: servicesBudget,
      maxPrice: budgetStatic.maxPrice,
      isDelete: false,
      pathState:
      {
        type: DYNAMIC_SCREEN_TYPES.EVENT_SERVICES_BUDGET,
        isFilter,
        dynamicServicePath: dynamicEventServiceRoute
      }
    },
    {
      label: FILTER_LABELS.SERVICE_EVENT_TYPE,
      value: eventServicesType?.serviceTypeName,
      id: eventServicesType?.serviceTypeId,
      path: servicesSelect,
      serviceTypeId: eventServicesType?.serviceTypeId,
      isDelete: false,
      pathState: {
        type: DYNAMIC_SCREEN_TYPES.SELECT_SERVICE,
        isFilter,
        dynamicServicePath: dynamicEventServiceRoute
      }
    },
    {
      label: FILTER_LABELS.EVENT_TYPE,
      value: eventType?.eventTypeName ? eventType?.eventTypeName : "Any Event",
      id: eventSearchStatic.eventTypeId,
      path: eventTypeRoute,
      pathState: { type: TYPES.EVENT_TYPE, dynamicServicePath: dynamicEventServiceRoute, reBackPagePath: dynamicEventServiceRoute },
      eventTypeId: eventSearchStatic.eventTypeId,
      defaultDeleteName: "Any Event",
      isDelete: false
    },
    {
      label: FILTER_LABELS.COMMUNITY_AND_CULTURE,
      value: community?.communityName ? community?.communityName : "Any Community",
      id: eventSearchStatic.communityId,
      path: eventTypeRoute,
      pathState: { type: TYPES.COMMUNITY, dynamicServicePath: dynamicEventServiceRoute, reBackPagePath: dynamicEventServiceRoute },
      communityId: eventSearchStatic.communityId,
      defaultDeleteName: "Any Community",
      isDelete: false
    },
    {
      label: FILTER_LABELS.RELIGION,
      value: religion?.religionName ? religion?.religionName : "Any Religion",
      id: eventSearchStatic.religionId,
      path: eventTypeRoute,
      pathState: { type: TYPES.RELIGION, dynamicServicePath: dynamicEventServiceRoute, reBackPagePath: dynamicEventServiceRoute },
      religionId: eventSearchStatic.religionId,
      defaultDeleteName: "Any Religion",
      isDelete: false
    },
  ]

  const [openPopUp, setOpenPopUp] = useState(isFilter);
  const [serviceCardCount, setServiceCardCount] = useState(10)

  useEffect(() => {
    if (eventServicesType?.serviceTypeId) {
      if (!isDelete && !isFilter) {
        if (!isEditStart) {
          dispatch(setEventServicesFilter(filterConstants))
          dispatch(setEventServicesPayload(designFilterPayload(filterConstants)))
        } else {
          dispatch(setEventServicesFilter(allFilterEventServicesStore.filterData))
          dispatch(setEventServicesPayload(allFilterEventServicesStore.apiPayload))
        }
        // dispatch(setEventServicesPayload(designFilterPayload(allFilterEventServicesStore.filterData)))
      }
    } else {
      //reset filter when user reload's the page
      dispatch(resetEventServicesPayload({
        ...allFilterEventServicesStore.apiPayload, serviceTypeId: 438,
        minPrice: defaultSetting.defaultBudget.defaultMin,
        maxPrice: defaultSetting.defaultBudget.defaultMax
      }))
      dispatch(resetEventServicesFilter())
    }
    dispatch(getPackagesSearchKeywords({ "keyword": "" })) // getting keyword
    dispatch(setHoldEventServicePayload(allFilterEventServicesStore.apiPayload))
    dispatch(setHoldEventServiceFilter(allFilterEventServicesStore.filterData))
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    !isFilter && dispatch(setEventServicesApplyFilterData(allFilterEventServicesStore.filterData))
  }, [isDelete, isFilter, allFilterEventServicesStore.filterData])

  useEffect(() => {
    !isFilter && dispatch(getEventServiceDynamic(allFilterEventServicesStore.apiPayload, serviceCardCount))
  }, [allFilterEventServicesStore.apiPayload, serviceCardCount])

  const handleDelete = (chipToDelete) => () => {
    const cloneFilterData = [...allFilterEventServicesStore.filterData]
    const modifiedFilterData = cloneFilterData.map((items) => {
      if (chipToDelete.id === items.id) {
        switch (chipToDelete.label) {
          case FILTER_LABELS.SERVICE_EVENT_TYPE:
            dispatch(setEventServicesTypeInputData(eventServiceSelectTabDefault))
            return { ...items, value: "Any", id: 438, serviceTypeId: 438, isDelete: true, defaultDeleteName: 'Select Service Type' }
          case FILTER_LABELS.BUDGET:
            dispatch(setEventServiceBudgetInputData({ value: 100000, id: 114 }))
            return { ...items, value: 100000, id: 114, isDelete: true, defaultDeleteName: 'Select Budget' }
          case FILTER_LABELS.EVENT_TYPE:
            // dispatch(setEventTypeInputData(staticConstants.packageScreen.eventDefault))
            return { ...items, value: "Any Event", id: 74, eventTypeId: 74, isDelete: true, defaultDeleteName: 'Select Event Type' }
          case FILTER_LABELS.COMMUNITY_AND_CULTURE:
            // dispatch(setEventCommunityInputData({ communityName: "Any Community", communityId: 1602, id: 1602, delete: true }))
            return { ...items, value: "Any Community", id: 1602, communityId: 1602, isDelete: true, defaultDeleteName: 'Select Community' }
          case FILTER_LABELS.RELIGION:
            // dispatch(setEventReligionInputData({ religionName: "Any Religion", religionId: 8, id: 8 }))
            return { ...items, value: "Any Religion", id: 8, religionId: 8, isDelete: true, defaultDeleteName: 'Select Religion' }
          case FILTER_LABELS.LOCATIONS:
            delete items.location
            return { ...items, id: 14548, value: "By Location", isDelete: true, defaultDeleteName: 'Select Location' }
          case FILTER_LABELS.BRANDS:
            return { ...items, id: 201514, value: "", isDelete: false, defaultDeleteName: '', keyword: '' }
          default:
            return { ...items, value: "" }
        }
      } else {
        return items
      }
    })
    const isAnyValue = modifiedFilterData.some((items) => items.id === chipToDelete.id)
    dispatch(setEventServicesIsDelete(true))
    dispatch(setEventServicesFilter(modifiedFilterData))
    dispatch(setEventServicesPayload(designFilterPayload(modifiedFilterData)))
    dispatch(setEventServiceEditStart(true))
  };

  const designFilterPayload = (data) => {
    const updatedServicesPayload = { ...allFilterEventServicesStore.apiPayload };

    data.forEach(item => {
      const key = MAIN_MATCH_CONSTANTS.LABEL_KEY_CHECK[item.label];
      if (key !== undefined) {
        if (key === MAIN_MATCH_CONSTANTS.MAX_PRICE) {
          updatedServicesPayload[key] = item.value ? item.value : defaultSetting.defaultBudget.defaultMax
          if (defaultSetting.defaultBudget.defaultMax === item.value) {
            updatedServicesPayload.minPrice = 0
          } else {
            updatedServicesPayload.minPrice = item.value ? findPreviousObject(budgetPrice, item.value)?.value : defaultSetting.defaultBudget.defaultMin
          }
        }
        else if (key === MAIN_MATCH_CONSTANTS.MATCH_LOCATION) {
          updatedServicesPayload[key] = item[key]
        }
        else if (MAIN_MATCH_CONSTANTS.MATCH_SERVICE_EVENT_TYPE_KEY.includes(key)) {
          updatedServicesPayload[key] = item[key]
        }
        else if (key === MAIN_MATCH_CONSTANTS.MAX_GUESTS) {
          updatedServicesPayload[key] = (item.value && item?.value !== NOT_SURE && item?.value !== OTHER) ? item.value : defaultSetting.defaultGuests.defaultMax
          if (defaultSetting.defaultGuests.defaultMax === item.value) {
            updatedServicesPayload.minGuests = 1
          } else {
            updatedServicesPayload.minGuests = item.value ? findPreviousObject(numberOfGuests, item.value)?.value : defaultSetting.defaultGuests.defaultMin
          }
        } else if (MAIN_MATCH_CONSTANTS.MATCH_EVENT_TYPE_KEY.includes(key)) {
          updatedServicesPayload[key] = item[key]
        } else if (key === MAIN_MATCH_CONSTANTS.SEARCH_KEYWORD) {
          updatedServicesPayload[key] = item[key]
        }
        else {
          updatedServicesPayload[key] = item.value;
        }
      }
    });

    return updatedServicesPayload
  }

  const handleChange = (event, newValue) => {
    dispatch(setEventServiceBrandTabs(newValue))

  };
  const handleBack = (e) => {
    e.preventDefault();
    navigate(servicesBudget)
  }

  const handleOnClickCard = (items) => {
    navigate(`/services/view/${items.serviceId}/${Math.floor(Math.random()*898)+101}`,{
      state: { customData: 'Some data' }
  })}

  const handleCloseOpenModal = () => {
    setOpenPopUp(!openPopUp);
    dispatch(toggleEventServicesPopup())
  };

  const handleClickOnFilter = (filteredItem) => {
    if (filteredItem.pathState) {
      dispatch(setEventServiceEditStart(true))
    }
    navigate(filteredItem.path, filteredItem.pathState ? { state: { ...filteredItem.pathState, isFilter } } : { state: { type: dynamicEventServiceRoute } })
  }

  const handleApplyFilter = (type) => {
    if (type === 'apply') {
      const clonePayload = { ...allFilterEventServicesStore.apiPayload }
      clonePayload.keyword = allFilterEventServicesStore.applyFilterData ? allFilterEventServicesStore.applyFilterData[1]?.keyword : ""
      dispatch(setEventServicesPayload(clonePayload))
      dispatch(setHoldEventServicePayload(designFilterPayload(allFilterEventServicesStore.filterData)))
      dispatch(setEventServicesFilter(allFilterEventServicesStore.applyFilterData))
      dispatch(setHoldEventServiceFilter(allFilterEventServicesStore.filterData))
      dispatch(setEventServiceEditStart(true))
      handleCloseOpenModal()
    } else {
      dispatch(setEventServicesPayload({ ...allFilterEventServicesStore.holdApiPayload }))
      dispatch(setEventServicesFilter(allFilterEventServicesStore.holdFilterData))
      dispatch(toggleEventServicesPopup())
    }

  }

  const handleRedirectBadge = (items) => {
    handleClickOnFilter(items)
  }

  const handleOnclickKeywordList = (keyword) => {
    dispatch(setEventServicesFilter(allFilterEventServicesStore.filterData))
    dispatch(setHoldEventServicePayload(allFilterEventServicesStore.apiPayload))
    editFilterData(
      isFilter ? allFilterEventServicesStore.applyFilterData : allFilterEventServicesStore.filterData,
      FILTER_LABELS.BRANDS, keyword
    )
  }


  const editFilterData = (data, type, updateData) => {
    const editedFilterData = data.map((items) => {
      if (items.label === type) {
        return {
          ...items,
          value: updateData,
          keyword: updateData,
          delete: false,
          isDelete: false
        }
      }
      else {
        return items
      }
    })
    if (isFilter) {
      dispatch(setEventServicesApplyFilterData(editedFilterData))
    } else {
      dispatch(setEventServicesFilter(editedFilterData))
      dispatch(setEventServicesApplyFilterData(editedFilterData))
      dispatch(setEventServicesPayload(designFilterPayload(editedFilterData)))
    }
  }
  const scrollableRef = useRef(null);
  const [isScrollVisible, setIsScrollVisible] = useState(false);

  useEffect(() => {
    checkScroll();
  }, []);


  const checkScroll = () => {
    if (
      scrollableRef &&
      scrollableRef.current &&
      scrollableRef.current.scrollWidth > scrollableRef.current.clientWidth
    ) {
      setIsScrollVisible(true);
    } else {
      setIsScrollVisible(false);
    }
  };

  const onScroll = (offset) => {
    if (scrollableRef && scrollableRef.current) {
      const currentScrollLeft = scrollableRef.current.scrollLeft;
      const newScrollLeft = currentScrollLeft + offset;
      scrollableRef.current.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const fetchMoreData = () => {
    setServiceCardCount(prevPage => prevPage + 10);
  };

  return (
    <>
      <Container maxWidth="md" sx={{ maxWidth: '720px', border: '1px solid #F2F2F2', paddingTop: '36px', marginBottom: '3rem' }}>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Box sx={EventPackagesStyles.eventPackagesContainer.container}>
              <Avatar sx={EventPackagesStyles.eventPackagesContainer.avatar} onClick={(e) => { handleBack(e) }} >
                <KeyboardArrowLeftIcon sx={{ color: 'black' }} />
              </Avatar>
              <Typography variant="h5" sx={EventPackagesStyles.eventPackagesContainer.labelText}>
                {eventServicesType?.serviceTypeName ? eventServicesType?.serviceTypeName : eventServiceSelectTabDefault.serviceTypeName}
              </Typography>
              <KeywordSearch
                data={keywordsData}
                handleOnclickKeywordList={handleOnclickKeywordList}
                openType="packages"
              />
            </Box>
          </Grid>

          <Grid sx={EventPackagesStyles.listItems.container} item xs={12}>

            <Grid sx={{
              justifyContent: 'space-between'
            }} container>
              <Grid
                sx={{
                  textAlign: 'start',
                }}
                item xs={0.5} lg={0.5} md={0.5}>
                <ScrollButtonBack onScroll={onScroll} />
              </Grid>
              <Grid item xs={11} lg={11} md={11}>
                <Paper
                  sx={EventPackagesStyles.listItems.paper}
                  component="ul"
                  ref={scrollableRef}
                >
                  <ListItem >
                    <Chip
                      onClick={handleCloseOpenModal}
                      icon={<TuneIcon sx={{ color: 'white' }} />}
                      label={"Filters"}
                      sx={EventPackagesStyles.listItems.badgesBox}
                    />
                  </ListItem>
                  {allFilterEventServicesStore.filterData.filter(value => value.value).map((data) => {
                    return (
                      <React.Fragment key={data.key}>
                        <ListItem key={data.key}>
                          <Chip

                            onClick={() => { handleRedirectBadge(data) }}
                            label={`${!data.isDelete ?
                              (data.label === FILTER_LABELS.BUDGET ?
                                (NOT_SURE !== data.value ? "Budget £" + formatCurrency(data.value) : NOT_SURE)
                                : data.value)
                              : data.defaultDeleteName
                              }`}
                            onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                            deleteIcon={
                              <CloseIcon style={{
                                ...EventPackagesStyles.listItems.icon,
                                display: data.isDelete ? 'none' : 'block'
                              }} />
                            }
                            sx={data.isDelete ? EventPackagesStyles.listItems.deletedBadges : EventPackagesStyles.listItems.badges}
                          />
                        </ListItem>
                      </React.Fragment>
                    );
                  })}
                </Paper>
              </Grid>
              <Grid
                sx={{
                  textAlign: 'end'
                }}
                item xs={0.5} lg={0.5} md={0.5}>
                <ScrollButtonNext onScroll={onScroll} />
              </Grid>
            </Grid>


            <Divider variant="fullWidth" orientation="horizontal" />
            <Box sx={EventPackagesStyles.tabBox.container}>
              <Tabs
                value={allFilterEventServicesStore.activeBrandTab}
                onChange={handleChange}
                textColor="black"
                aria-label="secondary tabs example"
                indicatorColor="primary"
                sx={EventPackagesStyles.tabBox.tabs}
              >
                <Tab value="one" label={eventServicesType?.serviceTypeName ? eventServicesType?.serviceTypeName : eventServiceSelectTabDefault.serviceTypeName} />
                <Tab value="two" label="Brands" />
              </Tabs>
            </Box>
          </Grid>
        </Grid>
        <Divider variant="fullWidth" orientation="horizontal" />
        {
          allFilterEventServicesStore.activeBrandTab === "two" ?
            <BrandCards
              brandsData={brandsData || []}
            /> : <>
              <Grid sx={EventPackagesStyles.resultFound.container}>
                {totalResult ? totalResult : 0} Results Found
                {
                  toggleImageText ? <Box onClick={() => setToggleImageText(!toggleImageText)} component="span">
                    <DehazeIcon />
                  </Box> : <Box onClick={() => setToggleImageText(!toggleImageText)} component="span">
                    <GridViewIcon />
                  </Box>
                }
              </Grid>
              <Divider variant="fullWidth" orientation="horizontal" />
              {
                (packagesServiceLoading && serviceCardCount === 10) ? <LoadingCardSkeleton /> :
                  <InfiniteScroll
                    dataLength={packagesData?.length || 0}
                    next={fetchMoreData}
                    hasMore={totalResult !== packagesData?.length}
                    loader={<LoadingCardSkeleton />}
                    scrollableTarget="scrollableDiv"
                  >
                    <PackagesCards
                      packagesData={packagesData}
                      toggleImageText={toggleImageText}
                      handleOnClickCard={handleOnClickCard}
                      type={'dynamicEventServiceScreen'}
                    />
                  </InfiniteScroll>
              }
            </>
        }
      </Container>
      {openPopUp && <FilterModal
        onClickModal={handleCloseOpenModal}
        type={'country'}
        openDialog={openPopUp}
        topHeading={'FILTERS'}
        filterFields={allFilterEventServicesStore.applyFilterData}
        handleClickOnFilter={handleClickOnFilter}
        handleApplyFilter={handleApplyFilter}
        handleOnclickKeywordList={handleOnclickKeywordList}
        keywordInputValue={allFilterEventServicesStore?.apiPayload?.keyword}
      />}
    </>
  );
};

export default Index
