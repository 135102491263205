import React, { useState } from 'react'
import { Container, Typography, TextField, Button, Grid, Box, IconButton } from '@mui/material';
import { RegistrationStyles } from './style'
import CountryCodeConstants from '../../../constants/CountryCodeConstants/CountryCodeConstants.json'
import ModalPopUp from "../../../components/ModalPopUp";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { userRegistration } from '../../../redux/auth/action'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { routePaths } from '../../../routes/routePaths'

const Index = () => {
  const [formData, setFormData] = useState({
    country: '',
    mobileNumber: '',
    fullName: '',
    emailAddress: '',
  });
  const [filteredCountryCode, setFilteredCountryCode] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [searchTerm, setSearchTerm] = useState()
  const [countyInputValue, setCountyInputValue] = useState({ name: 'United Kingdom', flag: '🇬🇧', code: 'GB', dial_code: '+44' })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [errors, setErrors] = useState({
    isMobileNumberError: false,
    isFullNameError: false,
    isEmailAddressError: false,
  });

  const validateMobileNumber = (value) => {
    const mobileNumberRegex = /^\d+$/;
    return mobileNumberRegex.test(value);
  };

  const validateFullName = (value) => {
    const fullNameRegex = /^[A-Za-z\s]+$/;
    return fullNameRegex.test(value);
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };
    if (name === 'mobileNumber') {
      newErrors.isMobileNumberError = !validateMobileNumber(value);
    } else if (name === 'fullName') {
      newErrors.isFullNameError = !validateFullName(value);
    } else if (name === 'emailAddress') {
      newErrors.isEmailAddressError = !validateEmail(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors(newErrors);
  };

  const handleSubmit = () => {
    const { isMobileNumberError, isFullNameError, isEmailAddressError } = errors;
    const { emailAddress, fullName, mobileNumber } = formData

    if (!isMobileNumberError && !isFullNameError && !isEmailAddressError && emailAddress && fullName && mobileNumber) {
      const { dial_code, code } = countyInputValue
      let cleanedDialCode = dial_code ? dial_code?.replace(/\+/g, "") : ""
      const payload = {
        "fullName": fullName,
        "country": code,
        "countryDialCode": cleanedDialCode,
        "mobileNo": `${dial_code} ${mobileNumber}`,
        "email": emailAddress,
        "isActive": true
      }
      dispatch(userRegistration(payload, navigate))
    } else {
      toast.error('Please fill out the form accurately. Unable to submit.')
    }
  };

  const handleCloseOpenModal = () => {
    setOpenPopUp(!openPopUp);
  };
  const onClickOnlist = (listItem) => {
    setCountyInputValue(listItem)
    setOpenPopUp(!openPopUp);
  }
  const handleModalSearch = (value) => {
    const cloneCountryCodes = [...CountryCodeConstants]
    const filteredData = cloneCountryCodes.filter((country) => {
      const { name, code } = country;
      return name.toLowerCase().includes(value.toLowerCase()) || code.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredCountryCode(filteredData);
    setSearchTerm(value)
  }

  return (
    <Grid>
      <Container sx={RegistrationStyles.container}>
        <Grid style={{ width: '100%', marginTop: '3rem', }}>
          <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '2rem' }}>
            <Typography variant="h5" sx={RegistrationStyles.registrationContainer.labelText}>
              REGISTER
            </Typography>
          </Grid>

          <Grid sx={RegistrationStyles.inputsContainer}>
            <Grid
              item
              xs={12}
              sm={8}
              md={4}
              style={{ borderRadius: '25px', marginBottom: '1rem', width: '312px' }}
            >
              <Typography variant="body1"  sx={RegistrationStyles.labels}>COUNTRY</Typography>
              <Box
                component="div"
                sx={RegistrationStyles.country.container}
                onClick={handleCloseOpenModal}
              >
                <Box
                  component="span"
                  sx={RegistrationStyles.country.text}
                >
                  {countyInputValue.name}
                </Box>
                <IconButton edge="end" >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Grid sx={RegistrationStyles.inputsContainer} style={{ marginTop: '0rem' }}>
            <Grid
              item
              xs={12}
              sm={8}
              md={4}
              style={{ borderRadius: '25px', minWidth: '312px' }}
            >
              <Typography variant="body1"  sx={RegistrationStyles.labels}>MOBILE NUMBER</Typography>
              <TextField
                fullWidth
                variant="outlined"
                sx={{
                  "& fieldset": { border: 'none' },
                }}
                InputProps={{
                  style: {  borderRadius: '25px',
                  backgroundColor: '#f2f2f2',
                  minWidth: '154px',
                  height: '49px',
                  textAlign: 'left',
                  fontWeight: 'normal',
                  fontSize: '19px',
                  // lineHeight: '22px',
                  fontFamily: 'Open Sans, sans-serif',
                  letterSpacing: '0px',
                  color: '#0F0F0F',
                  opacity: 1},
                  startAdornment: <Box sx={{ marginRight: '7px' }}>{countyInputValue.dial_code}</Box>,
                }}
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleInputChange}
                error={errors.isMobileNumberError}
                helperText={errors.isMobileNumberError ? 'Please enter a valid mobile number' : ''}
              />

            </Grid>
          </Grid>
          <Grid sx={RegistrationStyles.inputsContainer} style={{ marginTop: '1rem' }}>
            <Grid
              item
              xs={12}
              sm={8}
              md={4}
              style={{ borderRadius: '25px', minWidth: '312px' }}
            >
              <Typography variant="body1"  sx={RegistrationStyles.labels}>FULL NAME</Typography>
              <TextField
                fullWidth
                variant="outlined"
                sx={{
                  "& fieldset": { border: 'none' },
                }}
                InputProps={{
                  style: { ...RegistrationStyles.inputsPropsStyles },

                }}
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                error={errors.isFullNameError}
                helperText={errors.isFullNameError ? 'Please enter a valid full name' : ''}
              />
            </Grid>
          </Grid>
          <Grid sx={RegistrationStyles.inputsContainer} style={{ marginTop: '1rem' }}>
            <Grid
              item
              xs={12}
              sm={8}
              md={4}
              style={{ borderRadius: '25px', minWidth: '312px' }}
            >
              <Typography variant="body1"  sx={RegistrationStyles.labels}>EMAIL ADDRESS</Typography>
              <TextField
                fullWidth
                variant="outlined"
                sx={{
                  "& fieldset": { border: 'none' },
                }}
                InputProps={{
                  style: RegistrationStyles.inputsPropsStyles,
                }}
                value={formData.emailAddress}
                onChange={handleInputChange}
                error={errors.isEmailAddressError}
                helperText={errors.isEmailAddressError ? 'Please enter a valid email ' : ''}
                name="emailAddress"
              />
            </Grid>
          </Grid>

          <Grid
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '2rem',
            }}
          >
            <Grid item xs={12} >
              <Box sx={RegistrationStyles.buttonContent.container} >
                <Button variant="contained"
                  onClick={handleSubmit}
                  sx={{ ...RegistrationStyles.buttonContent.button }}
                >
                  CONTINUE
                </Button>
              </Box>
              <Grid >
                <Box sx={{
                  display: 'flex',
                  width: '311px',
                  justifyContent: 'center',
                  textAlign: 'center',
                  marginTop: '19px'
                }}>
                  <Typography sx={{fontFamily:'Open Sans, sans-serif'}} >
                    By continuing you confirm that you have read and accept the{' '}
                    <span style={{ color: '#1850AC' }}>terms of service</span> and{' '}
                    <span style={{ color: '#1850AC' }}>privacy policy</span>
                  </Typography>
                </Box>
              </Grid>
              <Box sx={{ ...RegistrationStyles.buttonContent.container, marginBottom: '3rem' }}>
                <Button variant="contained" onClick={() => navigate(routePaths.login)} sx={{ ...RegistrationStyles.buttonContent.button, marginTop: '2rem' }}>
                  BACK TO LOGIN
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {openPopUp && <ModalPopUp
        onClickModal={handleCloseOpenModal}
        type={'country'}
        onClickOnlist={onClickOnlist}
        openDialog={openPopUp}
        topHeading={'SELECT YOUR COUNTRY'}
        listDataType={"name"}
        handleOnchangeSearch={handleModalSearch}
        searchPlaceHolder={"Search country or area code"}
        listingDetails={filteredCountryCode.length > 0 ? filteredCountryCode : (!searchTerm && CountryCodeConstants)}
      />
      }
    </Grid>
  )
}

export default Index
