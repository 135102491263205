import React from 'react'
import { Grid, Typography, Box, Avatar } from '@mui/material';
import { topBarStyles } from './style'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router';

const Index = (props) => {
    const { heading } = props
    const navigate = useNavigate()
    const handleBack = (e) => {
        e.preventDefault();
        navigate(-1)
    }
    return (
        <Grid item xs={12}>
            <Box sx={topBarStyles.eventLocationContainer.container}>
                <Avatar onClick={(e) => { handleBack(e) }} sx={topBarStyles.eventLocationContainer.avatar}>
                    <KeyboardArrowLeftIcon style={{ color: 'black' }} />
                </Avatar>
                <Typography sx={topBarStyles.eventLocationContainer.labelText}>
                    {heading}
                </Typography>
            </Box>
        </Grid>
    )
}

export default Index