export const verificationStyles = {
  container: {
    background: '#FFFFFF',
    backgroundPosition: '0% 0%',
    backgroundRepeat: 'no-repeat',
    border: '1px solid #F5F5F5',
    opacity: 1,
    '@media (max-width: 400px)': {
      minWidth: 'auto',
    },
    '@media (min-width: 700px)': {
      width: '735px',
    },
    minHeight: '550px'
  },
  inputsContainer: { marginTop: '2rem', display: 'flex', justifyContent: 'center', minHeight: '47px' },
  verificationContainer: {
    container: { display: 'flex', alignItems: 'center' },
    avatar: { backgroundColor: '#F2F2F2', maxWidth: '100%' },
    labelText: { fontSize: '25px', textAlign: 'center', width: '100%', color: '#0F0F0F' , fontFamily:'Open Sans, sans-serif'}
  },

  search: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F2F2F2',
      borderRadius: '10px',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
  },
  wembleyBox: {
    container: {
      height: '80px',
      background: '#F2F2F2',
      borderRadius: '11px',
      opacity: '1',
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap'
    },
    text: {
      paddingTop: '26px',
      fontFamily: 'Open Sans, sans-serif',
      lineHeight: '23px',
      letterSpacing: '0px',
      color: '#161616',
      opacity: '1',
    }
  },

  buttonContent: {
    container: { display: 'flex', justifyContent: 'center' },
    button: {
      // borderRadius: '20px', display: 'inline-block',,
      // minWidth: '312px',
      // height: '49px',
      // textDecoration: 'none',
      fontFamily: 'Open Sans, sans-serif',
      minWidth: '312px',
      minHeight: '46px',
      borderRadius: '25px',
      opacity: 1,
      backgroundColor: '#EEB000',
      '&:hover': {
        backgroundColor: '#efb719',
      },
    }
  }
}