import { Get_Login_Response, Get_Profile_Response, Set_Login_Input_Data, Set_Login_Response_Data, Set_Registration_RequestBody } from './actionType'
export const DashboardData = (data = [], action) => {

    switch (action.type) {
        case Get_Login_Response:
            return action.data
        default:
            return data;
    }
}
export const ProfileData = (data = [], action) => {
    switch (action.type) {
        case Get_Profile_Response:
            return action.data
        case Get_Login_Response:
            return action.data
        default:
            return data;
    }
}

const allAuthEventStates = {
    loginResponse: {},
    loginInput: {},
    verification: {},
    registrationRequestBody: {}
}

export const AllLoginDetails = (state = allAuthEventStates, action) => {
    switch (action.type) {
        case Set_Login_Input_Data:
            return {
                ...state,
                loginInput: action.data,
            };
        case Set_Login_Response_Data:
            return {
                ...state,
                loginResponse: action.data,
            };
        case Set_Registration_RequestBody:
            return {
                ...state,
                registrationRequestBody: action.data,
            };
        default:
            return state;
    }
};
