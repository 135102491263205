import { routePaths } from '../routes/routePaths'
import planEvents from '../assets/images/planEvent.svg'
import packageSvg from '../assets/images/eventPackages.svg'
import vendorSvg from '../assets/images/eventVendor.svg'
import einviteSvg from '../assets/images/EInvite.svg'
export const CardConstants = [
    // {
    //     text: 'Plan An Event',
    //     logo: planEvents,
    //     path: ""
    // },
    {
        text: 'Event Package Deals',
        logo: packageSvg,
        path: routePaths.eventLocation
    },
    {
        text: 'Event Services',
        logo: vendorSvg,
        path: routePaths.servicesSelect
    },
    // {
    //     text: 'Event Vendors',
    //     logo: vendorSvg,
    //     path: ''
    // },
    // {
    //     text: 'E-Invite (RSVP)',
    //     logo: einviteSvg,
    //     path: ''
    // },
    // {
    //     text: 'Quote Me',
    //     logo: '',
    //     path: ''
    // },
]

