import React, { useState, useEffect } from 'react'
import { staticConstants } from '../../../../constants/StaticConstants'
import { MAIN_MATCH_CONSTANTS } from '../../../../constants/MainMatchConstants'
import {
    Container, Grid, IconButton, Card, Box,
    Divider, Tab, Tabs, CardMedia, Rating, CardContent,
    styled, Typography
} from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SearchIcon from "@mui/icons-material/Search";
import { BrandPackagesServiceStyle } from './style'
import PackagesCards from '../../../../components/PackagesCards'
import { useNavigate, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { getBrandPackages, getBrandServices } from '../../../../redux/CommonBrandPackages/action'
import { isArrayEmpty } from '../../../../utils/emptyHelpers';
import LoadingCardSkeleton from '../../../../components/LoadingCardSkeleton'
import { getEventPackagesDetails } from '../../../../redux/PackagesServices/action';

const CustomHeading = styled(Typography)({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '190px',
    fontFamily: 'Open Sans, sans-serif',
});

const Index = () => {
    const [tabValue, setTabValue] = useState('one');
    const brandPackagesData = useSelector(state => state.BrandPackages.brandPackages?.body?.data)
    const brandServiceData = useSelector(state => state.BrandService.servicesPackages?.body?.data)
    const companyData = useSelector(state => state.BrandPackages.brandPackages?.body?.company)
    const isLoading = useSelector(state => state.BrandPackages.loading)
    const isBrandServiceLoading = useSelector(state => state.BrandService.loading)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { accountId } = useParams()
    const { packageId } = useParams()
    useEffect(() => {
        dispatch(getBrandPackages(accountId))
        dispatch(getBrandServices(accountId))
    }, [])

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleOnClickCard = (items) => {
  
        dispatch(getEventPackagesDetails(items.packageId))
        navigate(`/event/packages/details/${items.packageId}`, { state: { packagesData: items } })
    }
    const handleOnClickServiceCard = (items) => {
        dispatch(getEventPackagesDetails(items.packageId))
        navigate(`/services/view/${items.serviceId}/${Math.floor(Math.random()*898)+101}`,{
            state: { customData: items.packageId }
        });
    };

    return (

        <Container maxWidth="md" sx={{ maxWidth: '720px', padding: '0rem', border: '1px solid #F2F2F2', marginBottom: '3rem' }}>
            {
                <>
                    <Grid >
                        <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', boxShadow: 'none' }}>
                            <Box>
                                <CardMedia component="img"
                                    alt={`Product ${staticConstants.placeholderImageUrl}`}
                                    image={companyData?.logoUrl && (!isLoading || !isBrandServiceLoading) ? companyData?.logoUrl : staticConstants.placeholderImageUrl}
                                    style={{ width: '225px', maxHeight: '8.375rem', minHeight: '134px', objectFit: 'contain' }}
                                />
                                <CardContent>
                                    <CustomHeading sx={{ fontFamily: 'Open Sans, sans-serif' }} variant="h6" align="center" gutterBottom>
                                        {companyData?.companyName}
                                    </CustomHeading>
                                    <Typography sx={{ fontFamily: 'Open Sans, sans-serif' }} variant="body2" align="center" color="textSecondary" gutterBottom>
                                        {companyData?.companyLocation}
                                    </Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Rating name={`brand-rating-${companyData?.accountId}`} value={companyData?.rating} precision={0.5} readOnly />
                                    </Box>
                                </CardContent>
                            </Box>
                            <>
                                <IconButton
                                    onClick={(e) => { navigate(-1) }}
                                    sx={{
                                        position: 'absolute',
                                        top: '10px',
                                        left: '10px',
                                        borderRadius: '50%',
                                        width: '40px',
                                        height: '40px',
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                        '&:hover': {
                                            backgroundColor: '#E5E5E5'
                                        }
                                    }}
                                >
                                    <KeyboardArrowLeftIcon />
                                </IconButton>

                                <>

                                    <IconButton
                                        sx={{
                                            position: 'absolute', top: '10px', right: '10px', borderRadius: '50%',
                                            width: '40px',
                                            height: '40px',
                                            backgroundColor: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                            '&:hover': {
                                                backgroundColor: '#E5E5E5'
                                            }
                                        }}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </>


                            </>
                        </Card>
                    </Grid>
                    <Divider variant="fullWidth" orientation="horizontal" />
                    <Box sx={BrandPackagesServiceStyle.tabBox.container}>
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}
                            textColor="black"
                            aria-label="secondary tabs example"
                            indicatorColor="primary"
                            sx={BrandPackagesServiceStyle.tabBox.tabs}
                        >
                            <Tab value="one" label={`Packages (${!isArrayEmpty(brandPackagesData || []) ? brandPackagesData?.length : 0})`} />
                            <Tab value="two" label={`Services (${!isArrayEmpty(brandServiceData || []) ? brandServiceData?.length : 0})`} />
                        </Tabs>
                    </Box>
                    <Divider variant="fullWidth" orientation="horizontal" />
                    <Box sx={{ padding: '0rem 1rem' }}>
                        {
                            tabValue === MAIN_MATCH_CONSTANTS.ONE ?
                                <>
                                    {
                                        !isLoading ?
                                            <PackagesCards
                                                packagesData={brandPackagesData}
                                                toggleImageText={false}
                                                handleOnClickCard={handleOnClickCard}
                                                type={'packages'}
                                            /> : <LoadingCardSkeleton />
                                    }
                                </>
                                :
                                <>
                                    {
                                        !isBrandServiceLoading ?
                                            <PackagesCards
                                                packagesData={brandServiceData}
                                                toggleImageText={false}
                                                handleOnClickCard={handleOnClickServiceCard}
                                                type={'dynamicEventServiceScreen'}
                                            />
                                            : <LoadingCardSkeleton />
                                    }
                                </>
                        }
                    </Box>
                </>
            }

        </Container>
    )
}

export default Index