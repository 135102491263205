import auth from './auth'
import packagesServicesEvent from './packagesServicesEvent'
import eventServices from './eventServices'
import brandPackagesServices from './brandPackagesServices'
const dictionary = {
    auth,
    packagesServicesEvent,
    eventServices,
    brandPackagesServices
}
export default dictionary
