export const detailsStyle = {
    container: {
        background: '#FFFFFF',
        backgroundPosition: '0% 0%',
        backgroundRepeat: 'no-repeat',
        border: '1px solid #F5F5F5',
        opacity: 1,
        '@media (max-width: 400px)': {
            minWidth: 'auto',
        },
        '@media (min-width: 700px)': {
            width: '735px',
        },
    },
    list: {
        onlyText: {
            height: '39px',
            background: '#F4F4F4 0% 0% no-repeat padding-box',
            opacity: 1,
        },
        headText: {
            fontFamily: 'Open Sans, sans-serif',
            letterSpacing: '0px',
            color: '#000000',
            textTransform: 'uppercase',
            opacity: 1,
            paddingLeft: '13px',
            fontWeight: 300,
        },
        listServiceText: {
            paddingLeft: '2.1875rem',
            paddingBottom:'22px',
            width: '52px',
            height: '15px',
            fontSize: '17px',
            lineHeight: '19px',
            letterSpacing: '0px',
            color: '#080808',
            opacity: 1,
            whiteSpace: 'normal',
            fontFamily: 'Open Sans, sans-serif',
            '& span': {
                fontFamily: 'Open Sans, sans-serif'
            },
            '@media (max-width: 600px)': {
                fontSize: '14px',
                lineHeight: '16px',
                paddingLeft: '1rem',
                paddingBottom:'23px',
            },
        }
    },
    buttonContent: {
        container: { display: 'flex', justifyContent: 'center', },
        button: {
            borderRadius: '20px', display: 'inline-block', textDecoration: 'none',
            minWidth: '312px',
            height: '47px',
            backgroundColor: '#EEB000',
            fontFamily: 'Open Sans, sans-serif',
            color: '#FFFFFF',
            marginBottom: '8rem',
            marginTop: '1rem',
            '&:hover': {
                backgroundColor: '#efb719',
            },
        }
    }

}