import { put, takeLatest } from 'redux-saga/effects'
import { Login_User, User_Registration, Set_Registration_RequestBody, Send_Otp_Verification, Set_Login_Input_Data, Set_Login_Response_Data, Login_Admin, Get_Login_Response, Get_Logout_Response, Logout_Admin, Change_Password, Get_Profile, Get_Profile_Response, Reset_Password, Re_Send_Otp_Verification } from './actionType';
import { AxiosResponse } from 'axios';
import service from '../../api/services/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { routePaths } from '../../routes/routePaths'

function* getLogin(action) {
    try {
        yield put({ type: Set_Login_Input_Data, data: action.data });
        const response = yield service.auth.login(action.data);
        const data = yield response.data;
        if (response.status === 200) {
            if (data.head.success) {
                action.navigate(routePaths.verification)
                toast.success("Otp sent to you mobile number.");
            } else {
                toast.error("Something went wrong.")
            }
            yield put({ type: Set_Login_Response_Data, data: data });
        } else {
            toast.error(response?.message)
        }
        return response;
    } catch (error) {
        if (error?.response?.data?.status === 401) {
            toast.error("Email or password is incorrect")
        } else {
            toast.error(error?.response?.data?.data.message)
        }
        return action
    }

}

function* userRegistration(action) {
    try {
        const response = yield service.auth.register(action.data);
        const data = yield response.data;
        if (response.status === 200) {
            toast.success("Otp sent to you mobile number.");
            yield put({ type: Set_Registration_RequestBody, data: { ...action.data, userId: data.body.userId } });
            yield put({ type: Set_Login_Response_Data, data: data });
            action.navigate(routePaths.verification)
        } else {
            toast.error(response?.message)
        }
        return response;
    } catch (error) {
        if (error?.response?.data?.status === 401) {
            toast.error("Email or password is incorrect")
        } else {
            toast.error(error?.response?.data?.data.message)
        }
        return action
    }

}

function* sendOtpToUser(action) {
    try {
        const response = yield service.auth.sendOtp(action.data);
        const data = yield response.data;
        if (response.status === 200) {
            localStorage.setItem("access_token", data?.body?.token)
            if (data.head.success) {
                action.navigate(routePaths.home)
            } else {
                toast.error("Something went wrong.")
            }
        } else {
            toast.error(response?.message)
        }
        return response;
    } catch (error) {
        toast.error(error?.message && error?.message)
        if (error?.response?.data?.status === 401) {
            toast.error("Email or password is incorrect")
        } else {
            toast.error(error?.response?.data?.data.message)
        }
        console.log("Error",)
        return action
    }

}

function* reSendOtpToUser(action) {
    try {
        const response = yield service.auth.reSendOtp(action.data);
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(data.head.msg);
        } else {
            toast.error(response?.message)
        }
        return response;
    } catch (error) {
        toast.error(error.message)
        return action
    }

}

function* getLogout(action) {
    const headers = {
        'Authorization': `Bearer ${action.token}`,
        "Content-Type": "application/x-www-form-urlencoded"
    };
    const response = yield service.auth.logout({}, headers);
    const data = yield response.data;
    action.navigate("/login")
    localStorage.clear();
    if (response.status === 200) {
        toast.success(response.data.message);
        yield put({ type: Get_Logout_Response, data });
    }
    else {
        toast.error(response.data.message)
    }
    localStorage.removeItem("access_token");
    return response; // add this line to return the response
}
function* changePassword(action) {
    let payload = {
        old_password: action.data.oldPassword,
        new_password: action.data.newPassword,
    }
    try {
        const response = yield service.auth.changePassword(payload, {});

        if (response.status === 200) {
            toast.success(response.data.message);
            localStorage.removeItem("access_token");
            action.navigate("/login")
        } else {
            toast.error(response.data.message)
        }
        return response;
    } catch (error) {
        console.log(error);

        toast.error(error?.response?.data?.message)
        return action;
    }
    // add this line to return the response
}

function* displayProfile() {
    const response = yield service.auth.viewProfile();
    const data = yield response.data;

    if (response.status === 200) {
        yield put({ type: Get_Profile_Response, data })
        toast.success(response.message);

    } else {
        toast.error(response.message);

    }
    return response; // add this line to return the response
}
function* resetPassword(action) {
    let payload = {
        new_password: action.data.password,
        confirm_password: action.data.confirmPassword,
        reset_token: action.reset_token
    }
    try {
        const response = yield service.auth.resetPassword(payload);

        if (response.status === 200) {
            toast.success(response.data.message);
            action.navigate("/login")
        } else {
            toast.error(response.data.message)
        }
        return response;
    } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message)
        return action;
    }
    // add this line to return the response
}
function* productSaga() {
    yield takeLatest(Login_User, getLogin)
    yield takeLatest(User_Registration, userRegistration)
    yield takeLatest(Send_Otp_Verification, sendOtpToUser)
    yield takeLatest(Re_Send_Otp_Verification, reSendOtpToUser)
    yield takeLatest(Logout_Admin, getLogout)
    yield takeLatest(Change_Password, changePassword)
    yield takeLatest(Get_Profile, displayProfile)
    yield takeLatest(Reset_Password, resetPassword)
}

export default productSaga;
