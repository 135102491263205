import { BASE_URL } from "../../constants/DefaultValues";
import Dictionary from "../dictionary"
import axios from "axios";
const { eventServices: eventServicesEndpoints } = Dictionary
const eventServices = {
    getEventServicesTypes: async (data, maxData) => {
        return axios.post(BASE_URL + eventServicesEndpoints.getEventServiceTypes(maxData), data);
    },
    getEventDynamicSearch: async (data, serviceCardCount) => {
        return axios.post(BASE_URL + eventServicesEndpoints.getEventDynamicSearch(serviceCardCount), data);
    },
    getDynamicEventServices: async (serviceId) => {
        return axios.get(BASE_URL + eventServicesEndpoints.getDynamicEventServices(serviceId));
    },
}
export default eventServices;
