import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
    IconButton,
} from '@mui/material';
const Index = ({ onScroll }) => {
    return (
        <div
            className="d-flex align-items-center mt-5"
            style={{ cursor: "pointer" }}
        >
            <IconButton disableRipple sx={{ paddingRight: '0px', paddingTop: '12px' }} fontSize={20} onClick={() => onScroll(-50)} >
                <ArrowBackIosIcon />
            </IconButton>
        </div>
    );
};

export default Index
