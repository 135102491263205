import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    Divider,
    Box,
    Button
} from '@mui/material';
import PackagesCards from '../../../../components/PackagesCards'
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import Skeleton from '@mui/material/Skeleton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useNavigate, useParams, useLocation } from 'react-router';
import {
    getEventPackagesServices,
    setEventServicesInputData,
    setEventPackagesIsDelete,
    getEventPackagesDetails,
    setEventPackagesDetails
} from './../../../../redux/PackagesServices/action'

import { detailsStyle } from './style'
import { isEmptyObject } from '../../../../utils/emptyHelpers';
import packagesServices from '../../../../api/services/packagesServices';

const Index = () => {
    const packagesData = useSelector(state => state.EventPackagesSearch.eventInputPackages)
    const packagesEventData = useSelector(state => state.EventPackagesDetails.inputData)
    const packageServices = useSelector(state => state.EventPackagesServices.services?.body?.data)
    const dispatch = useDispatch()
    const { packageId } = useParams()
    const [packegeDataAll, setPackegeDataAll] = useState()
    const navigate = useNavigate()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const [showBackButton, setShowBackButton] = useState(false);

     const demo = async() => {
        if(packegeDataAll!=="undefined"){
            await packagesServices.getEventPackagesDetails(packageId)
            .then((tempPackage) => {
            setPackegeDataAll(tempPackage.data.body.data[0]);
            })
            .catch((error) => {
            console.error('Failed to fetch package details:', error.message);
            });
            }
            else {
                setIsLoading(false)
            }
        }
        
    useEffect(() => {
        if (!location.state) {
            setShowBackButton(true);
          }
        if(!packegeDataAll) {
            demo();
            setPackegeDataAll(packagesEventData)
        }
        dispatch(getEventPackagesDetails(packageId))
        dispatch(getEventPackagesServices(packageId))
        dispatch(setEventPackagesDetails(packageId))
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [packegeDataAll])
    const handleItemOnClick = (service) => {
        dispatch(setEventServicesInputData(service))
        navigate(`/services/view/${service.serviceId}/${Math.floor(Math.random()*898)+101}`,{
            state: { customData: 'Some data' }
        })
        dispatch(setEventPackagesIsDelete(false))
    }

    return !isLoading? (
        <>
        loading...
        </>
    ):(
        <Container maxWidth="md" sx={{ marginBottom: '3rem', maxWidth: '720px', borderLeft: '1px solid #F2F2F2', borderRight: '1px solid #F2F2F2' }}>
            <Grid
                spacing={4} justifyContent="center" alignItems="center"

            >
                <Grid sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }} >

                    <PackagesCards
                        packagesData={[!isEmptyObject(packegeDataAll) ? packegeDataAll : packagesData]}
                        isLoading = {isLoading}
                        toggleImageText={false}
                        isNaved ={showBackButton}
                        // handleOnClickCard={handleOnClickCard}
                        galleryModalHeadingText={"PACKAGES"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div>
                        {packageServices && packageServices.map((service, index) => (
                            <Box key={service.id}>
                                <List
                                    sx={{ padding: '0px', '& .MuiListItem-root:hover': { backgroundColor: service.type === 'H' ? '#F4F4F4 0% 0% no-repeat padding-box' : 'inherit !important' } }}
                                >
                                    <ListItem
                                        button
                                        sx={
                                            service.type === 'H' ? detailsStyle.list.onlyText : {
                                                padding: '26px 28px',
                                            }
                                        }
                                        onClick={() => { handleItemOnClick(service) }} >
                                        {service.type === 'H' ? (
                                            <>
                                                <ListItemText sx={detailsStyle.list.headText} primary={service.serviceTypeName} />
                                            </>
                                        ) : (
                                            <>
                                                <ListItemIcon>
                                                    {service.media.length > 0 ?
                                                        < img
                                                            src={service.media[0].mediaUrl}
                                                            alt={service.serviceTypeName}
                                                            style={{ width: '71px', height: '71px', borderRadius: '8px' }}
                                                        /> : <Skeleton sx={{ width: '71px', height: '115px', borderRadius: '8px' }} animation={false} />
                                                    }
                                                </ListItemIcon>
                                                <ListItemText sx={detailsStyle.list.listServiceText}
                                                    primary={service.serviceTypeName} />
                                                <ListItemIcon >
                                                    <ArrowForwardIosOutlinedIcon sx={{ color: '#bdbdbd' }} />
                                                </ListItemIcon>
                                            </>
                                        )}
                                    </ListItem>
                                    {(index !== packageServices.length - 1 && service.type !== 'H' && packageServices[index + 1]?.type !== 'H') && <Divider />}
                                </List>
                            </Box>
                        ))}
                    </div>
                </Grid>
                <Grid item xs={12} >
                    <Box sx={detailsStyle.buttonContent.container} >
                        {
                            packageServices &&
                            <Button variant="contained"
                                sx={{ ...detailsStyle.buttonContent.button }}
                            >
                                CALL
                            </Button>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Index

