import React from 'react';
import { Divider, Grid, Card, CardContent, Typography, Box, Rating, CardMedia, styled } from '@mui/material';
import { routePaths } from '../../routes/routePaths'
import { useNavigate } from 'react-router';

const CustomHeading = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '190px',
  fontFamily: 'Open Sans, sans-serif',
});

const Index = ({ brandsData }) => {
  const navigate = useNavigate()

  const handleRedirect = (brand) => {
    navigate(`/brands/packages/service/${brand?.accountId}`)
  }


  return (
    <Box
      sx={{ paddingBottom: '4rem' }}
    >
      <Grid sx={{
        display: 'flex', justifyContent: 'space-between',
        marginTop: '1rem',
        fontFamily: 'Open Sans, sans-serif',
        marginBottom: '0.4rem',
      }}>
        {brandsData ? brandsData.length : 0} Results Found
      </Grid>
      <Divider variant="fullWidth" orientation="horizontal" />
      <Grid marginTop='0' container spacing={3}>
        {brandsData instanceof Array && brandsData.length > 0 && brandsData.map((brand) => (
          <Grid onClick={() => { handleRedirect(brand) }} sx={{ paddingTop: '0.5rem !important' }} key={brand.id} item xs={12} sm={6} md={4}>
            <CardContent sx={{ paddingBottom: '0 !important', paddingTop: '0.6rem !important' }}>
              <Card sx={{
                minHeight: '270px',
                display: 'flex', alignItems:
                  'center', justifyContent: 'center',
                boxShadow: '0px 0px 3px #00000029',
                flexDirection: 'column',
                cursor: 'pointer'
              }}>
                <CardMedia component="img"
                  alt={`Product ${brand.companyName}`} image={brand.logoUrl}
                  style={{ width: '225px', maxHeight: '8.375rem', minHeight: '134px', objectFit: 'contain' }}
                />
                <CardContent>
                  <CustomHeading sx={{ fontFamily: 'Open Sans, sans-serif' }} variant="h6" align="center" gutterBottom>
                    {brand.companyName}
                  </CustomHeading>
                  <Typography sx={{ fontFamily: 'Open Sans, sans-serif' }} variant="body2" align="center" color="textSecondary" gutterBottom>
                    {brand.locationName}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Rating name={`brand-rating-${brand.accountId}`} value={brand.rating} precision={0.5} readOnly />
                  </Box>
                </CardContent>
              </Card>
            </CardContent>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Index;
