import { call, put, takeLatest } from "redux-saga/effects";
import {
  SetEventServicesType,
  SetEventServicesTypeIsLoading,
  GetEventServiceTypes,
  Get_Event_Services_Dynamic,
  Set_Event_Services_Dynamic_Is_Loading,
  Set_Dynamic_Event_Services_Is_Loading,
  Set_Event_Services_Search_Dynamic,
  Get_Dynamic_Event_Services,
  // new
  Get_Event_Packages_Details,
  Set_Event_Packages_Details,
  // new
  Set_Dynamic_Event_Services
} from "./actionType";
import service from "../../api/services/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function* getEventServicesType({ payload, maxData }) {

  yield put({ type: SetEventServicesTypeIsLoading, data: true });
  const response = yield service.eventServices.getEventServicesTypes(payload, maxData);
  const data = yield response.data;
  if (response.status === 200 || response.status === 201) {
    yield put({ type: SetEventServicesType, data });
    yield put({ type: SetEventServicesTypeIsLoading, data: false });
  } else {
    yield put({ type: SetEventServicesTypeIsLoading, data: false });
    toast.error(response.message);
  }
  return response;
}

function* getEventDynamicSearch({ payload, serviceCardCount }) {
  try {
    yield put({ type: Set_Event_Services_Dynamic_Is_Loading, data: true });
    const response = yield service.eventServices.getEventDynamicSearch(payload, serviceCardCount);
    const data = response.data;
    if (response.status === 200 || response.status === 201) {
      yield put({ type: Set_Event_Services_Search_Dynamic, data });
      yield put({ type: Set_Event_Services_Dynamic_Is_Loading, data: false });
    } else {
      yield put({ type: Set_Event_Services_Dynamic_Is_Loading, data: false });
      toast.error(response.message);
    }
  } catch (error) {
    yield put({ type: Set_Event_Services_Dynamic_Is_Loading, data: false });
    console.error('Error in getEventDynamicSearch:', error);
    toast.error(error?.response?.data?.head?.message || 'Internal Server Error.', { autoClose: 2000 });
  }
}
// new
function* getEventPackagesDetails({ payload }) {
  try {
    const response = yield service.planEventService.getEventPackagesDetails(payload);
    const data = yield response.data;
    yield put({ type: Set_Event_Packages_Details, data })
  } catch (error) {
    console.log('error', error.message)
    toast.error(error.message, { autoClose: 2000 });
  }
}

function* getDynamicEventServices({ payload }) {

  try {
    yield put({ type: Set_Dynamic_Event_Services_Is_Loading, data: true })
    const response = yield service.eventServices.getDynamicEventServices(payload);
    const data = yield response.data;
    yield put({ type: Set_Dynamic_Event_Services, data })
    yield put({ type: Set_Dynamic_Event_Services_Is_Loading, data: false })
  } catch (error) {
    yield put({ type: Set_Dynamic_Event_Services_Is_Loading, data: false })
    console.error('error', error.message)
    toast.error(error.message, { autoClose: 2000 });
  }
}

function* productSaga() {
  yield takeLatest(Get_Event_Services_Dynamic, getEventDynamicSearch);
  yield takeLatest(GetEventServiceTypes, getEventServicesType);
  yield takeLatest(Get_Dynamic_Event_Services, getDynamicEventServices);
  yield takeLatest(Get_Event_Packages_Details, getEventPackagesDetails);

}

export default productSaga;
