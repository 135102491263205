export const modalStyles = {
    modalContainer: {
        container: { display: 'flex', alignItems: 'center' },
        avatar: { backgroundColor: '#F2F2F2', maxWidth: '100%', cursor: 'pointer' },
        labelText: { fontSize: '20px', textAlign: 'center', width: '100%' ,fontFamily: 'Open Sans, sans-serif'}
    },
    listContainer:{
        container:{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '7px' },
        listItems:{
            // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            padding: "6.5px 14px",
            fontSize: "1rem",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
            color: "rgba(0, 0, 0, 0.87)",
            boxSizing: "border-box",
            position: "relative",
            cursor: "pointer",
            alignItems: "center",
            width: "100%",
            borderRadius: "10px",
            paddingLeft: "43px",
            paddingRight: "14px",
            justifyContent: "space-between",
            backgroundColor: "#EFEFEF"
          }
        
    }
}