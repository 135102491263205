import {
  Set_Event_Type_Input_Data,
  Set_Event_Religion_Input_Data,
  Set_Event_Community_Input_Data,
  Set_Event_Guests_Input_Data,
  Set_Event_Budget_Input_Data,
  Set_Event_Location_Input_Data,
  Set_Religion_Types,
  Set_Religion_Types_Loading,
  Un_Set_Religion_Types_Loading,
  Set_Event_Types_Loading,
  Un_Set_Event_Types_Loading,
  Set_Event_Types,
  Set_Community_Types_Loading,
  Un_Set_Community_Types_Loading,
  Set_Community_Types,
  Set_Event_Settings,
  Set_Event_Packages_Search,
  Set_Event_Packages_Search_Is_Loading,
  Set_Event_Input_Packages_Search,
  Set_Event_Packages_Services,
  Set_Event_Services_Includes,
  Set_Event_Services,
  Set_Event_Services_Is_Loading,
  Set_Event_Packages_Brand_Tabs,
  Set_Event_Is_Filter,
  Reset_Event_Packages_Services_Filter,
  Set_Event_Packages_Services_Filter,
  Reset_Event_Packages_Services_Payload,
  Set_Event_Packages_Services_Payload,
  Set_Event_Packages_Services_IsDelete,
  Set_Event_Packages_Services_Apply_Filter_Data,
  Set_Event_Packages_Service_Edit_Start,
  Set_Packages_Search_Keywords,
  // new
  Set_Event_Packages_Is_Loading,
  Set_Event_Packages_Details,
  Get_Event_Packages_Details,
  // new
} from "./actionType";

import { MAIN_MATCH_CONSTANTS } from '../../constants/MainMatchConstants'
import { staticConstants } from '../../constants/StaticConstants'
import { routePaths } from '../../routes/routePaths'

const { budgetStatic, radiusStatic, guestsStatic } = staticConstants.defaultSettings
const { eventLocation: eventLocationRoute, eventGuests, eventBudget, eventType: eventTypeRoute, packages } = routePaths

const { FILTER_LABELS, TYPES } = MAIN_MATCH_CONSTANTS

const allPackagesServicesInitialState = {
  eventType: {},
  religion: {},
  community: {},
  guests: {},
  budget: {},
  eventLocation: {},
  isFilter: false
}

export const AllPackagesServiceStore = (state = allPackagesServicesInitialState, action) => {
  switch (action.type) {
    case Set_Event_Type_Input_Data:
      return {
        ...state,
        eventType: action.data,
      };
    case Set_Event_Religion_Input_Data:
      return {
        ...state,
        religion: action.data,
      };
    case Set_Event_Community_Input_Data:
      return {
        ...state,
        community: action.data,
      };
    case Set_Event_Guests_Input_Data:
      return {
        ...state,
        guests: action.data,
      };

    case Set_Event_Budget_Input_Data:
      return {
        ...state,
        budget: action.data,
      };
    case Set_Event_Location_Input_Data:
      return {
        ...state,
        eventLocation: action.data,
      };
    case Set_Event_Is_Filter:
      return {
        ...state,
        isFilter: !state.isFilter
      };
    default:
      return state;
  }
};

export const EventSettings = (state = { settings: {} }, action) => {
  switch (action.type) {
    case Set_Event_Settings:
      return {
        ...state,
        settings: action.data,
      };
    default:
      return state;
  }
};

export const EventPackagesSearch = (state = { packages: {}, eventInputPackages: {}, loading: false }, action) => {
  switch (action.type) {
    case Set_Event_Packages_Search:
      return {
        ...state,
        packages: action.data,
      };
    case Set_Event_Input_Packages_Search:
      return {
        ...state,
        eventInputPackages: action.data,
      };
    case Set_Event_Packages_Search_Is_Loading:
      return {
        ...state,
        loading: action.data,
      };
    default:
      return state;
  }
};

export const EventPackagesServices = (state = { services: {} }, action) => {
  switch (action.type) {
    case Set_Event_Packages_Services:
      return {
        ...state,
        services: action.data,
      };
    default:
      return state;
  }
};

export const EventServices = (state = { services: {}, loading: false }, action) => {
  switch (action.type) {
    case Set_Event_Services:
      return {
        ...state,
        services: action.data,
      };
    case Set_Event_Services_Is_Loading:
      return {
        ...state,
        loading: action.data,
      };
    default:
      return state;
  }
};


export const EventServicesIncludes = (state = { servicesIncludesInputData: {} }, action) => {
  switch (action.type) {
    case Set_Event_Services_Includes:
      return {
        ...state,
        servicesIncludesInputData: action.data,
      };
    default:
      return state;
  }
};
export const CommunityData = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case Set_Community_Types_Loading:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case Un_Set_Community_Types_Loading:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case Set_Community_Types:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    default:
      return state;
  }
};


export const ReligionData = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case Set_Religion_Types_Loading:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case Un_Set_Religion_Types_Loading:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case Set_Religion_Types:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    default:
      return state;
  }
};


export const EventTypesData = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case Set_Event_Types_Loading:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case Un_Set_Event_Types_Loading:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case Set_Event_Types:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    default:
      return state;
  }
};

const isLocationExist = JSON.parse(localStorage.getItem('location'))
const initialFilterConstants = [
  {
    label: FILTER_LABELS.LOCATIONS,
    value: isLocationExist?.formatted_address,
    id: isLocationExist?.place_id ? isLocationExist?.place_id : 14548,
    path: eventLocationRoute,
    delete: false,
    pathState: { packagesPath: packages },
    defaultDeleteName: "default",
  },
  {
    label: FILTER_LABELS.BRANDS,
    value: "",
    id: 201514,
    path: packages,
    delete: false,
    keyword: ""
  },
  {
    label: FILTER_LABELS.NO_OF_GUEST,
    value: 500,
    id: 210,
    path: eventGuests,
    maxGuests: 1,
    delete: false,
    pathState: { type: TYPES.NO_OF_GUEST, packagesPath: packages }
  },
  {
    label: FILTER_LABELS.BUDGET,
    value: 100000,
    id: 114,
    path: routePaths.eventBudget,
    maxPrice: 100000,
    delete: false
  },
  {
    label: FILTER_LABELS.EVENT_TYPE,
    path: eventTypeRoute,
    pathState: { type: TYPES.EVENT_TYPE, reBackPagePath: packages },
    value: "Any Event",
    id: 74,
    eventTypeId: 74,
    delete: false
  },
  {
    label: FILTER_LABELS.COMMUNITY_AND_CULTURE,
    value: "Any Community",
    id: 1602,
    path: eventTypeRoute,
    pathState: { type: TYPES.COMMUNITY, reBackPagePath: packages },
    communityId: 1602,
    delete: false
  },
  {
    label: FILTER_LABELS.RELIGION,
    value: "Any Religion",
    id: 8,
    path: eventTypeRoute,
    pathState: { type: TYPES.RELIGION, reBackPagePath: packages },
    religionId: 8
  },
]

export const FilterEventPackagesStore = (state = {
  filterData: initialFilterConstants,
  applyFilterData: initialFilterConstants,
  resetFilterData: initialFilterConstants,
  resetApiPayload: {},
  isDelete: false,
  isFilter: false,
  isEditStart: false,
  activeBrandTab: MAIN_MATCH_CONSTANTS.ONE
}, action) => {
  switch (action.type) {
    case Reset_Event_Packages_Services_Filter:
      return {
        ...state,
        resetFilterData: action.data,
      };
    case Set_Event_Packages_Services_Filter:
      return {
        ...state,
        filterData: action.data,
      };
    case Set_Event_Packages_Services_Apply_Filter_Data:
      return {
        ...state,
        applyFilterData: action.data,
      };
    case Reset_Event_Packages_Services_Payload:
      return {
        ...state,
        resetApiPayload: action.data,
      };
    case Set_Event_Packages_Services_Payload:
      return {
        ...state,
        apiPayload: action.data,
      };
    case Set_Event_Packages_Services_IsDelete:
      return {
        ...state,
        isDelete: action.data,
      };
    case Set_Event_Packages_Service_Edit_Start:
      return {
        ...state,
        isEditStart: action.data,
      };
    case Set_Event_Packages_Brand_Tabs:
      return {
        ...state,
        activeBrandTab: action.data,
      };
    default:
      return state;
  }
};

export const PackagesSearchKeywords = (state = { keywords: {} }, action) => {
  switch (action.type) {
    case Set_Packages_Search_Keywords:
      return {
        ...state,
        keywords: action.data,
      };
    default:
      return state;
  }
};
// new
export const EventPackagesDetails = (state = { inputData: {}, loading: false }, action) => {
  switch (action.type) {
    case Set_Event_Packages_Details:
      return {
        ...state,
        inputData: action.data,
      };
    case Set_Event_Packages_Is_Loading:
        return {
          ...state,
          loading: action.data,
        };
    default:
      return state;
  }
};
// new