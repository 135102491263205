import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid,
} from '@mui/material';
import PackagesCards from '../../../../components/PackagesCards'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import LoadingCardSkeleton from '../../../../components/LoadingCardSkeleton'
import { getEventPackagesDetails, getEventServices, setEventPackagesDetails, setEventServices } from './../../../../redux/PackagesServices/action'
const Index = () => {
    const packageServices = useSelector(state => state.EventServices?.services?.body?.data || [])
    const includeLoading = useSelector(state => state.EventServices.loading)
    const dispatch = useDispatch()
    const { serviceId } = useParams()
    const { packageId } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
  
  const [showBackButton, setShowBackButton] = useState(false);

    useEffect(() => {
        if (location.state !== "null") {
            setShowBackButton(true);
          }
        dispatch(getEventServices(serviceId))
        return () => dispatch(setEventServices({}))
    }, [])
    
    useEffect(() => {
        
        dispatch(getEventPackagesDetails(packageId))
        return () => dispatch(setEventPackagesDetails({}))
    }, [])
    return (
        <Container maxWidth="md" sx={{ marginBottom: '4rem', maxWidth: '720px', borderLeft: '1px solid #F2F2F2', borderRight: '1px solid #F2F2F2', paddingTop: '1rem' }}>
            <Grid
                spacing={4} justifyContent="center" alignItems="center"
            >
                <Grid sx={{ marginBottom: '6rem' }} >
                    {
                        includeLoading ? <LoadingCardSkeleton /> :
                            <>
                                {(Object.keys(packageServices)?.length !== 0) && <PackagesCards
                                    packagesData={[packageServices]}
                                    isLoading={false}
                                    toggleImageText={false}
                                    type={'includes'}
                                    isNaved ={showBackButton}
                                    galleryModalHeadingText={'PACKAGES'}
                                />}
                            </>
                    }

                </Grid>
            </Grid>
        </Container>
    )
}

export default Index

