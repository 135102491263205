import React, { useState } from "react";
import { Box , Typography } from "@mui/material";
import noData from '../../assets/images/no-data.png'

const Index = () => {
    return (
        <div>
         <Box textAlign='center' p='50px' height="100%" width='100%'>
            <Box component='img' src={noData} sx={{ height: '50px', width: '50px' }} />
            <Typography textAlign='center' pt='1rem'>No data found</Typography>
          </Box>
        </div>

    )
}

export default Index
