import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Grid, Box } from '@mui/material';
import { verificationStyles } from './style'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import TimerComponent from '../../../components/TimerComponent'
import { sendOtpVerification, reSendOtpVerification } from '../../../redux/auth/action'
import { useDispatch } from 'react-redux';

const Index = () => {
  const allAuthDetails = useSelector(state => state.AllLoginDetails)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [value, setValue] = useState('');
  const isValueValid = value.length === 4
  const [error, setError] = useState(false);

  const formatDesignRequestBody = () => {
    const { country, countryDialCode, mobileNo } = allAuthDetails.loginInput;
    const isUserLoggedIn = isEmptyObject(allAuthDetails.loginInput);
    const { registrationRequestBody } = allAuthDetails
    let cleanedNumber = registrationRequestBody.mobileNo ? registrationRequestBody.mobileNo.replace(/^(\+?\d{1,})(\s)?/, "") : null
    const requestBody = {
      country: !isUserLoggedIn ? country : registrationRequestBody.country,
      countryDialCode: !isUserLoggedIn ? countryDialCode : registrationRequestBody.countryDialCode,
      mobileNo: !isUserLoggedIn ? mobileNo : cleanedNumber,
      userId: !isUserLoggedIn ? allAuthDetails.loginResponse?.body?.userId : allAuthDetails.registrationRequestBody.userId,
      OTP: value
    };
    return requestBody

  }
  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (!/^\d*$/.test(inputValue)) {
      setError(true);
    } else {
      setError(false);
      const newValue = inputValue.slice(0, 4);
      setValue(newValue.replace(/\D/g, ''));
    }
  };

  const handleResendOtp = (isResend) => {
    const { country, countryDialCode, mobileNo } = formatDesignRequestBody()
    const payload = {
      country,
      countryDialCode,
      mobileNo
    }
    if (isResend) {
      dispatch(reSendOtpVerification(payload, navigate))
    }
  }

  const handleVerification = () => {
    dispatch(sendOtpVerification(formatDesignRequestBody(), navigate))
  }


  return (
    <Grid>
      <Container sx={verificationStyles.container}>
        <Grid style={{ width: '100%', marginTop: '3rem' }}>
          <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="h5" sx={{ ...verificationStyles.verificationContainer.labelText, margin: '1rem' }}>
              VERIFICATION
            </Typography>
          </Grid>
          <Grid style={{ textAlign: 'center', marginBottom: '20px' }}>
            <Typography sx={{ fontFamily: 'Open Sans, sans-serif' }} variant="body1">
              We have sent you Verification code to
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Open Sans, sans-serif' }}> {allAuthDetails?.loginInput?.mobileNo ? `${allAuthDetails.loginInput.countryDialCode}  ${allAuthDetails?.loginInput?.mobileNo}` : 'No data found.'}</Typography>
          </Grid>
          <Grid sx={verificationStyles.inputsContainer}>
            <Grid
              item
              xs={12}
              sm={8}
              md={4}
              style={{ borderRadius: '25px', marginBottom: '0rem', width: '312px' }}
            >
              <Typography variant="body1" align="center" style={{ marginTop: '2rem', color: '#949494' ,fontFamily: 'Open Sans, sans-serif'}}>ENTER VERIFICATION CODE</Typography>
              <TextField
                fullWidth

                variant="outlined"
                sx={{
                  "& fieldset": { border: 'none' },
                }}
                type='text'
                value={value}
                error={error}
                helperText={error ? "Please enter only numbers" : null}
                InputProps={{
                  style: {
                    borderRadius: '25px',
                    backgroundColor: '#f2f2f2',
                    marginTop: '1rem',
                    textAlign: 'center',
                    height: '47px',
                    fontFamily: 'Open Sans, sans-serif'
                  },
                  inputProps: {
                    style: {
                      textAlign: 'center',
                    }
                  }
                }
                }
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '1rem', minHeight: '47px'
            }}
          >
            <Grid item xs={12}>
              <TimerComponent
                time={60}
                handleClick={handleResendOtp}
              />
              <Box sx={verificationStyles.buttonContent.container}>
                <Button variant="contained" disabled={!isValueValid} onClick={handleVerification} sx={{ ...verificationStyles.buttonContent.button, marginTop: '24px' }}>
                  CONTINUE
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Index;