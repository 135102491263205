import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemText,
  Box,
  Avatar,
  Typography,
} from "@mui/material";
import { EventLocationStyles } from "../../screens/App/PackagesServicesScreens/EventLocation/style";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { modalStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import Skeleton from '@mui/material/Skeleton';
import SearchField from '../../components/SearchField'
const Index = ({ type, openDialog, onClickModal, topHeading,
  listingDetails, listDataType, handleOnchangeSearch, searchPlaceHolder,
  onClickOnlist, isReligionLoading, isCommunityLoading
}) => {

  return (
    <>
      <Dialog open={openDialog} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }} onClose={onClickModal} fullWidth>
        <DialogTitle>
          <Box sx={modalStyles.modalContainer.container}>
            <Typography variant="h5" sx={modalStyles.modalContainer.labelText}>
              {topHeading}
            </Typography>
            <Avatar sx={modalStyles.modalContainer.avatar}>
              <CloseIcon onClick={onClickModal} sx={{ color: "black" }} />
            </Avatar>
          </Box>
        </DialogTitle>

        <DialogContent >
          <SearchField
            placeholder={searchPlaceHolder}
            handleOnchangeSearch={handleOnchangeSearch}
          />
          <List
            sx={modalStyles.listContainer.container}
          >
            {
              listingDetails instanceof Array && listingDetails.length > 0 ?
                listingDetails.map((item, index) => (
                  <ListItem
                    sx={modalStyles.listContainer.listItems}
                    onClick={() => onClickOnlist(item)}
                    key={index}>
                    <ListItemText sx={{ fontFamily: 'Open Sans, sans-serif' }} primary={
                      type === "country" ?
                        (<>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box component="span">
                              {item.flag}
                              <Box component="span" sx={{ marginLeft: '1rem' }}>
                                {item[listDataType]}
                              </Box>
                            </Box>
                            <Box component="span">
                              {` (${item.dial_code})`}
                            </Box>
                          </Box>
                        </>) : item[listDataType]
                    } />
                  </ListItem>
                )) : isCommunityLoading || isReligionLoading ?
                  <>
                    <Skeleton variant="text" sx={{ fontSize: '2.5rem', borderRadius: '10px' }} />
                    <Skeleton variant="text" sx={{ fontSize: '2.5rem', borderRadius: '10px' }} />
                  </>
                  :
                  <ListItem>
                    <ListItemText primary={"No data found."} />
                  </ListItem>
            }
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Index;
