import React from 'react';
import { Grid, Card, Typography } from '@mui/material';
import { Images } from '../../assets/images'
import homeBackground from '../../assets/images/homeBackground.png'
const Index = (props) => {

    const { text, logo, path, onClick } = props
    const handleGetPath = () => {
        onClick(path)
    }
    return (
        <Grid item md={6} sm={6} onClick={handleGetPath} >
            <Card sx={{
                display: 'flex',
                justifyContent: 'space-around',
                borderRadius: 3,
                height: 180,
                backgroundImage: `url(${homeBackground})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                opacity: 1,
                cursor: 'pointer'
            }}>
                <Typography variant="body1" sx={{ fontFamily: 'Open Sans, sans-serif', color: 'white', paddingTop: '25px' }}>
                    {logo && <img src={logo} alt="Logo" style={{ width: '100%', height: '100' }} />}
                </Typography>
            </Card>
            <Typography variant="body2" sx={{fontFamily: 'Open Sans, sans-serif', textAlign: 'center', mt: 3 , fontSize:'1.1rem'}}>
                {text}
            </Typography>
        </Grid>
    );
}

export default Index;