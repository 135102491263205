export const BrandPackagesServiceStyle = {
    tabBox: {
        container: { width: '100%' },
        tabs: {
            display: 'flex',
            justifyContent: 'space-between',
            '& .MuiTabs-indicator': {
                borderBottom: '6px solid #363636',
                "@media (max-width: 600px)": {
                    borderBottom: '5px solid #363636',
                }
            },
            '& .MuiTabs-scroller': {
                width: '100%',
            },
            '& .MuiTab-root': {
                flexGrow: 1,
                maxWidth: '50%',
                flexBasis: 0,
                textTransform: 'none',
                fontFamily: 'Open Sans, sans-serif',
            }
        }
    },
}