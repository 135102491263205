import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
const Index = ({ time, handleClick }) => {
  const [timer, setTimer] = useState(time);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setCanResend(true);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const handleResendClick = () => {
    handleClick(true)
    setTimer(time);
    setCanResend(false);
  };

  return (
    <Typography variant="body1" component="div" sx={{ fontFamily: 'Open Sans, sans-serif', display: 'inline-block', fontSize: '16px', color: '#000' }}>
      {!canResend ? (
        <>
          {`Didn't receive the code? Resend in ${timer} seconds`}
        </>
      ) : (
        <>
          Didn't receive the code?{' '}
          <Typography component="span" onClick={handleResendClick} sx={{ color: 'blue', cursor: 'pointer', fontFamily: 'Open Sans, sans-serif' }}>Resend</Typography> now
        </>
      )}
    </Typography>
  );
};

export default Index;
