export const ServicesStyles = {
    search: {
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#F2F2F2',
        borderRadius: '10px',
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Change to the color you want or 'transparent'
        },
      },
    },
    wembleyBox: {
      container: {
        height: '80px',
        background: '#F2F2F2',
        borderRadius: '11px',
        opacity: '1',
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap'
      },
      text: {
        paddingTop: '26px',
        lineHeight: '23px',
        letterSpacing: '0px',
        color: '#161616',
        opacity: '1',
        fontFamily: 'Open Sans, sans-serif'
      }
    },
    withInBox: {
      slider: {
        color: '#E8A803', height: '1px',
        '& .MuiSlider-thumb': {
          borderRadius: '50%',
          backgroundColor: 'white',
        },
      }
    },
    buttonContent: {
      container: { display: 'flex', justifyContent: 'center' },
      button: {
        borderRadius: '20px', display: 'inline-block', textDecoration: 'none',
        minWidth: '337px',
        height: '47px',
        fontFamily: 'Open Sans, sans-serif',
        backgroundColor: '#EEB000',
        '&:hover': {
          backgroundColor: '#efb719', 
        },
        color:'#fff'
      }
    }
  }