export const EventPackagesStyles = {
  eventPackagesContainer: {
    container: { display: 'flex', alignItems: 'center' },
    avatar: { backgroundColor: '#F2F2F2', maxWidth: '100%', cursor: 'pointer' },
    labelText: { fontSize: '20px', textAlign: 'center', width: '100%', fontFamily: 'Open Sans, sans-serif', }
  },
  stepper: {
    height: '10px',
    borderRadius: '27px',
    backgroundColor: '#DCDCDC',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#EEB000',
    },
  },
  search: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F2F2F2',
      borderRadius: '10px',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
  },
  wembleyBox: {
    container: {
      height: '80px',
      background: '#F2F2F2',
      borderRadius: '11px',
      opacity: '1',
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap'
    },
    text: {
      paddingTop: '26px',
      fontFamily: 'Open Sans, sans-serif',
      lineHeight: '23px',
      letterSpacing: '0px',
      color: '#161616',
      opacity: '1',
    }
  },

  buttonContent: {
    container: { display: 'flex', justifyContent: 'center' },
    button: {
      borderRadius: '20px', display: 'inline-block', textDecoration: 'none',
      minWidth: '337px',
      height: '47px',
      backgroundColor: '#EEB000'
    }
  },
  listItems: {
    container: {
      paddingTop: '0.8rem !important',
    },
    paper: {
      boxShadow: 'none',
      display: 'flex',
      color: 'black',
      flexWrap: 'nowrap',
      listStyle: 'none',
      p: 0.5,
      m: 0,
      overflowX: { xs: 'auto', md: 'hidden', sx: "hidden" },
      overflowY: 'hidden',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      paddingBottom: '0.8rem'
    },
    icon: { color: 'white', width: '1.1rem', height: '1.1rem' },
    badges: {
      backgroundColor: '#363536',
      color: 'white',
      fontFamily: 'Open Sans, sans-serif',
      maxWidth: '180px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      transition: 'background-color 0.3s, color 0.3s',
      '&:hover': {
        backgroundColor: '#151515',
      },
    },
    deletedBadges: {
      border: '1px solid #ABABAB',
      backgroundColor: '#FFFFFF',
      color: '#343333',
      fontFamily: 'Open Sans, sans-serif',
      maxWidth: '180px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      fontWeight: 600,
      transition: 'background-color 0.3s, color 0.3s',
      '&:hover': {
        backgroundColor: '#E5E5E5',
      },
      '& .MuiChip-deleteIcon': {
        color: 'black',
      }
    },
    badgesBox: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#363536',
        color: 'white',
        fontFamily: 'Open Sans, sans-serif',
      },
    },
  },
  tabBox: {
    container: { width: '100%' },
    tabs: {
      display: 'flex',
      justifyContent: 'space-between',
      '& .MuiTabs-indicator': {
        borderBottom: '6px solid #363636',
        "@media (max-width: 600px)": {
          borderBottom: '3px solid #363636',
        }
      },
      '& .MuiTabs-scroller': {
        width: '100%',
      },
      '& .MuiTab-root': {
        flexGrow: 1,
        maxWidth: '50%',
        flexBasis: 0,
        textTransform: 'none',
        fontFamily: 'Open Sans, sans-serif',
      },
      '& .MuiButtonBase-root': {
        padding: '16px 16px'
      }
    }
  },
  resultFound: {
    container: {
      display: 'flex', justifyContent: 'space-between',
      marginTop: '1rem',
      fontFamily: 'Open Sans, sans-serif',
      marginBottom: '0.4rem',
    },

  }
}