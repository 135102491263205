import React from 'react'
import { Navigate } from 'react-router'
import privatePages from '../screens/App'
import publicPage from '../screens/Auth'
import { routePaths } from './routePaths'
const {
    Home,
    EventLocation,
    EventType, EventGuests,
    EventBudget, PackagesScreen,
    PackagesDetails,
    PackageServicesIncludes,
    SelectServices,
    ServiceBudget,
    DynamicEventServiceScreen,
    EventServicesIncludes,
    BrandPackagesService
} = privatePages
const {
    eventLocation, eventType,
    eventBudget, eventGuests, packagesDetails, home,
    registration, login, verification,
    packages, packageServicesIncludes,
    servicesBudget, servicesSelect,
    dynamicEventServiceRoute,
    eventServicesIncludesRoute, brandPackagesService
} = routePaths;
const { Login, Registration, Verification } = publicPage;
// const privateRoutes = [
//     { path: "/", exact: true, component: <Navigate to="/home" />, },
//     { path: home, component: <Home /> },
//     { path: eventLocation, component: <EventLocation /> },
//     { path: eventType, component: <EventType /> },
//     { path: eventGuests, component: <EventGuests /> },
//     { path: eventBudget, component: <EventBudget /> },
//     { path: packages, component: <PackagesScreen /> },
//     { path: packagesDetails, component: <PackagesDetails /> },
//     { path: packageServicesIncludes, component: <PackageServicesIncludes /> },
//     { path: servicesSelect, component: <SelectServices /> },
//     { path: servicesBudget, component: <ServiceBudget /> },
//     { path: dynamicEventServiceRoute, component: <DynamicEventServiceScreen /> },
//     { path: eventServicesIncludesRoute, component: <EventServicesIncludes /> },
//     { path: brandPackagesService, component: <BrandPackagesService /> },
// ]

const publicRoutes = [
    { path: login, component: <Login /> },
    { path: registration, component: <Registration /> },
    { path: verification, component: <Verification /> },
    { path: "/", exact: true, component: <Navigate to="/home" />, },
    { path: home, component: <Home /> },
    { path: eventLocation, component: <EventLocation /> },
    { path: eventType, component: <EventType /> },
    { path: eventGuests, component: <EventGuests /> },
    { path: eventBudget, component: <EventBudget /> },
    { path: packages, component: <PackagesScreen /> },
    { path: packagesDetails, component: <PackagesDetails /> },
    { path: packageServicesIncludes, component: <PackageServicesIncludes /> },
    { path: servicesSelect, component: <SelectServices /> },
    { path: servicesBudget, component: <ServiceBudget /> },
    { path: dynamicEventServiceRoute, component: <DynamicEventServiceScreen /> },
    { path: eventServicesIncludesRoute, component: <EventServicesIncludes /> },
    { path: brandPackagesService, component: <BrandPackagesService /> },
]
export { publicRoutes }
// privateRoutes,