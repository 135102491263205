import authService from './auth'
import packagesServices from './packagesServices'
import eventServices from './eventServices'
import brandPackagesServices from './brandPackagesServices'
const service = {
    auth: authService,
    planEventService: packagesServices,
    eventServices: eventServices,
    brandPackagesServices:brandPackagesServices
}
export default service