import {
  Set_Event_Type_Input_Data,
  Set_Event_Religion_Input_Data,
  Set_Event_Community_Input_Data,
  Set_Event_Guests_Input_Data,
  Set_Event_Budget_Input_Data,
  Set_Event_Location_Input_Data,
  Set_Religion_Types_Loading,
  Set_Religion_Types,
  Un_Set_Religion_Types_Loading,
  Get_Religion_Type,
  Set_Event_Types_Loading,
  Un_Set_Event_Types_Loading,
  Set_Event_Types,
  Get_Event_Types,
  Set_Community_Types_Loading,
  Un_Set_Community_Types_Loading,
  Set_Community_Types,
  Get_Community_Type,
  Set_Event_Settings,
  Get_Event_Settings,
  Get_Event_Packages_Search,
  Set_Event_Packages_Details,
  Get_Event_Packages_Details,
  Set_Event_Input_Packages_Search,
  Set_Event_Packages_Services,
  Get_Event_Packages_Services,
  Set_Event_Services_Includes,
  Set_Event_Services,
  Get_Event_Services,
  Set_Event_Is_Filter,
  Reset_Event_Packages_Services_Filter,
  Set_Event_Packages_Services_Filter,
  Reset_Event_Packages_Services_Payload,
  Set_Event_Packages_Services_Payload,
  Set_Event_Packages_Services_IsDelete,
  Set_Event_Packages_Services_Apply_Filter_Data,
  Set_Event_Packages_Service_Edit_Start,
  Get_Packages_Search_Keywords,
  Set_Event_Packages_Brand_Tabs
} from "./actionType";

// Plan Event User Input Details actions
export const setEventTypeInputData = (data) => ({
  type: Set_Event_Type_Input_Data,
  data,
});

export const setEventSettings = (data) => ({
  type: Set_Event_Settings,
  data,
});

export const getEventSettings = (payload) => ({
  type: Get_Event_Settings
});


export const setEventReligionInputData = (data) => ({
  type: Set_Event_Religion_Input_Data,
  data,
});

export const setEventCommunityInputData = (data) => ({
  type: Set_Event_Community_Input_Data,
  data,
});

export const setEventGuestsInputData = (data) => ({
  type: Set_Event_Guests_Input_Data,
  data,
});

export const setEventBudgetInputData = (data) => ({
  type: Set_Event_Budget_Input_Data,
  data,
});

export const setEventLocationInputData = (data) => ({
  type: Set_Event_Location_Input_Data,
  data,
});

// Community actions
export const setCommunityLoadingAction = () => ({
  type: Set_Community_Types_Loading,
});

export const unSetCommunityLoadingAction = () => ({
  type: Un_Set_Community_Types_Loading,
});

export const setCommunityDataAction = (data) => ({
  type: Set_Community_Types,
  data,
});

export const getCommunityTypeAction = (payload, setIsCommunityLoading) => {
  return {
    type: Get_Community_Type,
    payload,
    setIsCommunityLoading
  }
};


// Religion actions
export const setReligionLoadingAction = () => ({
  type: Set_Religion_Types_Loading,
});

export const unSetReligionLoadingAction = () => ({
  type: Un_Set_Religion_Types_Loading,
});

export const getReligionTypeAction = (payload, setIsReligionLoading) => {
  return {
    type: Get_Religion_Type,
    payload,
    setIsReligionLoading
  }
};

export const setReligionDataAction = (data) => ({
  type: Set_Religion_Types,
  data,
});


export const setEventTypeLoadingAction = () => ({
  type: Set_Event_Types_Loading,
});

export const unSetEventTypeLoadingAction = () => ({
  type: Un_Set_Event_Types_Loading,
});

export const getEventTypeAction = (payload, eventTypeCount) => {
  return {
    type: Get_Event_Types,
    payload,
    eventTypeCount
  }
}

export const setEventTypeDataAction = (data) => ({
  type: Set_Event_Types,
  data,
});

export const getEventPackagesSearch = (payload, packagesCardCount) => ({
  type: Get_Event_Packages_Search,
  payload,
  packagesCardCount
});

export const getEventPackagesServices = (payload) => ({
  type: Get_Event_Packages_Services,
  payload
});

export const setEventPackageServices = (data) => ({
  type: Set_Event_Packages_Services,
  data,
});

export const setEventSearchInputData = (data) => ({
  type: Set_Event_Input_Packages_Search,
  data,
});

export const setEventServicesInputData = (data) => ({
  type: Set_Event_Services_Includes,
  data,
});

export const setEventServices = (payload) => ({
  type: Set_Event_Services,
  payload
});

export const getEventServices = (payload) => ({
  type: Get_Event_Services,
  payload,
});

// new



export const setEventPackagesDetails = (payload) => ({
  type: Set_Event_Packages_Details,
  payload,
});

export const getEventPackagesDetails = (payload) => ({
  type: Get_Event_Packages_Details,
  payload
});




// 
export const setToggleIsFilter = (data) => ({
  type: Set_Event_Is_Filter,
  data,
});




export const resetEventPackagesFilter = (data) => ({
  type: Reset_Event_Packages_Services_Filter,
  data,
});

export const setEventPackagesFilter = (data) => ({
  type: Set_Event_Packages_Services_Filter,
  data,
});

export const resetEventPackagesPayload = (data) => ({
  type: Reset_Event_Packages_Services_Payload,
  data,
});

export const setEventPackagesPayload = (data) => ({
  type: Set_Event_Packages_Services_Payload,
  data,
});

export const setEventPackagesIsDelete = (data) => {
  return {
    type: Set_Event_Packages_Services_IsDelete,
    data,
  }
};

export const setEventPackagesFilterApplyFilterData = (data) => ({
  type: Set_Event_Packages_Services_Apply_Filter_Data,
  data,
});

export const setEventPackagesServiceEdit_Start = (data) => ({
  type: Set_Event_Packages_Service_Edit_Start,
  data,
});

export const getPackagesSearchKeywords = (payload) => ({
  type: Get_Packages_Search_Keywords,
  payload,
});

export const setEventPackagesBrandTabs = (data) => ({
  type: Set_Event_Packages_Brand_Tabs,
  data,
});


