import { BASE_URL } from "../../constants/DefaultValues";
import Dictionary from "../dictionary"
import axios from "axios";
const { packagesServicesEvent } = Dictionary
const packagesServices = {
    getEventSettings: async (data) => {
        return axios.get(BASE_URL + packagesServicesEvent.getEventSettings(), data);
    },
    getEventCommunity: async (data) => {
        return axios.post(BASE_URL + packagesServicesEvent.getCommunityTypes(), data);
    },
    getEventReligion: async (data) => {
        try {
            const response = await axios.post(BASE_URL + packagesServicesEvent.getEventReligion(), data);
            return response.data; // Return the data part of the response
          } catch (error) {
            console.error('Error fetching data:', error.message);
            throw error; // Rethrow the error to handle it elsewhere if needed
          }
    },
    getEventTypes: async (data,eventTypeCount) => {
        return axios.post(BASE_URL + packagesServicesEvent.getEventTypes(eventTypeCount), data);
    },
    getEventPackagesSearch: async (data, packagesCardCount) => {
        return axios.post(BASE_URL + packagesServicesEvent.getEventPackagesSearch(packagesCardCount), data);
    },
    getEventPackagesServices: async (packageId) => {
        return axios.get(BASE_URL + packagesServicesEvent.getEventPackagesServices(packageId));
    },
    // new
    getEventPackagesDetails: async (packageId) => {
        return axios.get(BASE_URL + packagesServicesEvent.getEventPackagesDetails(packageId));
    },
    // ṇew
    getEventServices: async (serviceId) => {
        return axios.get(BASE_URL + packagesServicesEvent.getEventServices(serviceId));
    },
    getPackagesSearchKeywords: async (data) => {
        return axios.post(BASE_URL + packagesServicesEvent.getPackagesSearchKeyword(), data);
    }
}
export default packagesServices;
