import * as React from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { routePaths } from '../routes/routePaths';

export const BottomBarConstants = [
    { label: 'Home', icon: <HomeOutlinedIcon />, path: routePaths.home },
    { label: 'My Events', icon: <AcUnitOutlinedIcon />, path: null },
    { label: 'Shop', icon: <ShoppingBagOutlinedIcon />, path: null },
    { label: 'Messages', icon: <ChatBubbleOutlineOutlinedIcon />, path: null },
    {
        label: 'Settings', icon: <SettingsIcon sx={{
            color: 'currentColor',
            '& path': {
                fill: 'none',
                stroke: 'currentColor',
                strokeWidth: 1.5
            }
        }} />, path: null,
    }
];
