import { all, fork } from "redux-saga/effects";

import login from "./auth/saga";
import PlanEvents from "./PackagesServices/saga";
import EventServicesSagaStore from './EventServices/saga'
import CommonBrandPackages from './CommonBrandPackages/saga'

export default function* rootSaga() {
  yield all([
    fork(login),
    fork(PlanEvents),
    fork(EventServicesSagaStore),
    fork(CommonBrandPackages)
  ])
}
