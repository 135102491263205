import React from 'react'
import { Grid, LinearProgress } from '@mui/material'
import { ProgressBarStyle } from './style'
const Index = ({ value , style}) => {
    return (
        <Grid item xs={12}>
            <LinearProgress variant="determinate" sx={{...ProgressBarStyle.stepper , ...style}} value={value} />
        </Grid>
    )
}
export default Index