import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Container, Grid, Typography, Button, Slider, Box } from '@mui/material';
import { Images } from '../../../../assets/images'
import SearchIcon from '@mui/icons-material/Search';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { EventLocationStyles } from './style'
import component from '../../../../components'
import Autocomplete from 'react-google-autocomplete'
import { useNavigate, useLocation } from 'react-router';
import { setEventLocationInputData, setEventPackagesIsDelete, setEventPackagesFilter, setEventPackagesFilterApplyFilterData } from '../../../../redux/PackagesServices/action'
import { useDispatch, useSelector } from 'react-redux';
import { routePaths } from '../../../../routes/routePaths'
import { toast } from 'react-toastify'
import { MAIN_MATCH_CONSTANTS } from '../../../../constants/MainMatchConstants'
import { isEmptyObject } from '../../../../utils/emptyHelpers';
import {
  setEventServicesIsDelete,
  setEventServicesPayload,
  setEventServicesApplyFilterData,
  setEventServicesFilter
} from '../../../../redux/EventServices/action'

const { TopBar, ProgressBar } = component
const Index = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const apiKey = process.env.REACT_APP_GOOGLE_AUTO_API_KEY;
  const allEventData = useSelector((state) => state.AllPackagesServiceStore);
  const settingsData = useSelector(state => state.EventSettings.settings?.settings?.radius || null)
  const [eventLocation, setEventLocation] = useState(allEventData.eventLocation.formatted_address || "")
  const allPackagesFilterData = useSelector((state) => state.FilterEventPackagesStore)
  const allFilterEventServicesStore = useSelector((state) => state.FilterEventServicesStore)
  const [rangeValue, setRangeValue] = useState(
    allEventData.eventLocation?.sliderValue ?
      allEventData.eventLocation?.sliderValue : 50
  )
  const isLocationExist = JSON.parse(localStorage.getItem('location'))
  const location = useLocation()
  const { state } = location
  const { FILTER_LABELS, NOT_SURE, OTHER } = MAIN_MATCH_CONSTANTS

  const handlePlaceSelected = (place) => {
    // const { lat, lng } = place?.geometry?.location;
    setEventLocation(place?.formatted_address)
    let locationObj = {
      ...place,
      longitude: place?.geometry?.location?.lng(),
      latitude: place?.geometry?.location?.lat(),
      sliderValue: rangeValue
    }
    localStorage.setItem('location', JSON.stringify(locationObj))
    dispatch(setEventLocationInputData(locationObj))
  }
  const handleSliderChange = (_, range) => {
    let rangeModify = {
      ...allEventData.eventLocation,
      sliderValue: range
    }
    dispatch(setEventLocationInputData(rangeModify))
    setRangeValue(range)
  };
  const handleSubmit = () => {
    const locationObj = {
      ...allEventData.eventLocation,
      sliderValue: rangeValue
    }
    localStorage.setItem('location', JSON.stringify(locationObj))
    dispatch(setEventLocationInputData(locationObj))
    if (isLocationExist && (!isEmptyObject(isLocationExist) && Object.keys(isLocationExist)?.length > 2)) {
      editFilterData(
        allEventData.isFilter ? allPackagesFilterData.applyFilterData : allPackagesFilterData.filterData,
        FILTER_LABELS.LOCATIONS, isLocationExist
      )
      if (allEventData.isFilter || routePaths.packages === state?.packagesPath) { //redirect when filter present into packages screen
        navigate(routePaths.packages)
        dispatch(setEventPackagesIsDelete(false))
      }
      else if (allFilterEventServicesStore.isFilter || state?.dynamicEventServiceRoute === routePaths?.dynamicEventServiceRoute) { //redirect when filter present into service card screen
        dispatch(setEventServicesIsDelete(false))
        editFilterData(
          allFilterEventServicesStore.isFilter ? allFilterEventServicesStore.applyFilterData : allFilterEventServicesStore.filterData,
          FILTER_LABELS.LOCATIONS, isLocationExist
        )
        dispatch(setEventServicesPayload(
          {
            ...allFilterEventServicesStore.apiPayload,
            location: {
              lat: locationObj?.latitude,
              lng: locationObj?.longitude,
            },
          }
        ))
        navigate(routePaths.dynamicEventServiceRoute)
      }
      else {
        if (location?.state?.isServicesScreens) {
          navigate(routePaths.eventType, { state: location?.state })
        }
        else {
          navigate(routePaths.eventType)
        }
      }
    } else {
      toast.error('Please select location!',);
    }
  }


  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`)
            .then((response) => response.json())
            .then((data) => {
              if (data.results && data.results.length > 0) {
                const placeName = data.results[0].formatted_address;
                let locationObj = {
                  longitude: longitude,
                  latitude: latitude,
                  formatted_address: placeName
                }
                localStorage.setItem('location', JSON.stringify(locationObj))
                dispatch(setEventLocationInputData(locationObj))
                setEventLocation(placeName)
              }
            })
            .catch((error) => {
              console.error('Error occurred while fetching place name:', error);
            });
        },
        (error) => {
          console.error('Error occurred while fetching location:', error.message);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  const editFilterData = (data, type, updateData) => {
    const editedFilterData = data.map((items) => {
      if (items.label === type) {
        return {
          ...items,
          id: updateData?.place_id ? updateData.place_id : 14548,
          value: updateData?.formatted_address ? updateData?.formatted_address : "Select Location",
          delete: false,
          isDelete: false,
          location: {
            "lat": updateData?.latitude,
            "lng": updateData?.longitude
          }
        }
      }
      else {
        return items
      }
    })

    if (state?.dynamicEventServiceRoute === routePaths.dynamicEventServiceRoute) {
      //event service filter data
      if (allFilterEventServicesStore.isFilter) {
        dispatch(setEventServicesApplyFilterData(editedFilterData))
      } else {
        dispatch(setEventServicesFilter(editedFilterData))
      }
    }
    else {
      if (allEventData.isFilter) {
        dispatch(setEventPackagesFilterApplyFilterData(editedFilterData))
      } else {
        dispatch(setEventPackagesFilter(editedFilterData))
      }
    }
  }

  useEffect(() => {
    if (!eventLocation) {
      handleGetLocation()
    }
  }, [])




  return (
    <Container maxWidth="md" sx={{ maxWidth: '720px', border: '1px solid #F2F2F2', paddingTop: '36px' }}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {/* Event location box */}
        <TopBar
          heading={"EVENT LOCATION"}
        />
        {/* Progress Bar */}
        {
          !(routePaths.packages === state?.packagesPath || routePaths.dynamicEventServiceRoute === state?.dynamicEventServiceRoute) &&
          <ProgressBar value={25} />
        }

        {/* Search map input box */}
        <Grid item xs={12}>
          <Box position="relative">
            <Box position="absolute" top="25%" left="10px" transform="translateY(-50%)">
              <SearchIcon />
            </Box>
            <Autocomplete
              apiKey={apiKey}
              onPlaceSelected={(place) => handlePlaceSelected(place)}
              style={{
                minWidth: '100%',
                height: '47px',
                backgroundColor: '#F2F2F2',
                borderRadius: '21px',
                paddingLeft: '41px',
                border: 'none',
                outline: 'none',
                paddingRight: '3rem'
              }}
              options={{
                types: ['geocode', 'establishment'],
              }}
              defaultValue={
                eventLocation
              }
            />
            <Box onClick={handleGetLocation} sx={{ cursor: 'pointer' }} position="absolute" top="24%" right="10px" transform="translateY(-50%)">
              <GpsFixedIcon />
            </Box>
          </Box>
        </Grid>
        {/* Wembley text box */}
        {
          ((isLocationExist && Object.keys(isLocationExist)?.includes("longitude")) ||
            isLocationExist?.formatted_address || eventLocation) &&
          <Grid item xs={12}>
            <Box
              sx={EventLocationStyles.wembleyBox.container}
            >
              <Typography sx={EventLocationStyles.wembleyBox.text}  >
                {(isLocationExist && Object.keys(isLocationExist).includes("longitude")) ?
                  isLocationExist?.formatted_address : eventLocation}
              </Typography>
            </Box>
          </Grid>
        }
        {/* With in box */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: '13px', fontFamily: 'Open Sans, sans-serif' }} htmlFor="customRange1">
              WITH IN
            </Typography>
            <Typography sx={{ color: '#1F1F1F', fontWeight: '600', fontFamily: 'Open Sans, sans-serif' }} htmlFor="customRange1">
              Miles {rangeValue ? rangeValue : allEventData?.eventLocation?.sliderValue ? allEventData.eventLocation.sliderValue : settingsData?.default}
            </Typography>
          </Box>
          <Slider
            defaultValue={allEventData.eventLocation.sliderValue ? allEventData.eventLocation.sliderValue : (settingsData?.default || 50)}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            max={settingsData?.max || 500}
            sx={EventLocationStyles.withInBox.slider}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography htmlFor="customRange1">
              1
            </Typography>
            <Typography sx={{ fontFamily: 'Open Sans, sans-serif' }} htmlFor="customRange1">
              {settingsData?.max || 500}
            </Typography>
          </Box>
        </Grid>
        {/* Button content box */}
        <Grid item xs={12}>
          <Box sx={EventLocationStyles.buttonContent.container}>
            <Button onClick={handleSubmit}
              variant="contained" sx={EventLocationStyles.buttonContent.button}>
              CONTINUE
            </Button>
          </Box>
        </Grid>
        {/* Bottom map image */}
        <Grid item xs={12}>
          <Box component="img" src={Images.worldmap} alt="img-fluid" style={{ maxWidth: '100%' }} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Index;