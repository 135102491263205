import React from 'react'
import Header from '../components/Header'
import { Box } from '@mui/material';

const NonAuthLayout = (props) => {

    return (
        <React.Fragment>
            <Box sx={{ border: '1px solid #F2F2F2' }}>
                {/* <SideBar /> */}
                <Header />
                <Box className="main-content">{props.children}</Box>
            </Box>
        </React.Fragment>
    )
}

export default NonAuthLayout


