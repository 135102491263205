

import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid,
    Box,
    Typography,
    ListItemAvatar,
    ListItemText,
    Avatar,
} from '@mui/material';
import { ListServiceListStyles } from './style'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { styled } from '@mui/material/styles';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import includesDone from '../../assets/images/includesDone47.png';
import excludesCross from '../../assets/images/excludesCross48.png';
import addOns from '../../assets/images/addOns49.png';
import { MAIN_MATCH_CONSTANTS } from '../../constants/MainMatchConstants'

const Index = ({ serviceListData, topHeadingText }) => {
    const { LIST_MATCH } = MAIN_MATCH_CONSTANTS
    const Demo = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
    }));

    const getTopHeadingTextColor = () => {
        switch (topHeadingText) {
            case LIST_MATCH.INCLUDES:
                return '#32C7EB'
            case LIST_MATCH.EXCLUDES:
                return '#DD6389'
            case LIST_MATCH.ADD_ONS:
                return '#BF55CC'
            default:
                return '#F2F2F2';
        }
    }

    const formatPrice = (price) => {
        if (price === 0) {
            return '£0.00';
        } else {
            return `£${price.toFixed(2)}`;
        }
    };

    return (
        <Grid item xs={12} paddingBottom='1rem'>
            <Box sx={{
                ...ListServiceListStyles.listContainer.topHeading,
                backgroundColor: getTopHeadingTextColor()
            }}>
                <Typography sx={ListServiceListStyles.listContainer.headingText}>
                    {topHeadingText}
                </Typography>
            </Box>
            <Grid item xs={12} md={6}>
                <Demo>
                    {
                        serviceListData instanceof Array && serviceListData.length > 0 &&
                        <List dense={false}>
                            {serviceListData.map((item) => (
                                <ListItem key={item.id}
                                    secondaryAction={
                                        `${topHeadingText === LIST_MATCH.INCLUDES ?
                                            formatPrice(item.totalPrice) : ''
                                        }`
                                    }
                                >
                                    {
                                        topHeadingText === LIST_MATCH.INCLUDES &&
                                        <ListItemIcon>
                                            <Box component='img' src={includesDone} />
                                        </ListItemIcon>
                                    }
                                    {
                                        topHeadingText === LIST_MATCH.EXCLUDES &&
                                        <ListItemIcon>
                                            <Box component='img' src={excludesCross} />
                                        </ListItemIcon>
                                    }
                                    {
                                        topHeadingText === LIST_MATCH.ADD_ONS &&
                                        <ListItemIcon>
                                            <Box component='img' sx={ListServiceListStyles.addOnsImageSize} src={addOns} />
                                        </ListItemIcon>
                                    }

                                    <ListItemText
                                        primary={item.description}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    }
                </Demo>
            </Grid>
        </Grid>
    )
}

export default Index