import { call, put, takeLatest } from "redux-saga/effects";
import {
  Set_Religion_Types_Loading,
  Set_Religion_Types,
  Un_Set_Religion_Types_Loading,
  Get_Religion_Type,
  Set_Event_Types_Loading,
  Un_Set_Event_Types_Loading,
  Set_Event_Types,
  Get_Event_Types,
  Un_Set_Community_Types_Loading,
  Set_Community_Types,
  Get_Community_Type,
  Get_Event_Settings,
  Set_Event_Settings,
  Get_Event_Packages_Search,
  Set_Event_Packages_Search,
  Get_Event_Packages_Services,
  Set_Event_Packages_Services,
  Set_Event_Services,
  Set_Event_Services_Is_Loading,
  Get_Event_Services,
  Get_Packages_Search_Keywords,
  Set_Packages_Search_Keywords,
  Set_Event_Packages_Search_Is_Loading,
  // new
  Set_Event_Packages_Is_Loading,
  Get_Event_Packages_Details,
  Set_Event_Packages_Details,
  // new
} from "./actionType";
import service from "../../api/services/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* getReligionType({ payload, setIsReligionLoading }) {
  setIsReligionLoading(true)
  yield put({ type: Set_Religion_Types_Loading });
  const response = yield service.planEventService.getEventReligion(payload);
  const data = yield response.data;
  if (response.status === 200) {
    setIsReligionLoading(false)
    yield put({ type: Un_Set_Religion_Types_Loading });
    // yield put({ type: Get_Profile_Response, data })
    // toast.success(response.message);

    yield put({ type: Set_Religion_Types, data });
  } else {
    setIsReligionLoading(false)
    toast.error(response.message);
    yield put({ type: Un_Set_Religion_Types_Loading });
  }
  return response;
}

function* getEventSettings() {
  const response = yield service.planEventService.getEventSettings();
  const data = yield response.data.body;
  if (response.status === 200) {
    yield put({ type: Set_Event_Settings, data });
  } else {
    toast.error(response.message);
  }
  return response;
}

function* getCommunityType({ payload, setIsCommunityLoading }) {
  setIsCommunityLoading(true)
  yield put({ type: Set_Religion_Types_Loading });
  const response = yield service.planEventService.getEventCommunity(payload);
  const data = yield response.data;
  if (response.status === 200) {
    setIsCommunityLoading(false)
    yield put({ type: Set_Community_Types, data });
  } else {
    setIsCommunityLoading(false)
    toast.error(response.message);
    yield put({ type: Un_Set_Community_Types_Loading });
  }
  return response;
}

function* getEventTypeData({ payload, eventTypeCount }) {
  yield put({ type: Set_Event_Types_Loading });
  const response = yield service.planEventService.getEventTypes(payload, eventTypeCount);
  const data = yield response.data;
  if (response.status === 200 || response.status === 201) {
    yield put({ type: Un_Set_Event_Types_Loading });
    // yield put({ type: Get_Profile_Response, data })
    // toast.success(response.message);

    yield put({ type: Set_Event_Types, data });
  } else {
    toast.error(response.message);
    yield put({ type: Un_Set_Event_Types_Loading });
  }
  return response;
}

function* getEventPackagesSearch({ payload, packagesCardCount }) {
  yield put({ type: Set_Event_Packages_Search_Is_Loading, data: true })
  const response = yield service.planEventService.getEventPackagesSearch(payload, packagesCardCount);
  const data = yield response.data;
  if (response.status === 200 || response.status === 201) {
    yield put({ type: Set_Event_Packages_Search, data })
    yield put({ type: Set_Event_Packages_Search_Is_Loading, data: false })
    // toast.success(response.message);
    yield put({ type: Set_Event_Types, data });
  } else {
    yield put({ type: Set_Event_Packages_Search_Is_Loading, data: false })
    toast.error(response.message);
  }
  return response;
}

function* getEventPackagesServices({ payload }) {
  try {
    const response = yield service.planEventService.getEventPackagesServices(payload);
    const data = yield response.data;
    yield put({ type: Set_Event_Packages_Services, data })
  } catch (error) {
    console.log('error', error.message)
    toast.error(error.message, { autoClose: 2000 });
  }
}

// new
function* getEventPackagesDetails({ payload }) {

  try {
    yield put({ type: Set_Event_Packages_Is_Loading, data: true })
    const response = yield service.planEventService.getEventPackagesDetails(payload);
    const data = yield response.data;
    yield put({ type: Set_Event_Packages_Details, data })
    yield put({ type: Set_Event_Packages_Is_Loading, data: false })
  } catch (error) {
    console.log('error', error.message)
    yield put({ type: Set_Event_Packages_Is_Loading, data: false })
    toast.error(error.message, { autoClose: 2000 });
  }
}
// 



function* getEventServices({ payload }) {

  try {
    yield put({ type: Set_Event_Services_Is_Loading, data: true })
    const response = yield service.planEventService.getEventServices(payload);
    const data = yield response.data;
    yield put({ type: Set_Event_Services, data })
    yield put({ type: Set_Event_Services_Is_Loading, data: false })
  } catch (error) {
    console.log('error', error.message)
    yield put({ type: Set_Event_Services_Is_Loading, data: false })
    toast.error(error.message, { autoClose: 2000 });
  }
}

function* getPackagesSearchKeywords({ payload }) {
  try {
    const response = yield service.planEventService.getPackagesSearchKeywords(payload);
    const data = yield response.data;
    yield put({ type: Set_Packages_Search_Keywords, data })
  } catch (error) {
    console.log('error', error.message)
    toast.error(error.message, { autoClose: 2000 });
  }
}



function* productSaga() {
  yield takeLatest(Get_Religion_Type, getReligionType);
  yield takeLatest(Get_Event_Packages_Search, getEventPackagesSearch);
  yield takeLatest(Get_Event_Settings, getEventSettings);
  yield takeLatest(Get_Event_Types, getEventTypeData);
  yield takeLatest(Get_Community_Type, getCommunityType);
  yield takeLatest(Get_Event_Packages_Services, getEventPackagesServices);
  yield takeLatest(Get_Event_Services, getEventServices);
  yield takeLatest(Get_Packages_Search_Keywords, getPackagesSearchKeywords);
  yield takeLatest(Get_Event_Packages_Details, getEventPackagesDetails);
}

export default productSaga;
