export const findPreviousObject = (data, valueToFind) => {
    for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
            if (data[i][j].value === valueToFind) {
                if (j > 0) {
                    return data[i][j - 1];
                } else if (i > 0) {
                    return data[i - 1][data[i - 1].length - 1];
                }
            }
        }
    }
    return null;
}