import { BASE_URL } from "../../constants/DefaultValues";
import Dictionary from "../dictionary"
import axios from "axios";
const { brandPackagesServices } = Dictionary
const eventServices = {
    getBrandPackages: async (accountId) => {
        return axios.get(BASE_URL + brandPackagesServices.getBrandPackages(accountId));
    },
    getBrandServices: async (accountId) => {
        return axios.get(BASE_URL + brandPackagesServices.getBrandServices(accountId));
    },
}
export default eventServices;
